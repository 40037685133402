import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { Button, Alert, CircularProgress } from '@mui/material';

import PropTypes from 'prop-types';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const SpecialPermissionList = (props) => {
    const { rolePermissionList, rolePermissionPrev, setIsLoadPermission } = props;

    const [rolesSpecialPermissionList, setSpecialRolesPermissonList] = React.useState(
        rolePermissionList.UserRoleMenuSpecialPermissions !== undefined
            ? rolePermissionList.UserRoleMenuSpecialPermissions
            : [],
    );

    const [tableRow, setTableRow] = React.useState([]);
    const [savedResponse, setSaveResponse] = React.useState({});
    let parrentArray = [];
    const [isLoad, setIsLoad] = React.useState(false);
    React.useEffect(() => {
        setIsLoad(true);
    }, []);

    React.useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
            permissionChange();
        }
    }, [isLoad]);

    const { submitData, response } = usePostData(AppUrls.SaveUserRole, 'POST');

    React.useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);

            const timer = setTimeout(() => {
                setIsLoadPermission(true);
                setSaveResponse({});
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const handleSaveClick = async () => {
        submitData({
            IsActive: true,
            RoleId: rolePermissionList.RoleId === undefined ? 0 : rolePermissionList.RoleId,
            RoleName: rolePermissionList.RoleName === undefined ? 0 : rolePermissionList.RoleName,
            RoleCode: rolePermissionList.RoleCode === undefined ? 0 : rolePermissionList.RoleCode,
            HasWebAccess: !rolePermissionList.HasWebAccess ? false : true,
            AllowImpersonate: !rolePermissionList.AllowImpersonate ? false : true,
            UserRoleMenuPermissions: [
                {
                    IsActive: true,
                    PermissionId: 0,
                    RoleId: 0,
                    MenuId: 0,
                    MenuName: '',
                    RoleCode: '',
                    RoleName: '',
                    IsView: true,
                    IsAdd: true,
                    IsEdit: true,
                    IsDelete: true,
                    IsPrint: true,
                    IsScreen: true,
                    IsMenuPage: true,
                    SeqNo: 0,
                    ParentMenu: 0,
                    MenuLevel: 0,
                },
            ],
            UserRoleMenuSpecialPermissions: rolesSpecialPermissionList,
            cTLRole_MasterPrev:
                rolePermissionPrev.RoleId !== undefined
                    ? {
                          RoleName: rolePermissionPrev && rolePermissionPrev.RoleName,
                          HasWebAccess: rolePermissionPrev && rolePermissionPrev.HasWebAccess,
                          AllowImpersonate:
                              rolePermissionPrev && rolePermissionPrev.AllowImpersonate,
                          UserRoleMenuPermissions:
                              rolePermissionPrev && rolePermissionPrev.UserRoleMenuPermissions,
                          UserRoleMenuSpecialPermissions:
                              rolePermissionPrev &&
                              rolePermissionPrev.UserRoleMenuSpecialPermissions,
                      }
                    : {
                          RoleName: '',
                          HasWebAccess: null,
                          AllowImpersonate: null,
                          UserRoleMenuPermissions: [{}],
                          RoleId: 5,
                          UserRoleMenuSpecialPermissions: [{}],
                      },
        });
    };

    const permissionChange = () => {
        if (rolesSpecialPermissionList.length > 0) {
            setTableRow([]);

            parrentArray.splice(0, parrentArray.length);

            rolesSpecialPermissionList.forEach((data) => {
                parrentArray.push(
                    <TableRow key={data.MenuId}>
                        {/* <TableCell style={{ color: 'blue', cursor: 'pointer' }}>
                            {data.MenuName}
                        </TableCell> */}
                        <TableCell>{data.PermissionDesc}</TableCell>
                        <TableCell align="right">
                            {' '}
                            <Checkbox
                                value={1}
                                onClick={(e) => menuChange(e, data.SpecialPermissionId)}
                                checked={data.HasAccess ? true : false}
                                {...label}
                            />
                        </TableCell>
                    </TableRow>,
                );
            });

            setTableRow([]);
            setTableRow(parrentArray);
        }
    };

    const menuChange = (e, menuId) => {
        var index = rolesSpecialPermissionList.findIndex(
            (obj) => obj.SpecialPermissionId === menuId,
        );

        rolesSpecialPermissionList[index].HasAccess = e.target.checked;
        setSpecialRolesPermissonList([]);
        setSpecialRolesPermissonList(rolesSpecialPermissionList);
        setIsLoad(true);
    };

    return (
        <>
            <div className="grid-cover">
                {rolesSpecialPermissionList.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 250 }} aria-label="simple table">
                            <TableHead>
                                <TableRow className="titleTable">
                                    {/* <TableCell> Menu Name</TableCell> */}
                                    <TableCell>Permission Desc</TableCell>
                                    <TableCell align="right">Enable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRow !== undefined || tableRow.length > 0
                                    ? tableRow.map((data, index) => {
                                          return data;
                                      })
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                )}
            </div>
            <div className="d-flex">
                <Button
                    className="saveBtn"
                    variant="contained"
                    size="large"
                    onClick={() => handleSaveClick()}
                >
                    {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                </Button>
            </div>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.IsSuccess ? 'Role Saved Successfully' : 'Error'}
                </Alert>
            ) : null}
        </>
    );
};

export default SpecialPermissionList;
SpecialPermissionList.propTypes = {
    rolePermissionList: PropTypes.array.isRequired,
};
