export const AppUrls = {
    Login: 'Authentication',
    TemLOgin: 'Authentication/AuthenticateUserTemp',
    LoginAzure: 'Authentication/AzureAuthentication',
    UserDetails: 'User/GetSignedInUser',
    InspesctionForSelect: 'InspectionType/GetAll',
    InspesctionForSave: 'InspectionType/Save',
    InspesctionForGet: 'InspectionType/GetDetail',
    InspesctionForDelete: 'InspectionType/Delete',
    InspesctionForList: 'InspectionType/GetList',

    carrierList: 'Carrier/GetList',

    claimList: 'Claim/GetList',
    claimsStatusCountList: 'Claim/GetClaimStatusCount',
    CliamProfileDetails: 'Claim/GetDetail',
    NoteList: 'Note/GetList',
    ClaimDocumentList: 'ClaimDocument/GetList',
    ClaimTaskList: 'ClaimTask/GetList',
    ClaimNoteSave: 'Note/Save',
    ClaimNoteGet: 'Note/GetDetail',
    ClaimNoteGetByPage: 'Note/GetListByPageName',
    ClaimNoteDelete: 'Note/Delete',
    ClaimDocumentFileSave: 'FileHandler',
    ClaimStatusWiseCountGet: 'Claim/GetClaimsStatusCount',
    ClaimTaskGetDetails: 'ClaimTask/Get',
    ClaimInfoSave: 'Claim/Update',
    ClaimProfileStatusBar: 'ClaimStatus/GetListByPageName',
    ClaimHistory: 'ClaimActivity/GetList',
    ClaimDocumentDelete: 'ClaimDocument/Delete',
    InsuredContact: 'Notification/SaveMessage',
    ClaimGeneralTaskSave: 'ClaimTask/SaveGeneralTask',
    ClaimGridSave: 'User/SaveUserGridFilter',
    ClaimGridGet: 'User/GetUserGridFilter',
    ClaimTaskFileClose: 'ClaimTask/SaveFileClose',
    ClaimUpdateReviewTask: 'ClaimTask/UpdateReviewTask',
    DocumentTypeGet: 'ClaimDocumentType/GetAll',
    UpdateReservation: 'Claim/UpdateReservation',
    TaskStatusCountList: 'ClaimTask/GetTaskStatusCount',
    CancelClaimOrDelete: 'Claim/CancelDeleteClaim',

    UserList: 'User/GetInspectorsList',
    AuditTrail: 'TransactionLog/GetList',
    AuditTrailForUser: 'TransactionLog/GetUserLog',
    AuditTrailForUserClaims: 'ClaimActivity/GetAllUserActivities',

    NotificationList: 'Notification/GetList',
    NotificationCodeList: 'Notification/NotificationCodes/GetAll',
    NotificationMedia: 'Notification/NotificationMedia/GetAll',
    GetNotification: 'Notification/GetDetail',
    NotificationSave: 'Notification/Save',
    NotificationDelete: 'Notification/Delete',
    GetMessagesByUser: 'Notification/GetMessagebyUser',

    AssignmentTypeForList: 'AssignmentType/GetList',
    AssignmentTypeForSave: 'AssignmentType/Save',
    AssignmentTypeForDelete: 'AssignmentType/Delete',
    AssignmentTypeForGet: 'AssignmentType/GetDetail',
    GetAllAssignmentType: 'AssignmentType/GetAll',

    CarrierForDashboard: 'Carrier/GetDashboard',
    CarrierForList: 'Carrier/GetList',
    claimListForCarrier: 'Claim/GetListByCarrier',
    SLAListForCarrier: 'SLAMaster/GetListByCarrier',
    ClaimscountForCarrier: 'Claim/GetClaimsCount',
    CarrierProfilePicSave: 'Carrier/SavePic',
    CliamTask: 'ClaimTask/GetList',
    GetSLAsByUser: 'SLAMaster/SLATrackingByUserGetList',

    GetPeril: 'Peril/GetAll',

    CarrierForGet: 'Carrier/GetDetail',
    ClaimTaskStatusWiseCountGet: 'ClaimTaskStatuses/GetTaskStatusCount',

    UserRoleList: 'User/GetAllRoles',
    UserRoleAssignmentSave: 'User/SaveUserRoleAssignment',
    RoleList: 'User/GetRoleList',
    UserRoleListForPermission: 'User/GetRole',
    SaveUserRole: 'User/SaveRoles',
    UserProfile: 'User/GetDetail',
    SendBulkInspector: 'User/SendBulkDispatchInspector',

    MenuMaster: 'MenuMaster/GetList',
    CarrierForSave: 'Carrier/Save',
    GetManagers: 'User/GetUsersByRole',
    UpdateUser: 'User/UpdateUser',
    UserProfilePicSave: 'User/SaveUserImage',
    GetUserInfo: 'User/GetUserInfo',
    GetUserTasks: 'User/GetUserTasks',
    GetImpersonateList: 'User/GetImpersonateList',
    Userimpersonate: 'Authentication/Userimpersonate',
    ClaimDocumentDownload: 'ClaimDocument/Download',
    ClaimDocumentUpdate: 'ClaimDocument/Update',

    InspectorForIndividualclaim: 'Claim/GetAvailableInspectorbyClaim',
    SaveAssignment: 'Claim/AssignClaim',
    SaveReAssignment: 'Claim/ReAssignClaim',
    InspectorForMulticlaim: 'Claim/GetAllInspectorForMultiClaim',
    SaveAssignmentReAssignment: 'Claim/AssignReAssignClaim',

    GetAllLocation: 'Location/GetAll',
    GetAllStates: 'State/GetAll',

    GetQuestionnaire: 'ResumeBuilder/GetQuestionnaire',
    GetAllLOVs: 'ResumeBuilder/GetFormLOVsByLOVCodes',
    PostQuestionnaire: 'ResumeBuilder/SaveResume',
    DownloadResumePdf: 'ResumeBuilder/DownloadResume',

    GetPagedProfiles: 'ResumeBuilder/SearchResume',
    GetTimeZone: 'TimeZone/GetAll',
    SaveTimeZone: 'User/SaveUserTimeZone',
    GetAllSLA: 'SLAMaster/GetList',
    SaveSLA: 'SLAMaster/Save',
    SlaTractList: 'SLAMaster/SLATrackingGetList',
    SlaTractAllList: 'SLAMaster/SLATrackingGetAllList',
    GetSLA: 'SLAMaster/Get',

    QueryOperators: 'QueryBuilder/QueryOperatorsGetAll',
    ReportEntitie: 'QueryBuilder/ReportEntitiesGetAll',
    ReportEntityField: 'QueryBuilder/ReportEntityFieldsGetAll',
    ReportQuerySave: 'QueryBuilder/SaveReportSubscriptions',
    AllDropDownData: 'QueryBuilder/GetFillData',
    GetAllCustomQueryList: 'QueryBuilder/GetAllReports',
    ExecuteReport: 'QueryBuilder/ExecuteReport',
    RunQueary: 'QueryBuilder/RunQuery',
    GetAllCarrier: 'Carrier/GetAll',
    GetClaimTaskStatuses: 'ClaimTaskStatuses/GetAll',
    GetAllSLAs: 'SLAMaster/GetAllByCarrier',
    GetInspectorSchedule: 'User/GetInspectorSchedule',
    GetTransactionCountByPageName: 'Note/GetTransactionCountByPageName',
    GetAllManualStatus: 'ClaimTaskStatuses/GetManualUpateTaskStatus',
    ManulTaskStatusUpdate: 'ClaimTask/ManualTaskStatusUpdate',
    UpdateAttemptedContact: 'ClaimTask/ManualCustomerContactUpdate',

    GetReportClaimsDashboard: 'Claim/GetReportClaimsDashboard',
    GetReportClaimStatusDashboard: 'Claim/GetReportClaimsStatusDashboard',
    GetReportClaimCountDashboardGrid: 'Claim/GetReportClaimsDashboardGrid',
};
