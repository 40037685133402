import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import {
    Button,
    FormControl,
    Alert,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import SelectOption from '../../components/select-option';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { AppUrls } from '../../core/route.url';
import PropTypes from 'prop-types';
import SelectMulti from '../../components/select-multi';

const ClaimBulkStatusAssignment = ({ setIsOpenOne, setClaimFilters, idValues, setIsLoad }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    const [savedResponse, setSaveResponse] = useState([]);

    const { result: InspectorList } = useLoadData(AppUrls.GetManagers + '?rolecode=INSPECTOR');

    const { submitData, response } = usePostData(AppUrls.SaveAssignment, 'POST');

    let assignment = [];

    const onSubmit = async (data) => {
        // for (let claimGuId of idValues) {
        //     assignment.push({
        //         AssignedToId: data.Inspector,
        //         AssignedToName: InspectorList?.Data
        //             ? InspectorList.Data.filter((d) => d.UserId === data.Inspector)[0].UserFullName
        //             : '',
        //         ClaimGuID: claimGuId,
        //     });
        // }
        // submitData(assignment);
    };

    useEffect(() => {
        if (response.result) {
            setSaveResponse(response.result);
            setIsLoad(true);
            const timer = setTimeout(() => {
                setSaveResponse([]);
                setIsLoad(true);
                setValue('InspectorId', '');
                setIsOpenOne(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    return (
        <div>
            <h3 className="mb-2">Claim Status Assignment</h3>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '35ch' },
                }}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div>
                    <FormControl sx={{ m: 1, width: 413 }}>
                        <Controller
                            control={control}
                            name="Inspector"
                            rules={{
                                required: 'Please select Inspector ',
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <SelectMulti
                                    fullWidth
                                    values={
                                        InspectorList !== undefined
                                            ? InspectorList.Data !== undefined
                                                ? InspectorList.Data.map((e) => {
                                                      return {
                                                          value: e['UserId'],
                                                          label: e['UserFullName'],
                                                      };
                                                  })
                                                : []
                                            : []
                                    }
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value === undefined ? '' : value}
                                    name={name || ''}
                                    error={errors.Inspector}
                                    multi={true}
                                />
                            )}
                        />
                    </FormControl>
                </div>

                <div className="d-flex model-footer">
                    <Button
                        style={{ marginRight: 'auto' }}
                        variant="outlined"
                        onClick={() => {
                            setIsOpenOne(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button style={{ marginLeft: 2 }} variant="contained" type="submit">
                        {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                    </Button>
                </div>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </div>
    );
};

export default React.memo(ClaimBulkStatusAssignment);

// User_edit.propTypes = {
//   idValue: PropTypes.number.isRequired,
//   setIsLoadList: PropTypes.any.isRequired,
//   setIsOpenEdit: PropTypes.any.isRequired,
//   roleCode: PropTypes.string.isRequired,
// };
