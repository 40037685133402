import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { AppUrls } from '../../core/route.url';
import { ClaimMap } from './claim-profile-mapview';
import ClaimProfileTab from './claim-profile-tab';
import ClaimProfileTaskDetails from './claim-profile-task-details';
import { Button, Modal, Tooltip } from '@mui/material';
import ClaimIndividualAssignment from './claim-profile-individual-assign-list';
import Moment from 'moment';
import ClaimInformationForm from './claim-Information-form';
import CreateIcon from '@mui/icons-material/Create';

import ClaimProfileStatusBar from './claim-profile-status-bar';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import ClaimInsuredInformation from './claim-insured-information';
import { decryptWithAES } from '../../utils/global-functions';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import ClaimContact from './claim-contact';
import useUserPermissions from '../../hooks/use-user-permissions.hook';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    height: '96%',
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const ClaimProfile = () => {
    let { id: param } = useParams();
    // const { state } = useLocation();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const id = parsedParam?.state?.guId;
    const { state } = parsedParam;
    const { handleSetEditPermissions, handleSetSpecialPermissions } = useUserPermissions();
    const { result: cliamProfile, reload } = useLoadData(
        AppUrls.CliamProfileDetails + `?claimguid=${id}`,
    );

    const { response, submitData } = usePostData(AppUrls.ClaimTaskList, 'POST');

    const [isLoad, setIsLoad] = React.useState(true);
    const [isLoadForFileRviewer, setIsLoadFileReviewer] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [loadStatus, setLoadStatus] = React.useState(false);
    const [idValue, setIdValue] = React.useState('');
    const [isClaimInfoOpen, setIsClaimInfoOpen] = React.useState(false);
    const [isContactOpen, setIsContactOpen] = React.useState(false);
    const [isClaimTaskOpen, setIsClaimTaskOpen] = React.useState(false);
    const [taskStatusvalue, settaskStatusvalue] = React.useState(0);
    const [actionType, setactionType] = React.useState('');
    // const { submitData: fileReviewerSubmit, response: fileReviewer } = usePostData(
    //     AppUrls.GetManagers,
    //     'POST',
    // );
    const { result: fileReviewer, reload: reloadFileReviewer } = useLoadData2();

    const { submitData: noteSubmit, response: noteResponse } = usePostData(
        AppUrls.ClaimNoteGetByPage,
        'POST',
    );

    const { submitData: reloadStatus } = usePostData(AppUrls.ClaimProfileStatusBar, 'POST');

    const navigate = useNavigate();
    React.useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
            submitData({
                PageNo: 1,
                PageSize: 100,
                SortDir: 'Ascending',
                SortExp: '',
                StartDate: null,
                EndDate: null,
                ClaimTaskNumber: '',
                ClaimGuID: id,
            });
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (isLoadForFileRviewer) {
            setIsLoadFileReviewer(false);
            reloadFileReviewer(AppUrls.GetManagers + '?rolecode=FILE_RVR', '');
        }
    }, [isLoadForFileRviewer]);

    {
        /*} React.useEffect(() => {
        settaskStatusvalue(0);
        if (response.result !== undefined) {
            if (response.result.Data !== null) {
                const isAvailableForReassign = response.result.Data.filter(
                    (s) =>
                        s.ClaimTaskTypeId === 1 &&
                        (s.ClaimTaskStatusCode === 'ASSIGNED' ||
                            s.ClaimTaskStatusCode === 'PEND_DISPATCH' || s.ClaimTaskStatusCode === 'ATTEMPT_CONTACT' 
                            || s.ClaimTaskStatusCode === 'CONTACT_SUCC'),
                );

                if (isAvailableForReassign[0] !== undefined) {
                    if (
                        isAvailableForReassign[0].ClaimTaskStatusCode === 'ASSIGNED' ||
                        isAvailableForReassign[0].ClaimTaskStatusCode === 'PEND_DISPATCH' ||                        
                        isAvailableForReassign[0].ClaimTaskStatusCode === 'ATTEMPT_CONTACT' ||                        
                        isAvailableForReassign[0].ClaimTaskStatusCode === 'CONTACT_SUCC'
                    ) {
                        settaskStatusvalue(1);
                    }
                }
            }
        }
    }, [response.result]);*/
    }

    if (cliamProfile === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (cliamProfile.Data === null) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    const handleRefreshTask = () => {
        submitData({
            PageNo: 1,
            PageSize: 100,
            SortDir: 'Ascending',
            SortExp: '',
            StartDate: null,
            EndDate: null,
            ClaimTaskNumber: '',
            ClaimGuID: id,
        });
    };

    const handleRefreshNotes = () => {
        noteSubmit({
            pageName: state?.page === 'claim' ? 'Claims' : 'Task',
            ClaimId: cliamProfile.Data.ClaimId,
            ClaimTaskUniqueId: state?.page === 'claim' ? '' : state?.taskGuId,
        });
    };

    const handleReloadStatus = () => {
        reloadStatus({
            pageName: state?.page === 'claim' ? 'Claims' : 'Task',
            ClaimTaskStatusCode:
                state?.page === 'claim'
                    ? ''
                    : state?.taskCode === 1
                    ? 'CLAIM_TASK'
                    : state?.taskCode === 3
                    ? 'REVIEW_TASK'
                    : '',
            ClaimId:
                cliamProfile.Data != undefined && cliamProfile.Data !== null
                    ? cliamProfile.Data.ClaimId
                    : '',
        });
    };
    // if (countresponse.result === undefined) {
    //     return (
    //         <Box sx={{ width: '100%' }}>
    //             <LinearProgress />
    //         </Box>
    //     );
    // }
    // if (countresponse.result.Data === null) {
    //     return (
    //         <Box sx={{ width: '100%' }}>
    //             <LinearProgress />
    //         </Box>
    //     );
    // }

    const handleClose = () => {
        setIsOpen(false);
        setIdValue('');
    };

    return (
        <div className="main-content">
            {' '}
            <ClaimContact
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
                email={cliamProfile.Data.InspectorEmail}
                phoneNumber={cliamProfile.Data.InspectorPhone}
                claimId={cliamProfile.Data.ClaimGuID}
                EmailToName={cliamProfile.Data.AssignedToName}
                inspectorId={null}
                handleRefreshNotes={handleRefreshNotes}
            />
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ClaimIndividualAssignment
                        cliamProfile={cliamProfile.Data}
                        setIsLoads={setIsLoad}
                        setIsOpen={setIsOpen}
                        reload={reload}
                        actionType={actionType}
                        handleRefreshNotes={handleRefreshNotes}
                    />
                </Box>
            </Modal>
            <ClaimInformationForm
                isClaimInfoOpen={isClaimInfoOpen}
                setIsClaimInfoOpen={setIsClaimInfoOpen}
                cliamProfileDetails={cliamProfile.Data}
                reload={reload}
                handleReloadStatus={handleReloadStatus}
                handleRefreshTask={handleRefreshTask}
                handleRefreshNotes={handleRefreshNotes}
                setLoadStatus={setLoadStatus}
            />
            <div className="stack-top gridCol2">
                <div className="card-claim-details">
                    {' '}
                    <img
                        alt=" "
                        // src={require('./../../images/Group 261.png')}
                        src={cliamProfile.Data.CarrierImage}
                        className="carrier-logo"
                        style={{ cursor: 'pointer', height: 52 }}
                        onClick={() => {
                            navigate('/carriers/view', {
                                state: { carrierId: `${cliamProfile.Data.CarrierUniqueId}` },
                            });
                        }}
                    />
                    <Tooltip title={cliamProfile.Data.ClaimNumber}>
                        <h2 style={{ maxWidth: 208, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {cliamProfile.Data.ClaimNumber}
                        </h2>
                    </Tooltip>
                    <p>{cliamProfile.Data.InsuredName}</p>
                    <p>{cliamProfile.Data.PropertyAddress}</p>
                </div>
                <div className="stack-status-bar">
                    <ClaimProfileStatusBar
                        statusId={
                            state?.page === 'claim'
                                ? cliamProfile.Data.StatusSeqNo
                                : response.result !== undefined
                                ? response.result.Data !== null
                                    ? response.result.Data
                                    : 0
                                : 0
                        }
                        cliamProfileDetails={cliamProfile.Data}
                        setLoadStatus={setLoadStatus}
                        loadStatus={loadStatus}
                    />
                </div>
            </div>
            {/* Stack top area end */}
            <div className="row">
                <div className="col-5" style={{ minWidth: '545px' }}>
                    {/* Assigned Resource area */}
                    <div className="card" style={{ position: 'relative' }}>
                        {cliamProfile.Data.AssignedToId === null ||
                        cliamProfile.Data.AssignedToId === 0 ? null : (
                            <div style={{ position: 'absolute', right: '118px', top: '13px' }}>
                                <Button
                                    style={{ marginLeft: 'auto', boxShadow: 'none' }}
                                    variant="contained"
                                    onClick={() => {
                                        setIsContactOpen(true);
                                    }}
                                >
                                    Send Message
                                </Button>
                            </div>
                        )}
                        <div style={{ position: 'absolute', right: '12px', top: '13px' }}>
                            {(cliamProfile.Data.AssignedToId === null ||
                                cliamProfile.Data.AssignedToId === 0) &&
                            cliamProfile.Data.ClaimStatusCode === 'UNASSIGN' ? (
                                <Button
                                    style={{ marginLeft: 'auto', boxShadow: 'none' }}
                                    variant="contained"
                                    type="button"
                                    size="small"
                                    onClick={() => {
                                        setIsOpen(true);
                                        setactionType('Assign');
                                        setIdValue(cliamProfile.Data.ClaimGuID);
                                    }}
                                >
                                    Assign
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        style={{ marginLeft: 'auto', boxShadow: 'none' }}
                                        variant="contained"
                                        type="button"
                                        onClick={() => {
                                            setIsOpen(true);
                                            setactionType('ReAssign');
                                            setIdValue(cliamProfile.Data.ClaimGuID);
                                        }}
                                    >
                                        ReAssign
                                    </Button>
                                </>
                            )}
                        </div>
                        <h3>Assigned Resource</h3>

                        <div className="profileGrid">
                            {cliamProfile.Data.AssignedToId === null ||
                            cliamProfile.Data.AssignedToId === 0 ? null : (
                                <div className="p-col">
                                    <figure className="profile">
                                        <div>
                                            {' '}
                                            {cliamProfile.Data !== null &&
                                            cliamProfile.Data.AssigneeImage !== undefined &&
                                            cliamProfile.Data.AssigneeImage !== '' ? (
                                                <img
                                                    alt=" "
                                                    src={cliamProfile.Data.AssigneeImage}
                                                    width="92"
                                                    height="92"
                                                />
                                            ) : (
                                                // : null
                                                // <Box
                                                //     component={'figure'}
                                                //     style={{ borderColor: '#337dff' }}
                                                //     className="pic"
                                                //     sx={{
                                                //         backgroundImage: `url( ${require('./../../images/no-image.png')})`,
                                                //         backgroundPosition: 'center',
                                                //         backgroundSize: '100%',
                                                //     }}
                                                // ></Box>
                                                <img
                                                    alt=" "
                                                    src={require('./../../images/no-image.png')}
                                                />
                                            )}
                                        </div>
                                        <figcaption>
                                            <a
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate(
                                                        window.open(
                                                            `/inspector/${cliamProfile.Data.AssignToUniqueId}`,
                                                            '_blank',
                                                        ),
                                                    );
                                                }}
                                            >
                                                <b>
                                                    {cliamProfile.Data !== null &&
                                                    cliamProfile.Data.AssignedToName !== undefined
                                                        ? cliamProfile.Data.AssignedToName
                                                        : ''}
                                                </b>
                                            </a>
                                            <Box>
                                                {cliamProfile.Data !== null &&
                                                cliamProfile.Data.InspectorAddress !== undefined
                                                    ? cliamProfile.Data.InspectorPhone
                                                    : ''}
                                            </Box>
                                            <Box style={{ wordBreak: 'break-all' }}>
                                                {cliamProfile.Data !== null &&
                                                cliamProfile.Data.InspectorAddress !== undefined
                                                    ? cliamProfile.Data.InspectorEmail
                                                    : ''}
                                            </Box>
                                            <Box>
                                                {cliamProfile.Data !== null &&
                                                cliamProfile.Data.InspectorAddress !== undefined
                                                    ? cliamProfile.Data.InspectorAddress
                                                    : ''}
                                            </Box>
                                            {/* <p>410-920-6561</p>
                                        <p>Baltimore, FL</p> */}
                                        </figcaption>
                                    </figure>
                                </div>
                            )}

                            {cliamProfile.Data.AssignedToId === null ||
                            cliamProfile.Data.AssignedToId === 0 ? null : (
                                <div className="stats-profile p-col">
                                    <div className="stats-block">
                                        <h3 className="stats green">
                                            {cliamProfile.Data.AssignedCount}
                                        </h3>
                                        <p>Assigned</p>
                                    </div>
                                    <div className="stats-block">
                                        <h3 className="stats yellow">
                                            {cliamProfile.Data.RiskCount}
                                        </h3>
                                        <p>SLA Alert</p>
                                    </div>
                                    <div className="stats-block">
                                        <h3 className="stats red">
                                            {cliamProfile.Data.BreachedCount}
                                        </h3>
                                        <p>Breach</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Assigned Resource end */}
                    {/* Claim Information area */}
                    <div className="card" style={{ position: 'relative' }}>
                        {state?.page === 'claim' ? (
                            handleSetEditPermissions('Claims') ? (
                                <button
                                    onClick={() => {
                                        setIsClaimInfoOpen(true);
                                    }}
                                    className="icons absoluteR"
                                >
                                    <CreateIcon />
                                </button>
                            ) : null
                        ) : handleSetEditPermissions('Tasks') ? (
                            <button
                                onClick={() => {
                                    setIsClaimInfoOpen(true);
                                }}
                                className="icons absoluteR"
                            >
                                <CreateIcon />
                            </button>
                        ) : null}
                        <h3>Claim Information</h3>

                        <div className="row ">
                            <div className="p-col">
                                <ul className="list-data">
                                    <li>Carrier:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data.CarrierName !== undefined
                                            ? cliamProfile.Data.CarrierName
                                            : ''}
                                    </li>
                                    <li>Policy #:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.PolicyNo
                                            : ''}
                                    </li>
                                    {/* <li>Policy Type:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.PolicyType
                                            : ''}
                                    </li> */}
                                    <li>Type of Loss:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.TypeOfLoss
                                            : ''}
                                    </li>
                                    <li>Job Type:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.JobType
                                            : ''}
                                    </li>
                                    <li>Loss Date:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? Moment(cliamProfile.Data.DateOfLoss).format(
                                                  'MM-DD-YYYY  ',
                                              )
                                            : ''}
                                    </li>
                                    {/* <li>Peril:</li>
                                    <li className="text-label">
                                        {' '}
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.PerilName
                                            : ''}
                                    </li> */}
                                    <li>MFN:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.MFN
                                            : ''}
                                    </li>
                                    {/* <li>Inspection Type:</li>
                                    <li className="text-label">
                                        {' '}
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.InspectionType
                                            : ''}
                                    </li>
                                    <li>Assignment Type:</li>
                                    <li className="text-label">
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.AssignmentType
                                            : ''}
                                    </li> */}
                                    <li>Has EagleView:</li>
                                    <li className="text-label">
                                        {' '}
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.HasEagleView
                                                ? 'Yes'
                                                : 'No'
                                            : ''}
                                    </li>
                                    <li>Assigned in XA:</li>
                                    <li className="text-label">
                                        {' '}
                                        {cliamProfile.Data !== null &&
                                        cliamProfile.Data !== undefined
                                            ? cliamProfile.Data.AssignedInXA
                                            : ''}
                                    </li>
                                </ul>
                            </div>
                            <div className="p-col">
                                <div className="map">
                                    <ClaimMap
                                        position={{
                                            lat: parseFloat(cliamProfile.Data.LAT),
                                            lng: parseFloat(cliamProfile.Data.LONG),
                                        }}
                                        index={0}
                                    />
                                </div>
                                <p>
                                    {' '}
                                    {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                        ? cliamProfile.Data.PropertyAddress
                                        : ''}
                                </p>
                                {/* <Box>
                                        <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.ContactAddress}
                                        </Typography>
                                        <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.City
                                                ? inspectorProfileDetails.City
                                                : ''}
                                            {inspectorProfileDetails.StateName
                                                ? ', ' + inspectorProfileDetails.StateName
                                                : ''}

                                            {inspectorProfileDetails.Zip
                                                ? ', ' + inspectorProfileDetails.Zip
                                                : ''}
                                        </Typography>
                                         
                                    </Box>  */}
                            </div>
                        </div>
                    </div>
                    {/* Claim Information end */}
                    {/* Insured Information area */}
                    <ClaimInsuredInformation cliamProfile={cliamProfile} reload={reload} />

                    {/* Insured Information end */}
                </div>
                {/* Task area  */}
                <ClaimProfileTaskDetails
                    stateValue={state}
                    cliamProfileDetails={cliamProfile.Data}
                    response={response}
                    setIsClaimTaskOpen={setIsClaimTaskOpen}
                    reload={reload}
                    isClaimTaskOpen={isClaimTaskOpen}
                    handleRefreshTask={handleRefreshTask}
                    fileReviewer={fileReviewer}
                    handleRefreshNotes={handleRefreshNotes}
                    isAssigned={cliamProfile.Data.AssignedToId}
                    claimGuID={id}
                    reloadClaimTaskList={() => setIsLoad(true)}
                    setLoadStatus={setLoadStatus}
                />
                {/* Task End  */}
            </div>
            {/* Tabbed block */}
            <div className="tab-area card">
                {/* Tabbed note container start */}

                <ClaimProfileTab
                    cliamProfileDetails={cliamProfile.Data}
                    stateValue={state}
                    response={response}
                    handleRefreshTask={handleRefreshTask}
                    handleRefreshNotes={handleRefreshNotes}
                    noteResponse={noteResponse}
                    // trancountdetails={trancountdetails}
                    // handleRefreshCount={handleRefreshCount}
                />
                {/* Tabbed Document container end */}
            </div>
        </div>
    );
};

export default ClaimProfile;

ClaimProfile.propTypes = {};
