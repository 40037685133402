import React from 'react';
import ReportingDashboardList from './reporting_Dashboard_list';

function ReportingDashboardGrid({ claimCountForGridResponse }) {
    const [gridColumns, setGridColumns] = React.useState([]);
    const [reportList, setReportList] = React.useState([]);

    React.useEffect(() => {
        if (claimCountForGridResponse?.Data) {
            setGridColumns(claimCountForGridResponse?.Data?.ReportColumnFields);
            let dataArray = [];
            dataArray.splice(0, dataArray.length);
            claimCountForGridResponse?.Data?.ReportStates?.forEach((data) => {
                const ethnicitiesObject = data?.ReportCarriers?.reduce(
                    (previousObject, currentObject) => {
                        return Object.assign(previousObject, {
                            [currentObject.CarrierName]: currentObject.ClaimCount,
                        });
                    },
                    {},
                );

                ethnicitiesObject.statename = data.StateName;
                dataArray.push(ethnicitiesObject);
                setReportList(dataArray);
            });
        }
    }, [claimCountForGridResponse?.Data]);

    return (
        <>
            {gridColumns.length > 0 ? (
                <div className="card">
                    <ReportingDashboardList
                        filterList={reportList || []}
                        gridColumns={gridColumns || []}
                        setGridColumns={setGridColumns}
                    />
                </div>
            ) : null}
        </>
    );
}

export default ReportingDashboardGrid;
