import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import {
    Button,
    FormControl,
    Alert,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@mui/material';
import SelectOption from '../../components/select-option';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { AppUrls } from '../../core/route.url';
import PropTypes from 'prop-types';

const User_edit = (props) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useForm();

    const [savedResponse, setSaveResponse] = useState([]);
    const workingRadius = [
        { value: 50, label: '50 Miles' },
        { value: 100, label: '100 Miles' },
        { value: 150, label: '150 Miles' },
    ];

    const { result: managerList } = useLoadData(AppUrls.GetManagers + '?rolecode=MNGR');

    const { submitData, response } = usePostData(AppUrls.UpdateUser, 'POST');
    const { result: resultUserInfo } = useLoadData(
        AppUrls.GetUserInfo + '?userId=' + props.idValue,
    );

    const { result: userRoleList } = useLoadData(AppUrls.UserRoleList);
    const { result: stateList } = useLoadData(AppUrls.GetAllStates);
    const watchAllFields = watch();

    const onSubmit = async (data) => {
        submitData({
            IsActive: data.active,
            ManagerId: watchAllFields.Role === 3 ? data.Manager : 0,
            ManagerName:
                managerList?.Data && watchAllFields.Role === 3 && data.Manager !== 0
                    ? managerList.Data.filter((d) => d.UserId === data.Manager)[0] !== undefined
                        ? managerList.Data.filter((d) => d.UserId === data.Manager)[0].UserFullName
                        : null
                    : null,
            UserId: props.idValue,
            WorkRadius: watchAllFields.Role === 3 ? data.workingRadius : null,
            UserName: data.UserName,
            RoleId: data.Role !== null ? data.Role : null,
            RoleName:
                !userRoleList && !userRoleList?.Data
                    ? ''
                    : userRoleList?.Data
                    ? userRoleList.Data.find((role) => role.RoleId === data.Role)?.RoleName
                    : '',

            StateCode: data.State,
            StateName: '',
            Zip: data.zip,
            City: data.city,
            ContactAddress: data.address,
            updateUserInfoPrev: {
                IsActive: resultUserInfo && resultUserInfo.Data?.IsActive,
                ManagerName: resultUserInfo && resultUserInfo.Data?.ManagerName,
                WorkRadius: resultUserInfo && resultUserInfo.Data?.WorkRadius,
                UserName: resultUserInfo && resultUserInfo.Data?.UserName,
                RoleName: resultUserInfo && resultUserInfo.Data?.RoleName,
                StateName: '',
                StateCode: resultUserInfo && resultUserInfo.Data?.StateCode,
                Zip: resultUserInfo && resultUserInfo.Data?.Zip,
                City: resultUserInfo && resultUserInfo.Data?.City,
                ContactAddress: resultUserInfo && resultUserInfo.Data?.ContactAddress,
                LAT: resultUserInfo && resultUserInfo.Data?.LAT,
                LONG: resultUserInfo && resultUserInfo.Data?.LONG,
            },
        });
    };

    useEffect(() => {
        if (response.result) {
            setSaveResponse(response.result);
            props.setIsLoadList(true);
            const timer = setTimeout(() => {
                setSaveResponse([]);
                props.setIsLoadList(true);
                setValue('ManagerId', '');
                setValue('WorkRadius', '');
                setValue('UserName', '');
                setValue('Role', '');
                setValue('State', '');
                setValue('zip', '');
                setValue('city', '');
                setValue('address', '');
                props.setIsOpenEdit(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    useEffect(() => {
        if (resultUserInfo) {
            setValue(
                'Manager',
                resultUserInfo.Data.ManagerId == null ? 0 : resultUserInfo.Data.ManagerId,
            );
            setValue(
                'workingRadius',
                resultUserInfo.Data.WorkRadius === null ? 50 : resultUserInfo.Data.WorkRadius,
            );
            setValue('Role', resultUserInfo.Data.RoleId);
            setValue('UserName', resultUserInfo.Data.UserName);
            setValue('active', resultUserInfo.Data.IsActive);
            setValue('State', resultUserInfo.Data.StateCode);
            setValue('zip', resultUserInfo.Data.Zip);
            setValue('city', resultUserInfo.Data.City);
            setValue('address', resultUserInfo.Data.ContactAddress);
        }
    }, [resultUserInfo]);

    return (
        <div>
            <h2 className="mb-2">User Edit</h2>
            <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box className="grid-cl-2">
                    <Box>
                        <h3>User Info</h3>
                        <hr style={{ marginBottom: 22 }} />
                        <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                            <TextField
                                size="small"
                                fullWidth
                                required
                                error={errors.UserName}
                                id="outlined-required"
                                label="Username"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: true,
                                    // shrink: watchAllFields.carrier === '' ? false : true,
                                }}
                                {...register('UserName', { required: true })}
                            />
                        </FormControl>
                        <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                            <Controller
                                size="small"
                                fullWidth
                                control={control}
                                name="Role"
                                id="outlined-required"
                                rules={{
                                    required: 'Please select Role ',
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        fullWidth
                                        values={
                                            userRoleList?.Data
                                                ? userRoleList.Data.map((e) => {
                                                      return {
                                                          value: e['RoleId'],
                                                          label: e['RoleName'],
                                                      };
                                                  })
                                                : []
                                        }
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined || value === null ? 0 : value}
                                        name={name || ''}
                                        error={errors.Role}
                                    />
                                )}
                            />
                        </FormControl>
                        {/* <FormControl sx={{ m: 1, width: 413 }}  style={{ display: props.roleCode === 'MNGR'  ? "none" : null}}> */}
                        {watchAllFields.Role !== undefined && watchAllFields.Role === 3 ? (
                            <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                                <Controller
                                    size="small"
                                    control={control}
                                    name="Manager"
                                    // rules={{
                                    //   required: "Please select Manager ",
                                    // }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            fullWidth
                                            values={
                                                managerList?.Data
                                                    ? managerList.Data.map((e) => {
                                                          return {
                                                              value: e['UserId'],
                                                              label: e['UserFullName'],
                                                          };
                                                      })
                                                    : []
                                            }
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === null ? 0 : value}
                                            name={name || ''}
                                            error={errors.Manager}
                                        />
                                    )}
                                />
                            </FormControl>
                        ) : null}
                        {watchAllFields.Role !== undefined && watchAllFields.Role === 3 ? (
                            <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                                <Controller
                                    size="small"
                                    fullWidth
                                    control={control}
                                    name="workingRadius"
                                    id="outlined-required"
                                    rules={{
                                        required:
                                            watchAllFields.Role === 3
                                                ? 'Working Radius is required '
                                                : null,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={workingRadius}
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={
                                                value === undefined && value === null ? '' : value
                                            }
                                            name={'Working Radius' || ''}
                                            error={
                                                watchAllFields.Role === 3
                                                    ? errors.workingRadius
                                                    : null
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        ) : null}
                    </Box>
                    <Box>
                        {/* <h3>Contact Info</h3>
                        <hr style={{ marginBottom: 22 }} />
                        <TextField
                            size="small"
                            style={{ marginBottom: 15 }}
                            fullWidth
                            required
                            error={errors.address}
                            id="outlined-required"
                            label="Address"
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.address === '' ? false : true,
                            }}
                            {...register('address', { required: true })}
                        />
                        <TextField
                            size="small"
                            style={{ marginBottom: 15 }}
                            fullWidth
                            required
                            error={errors.city}
                            id="outlined-required"
                            label="City"
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.city === '' ? false : true,
                            }}
                            {...register('city', { required: true })}
                        />
                        <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                            <Controller
                                size="small"
                                control={control}
                                name="State"
                                id="outlined-required"
                                rules={{
                                    required: 'Please select state ',
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        fullWidth
                                        values={
                                            stateList?.Data
                                                ? stateList.Data.map((e) => {
                                                      return {
                                                          value: e['StateCode'],
                                                          label: e['StateName'],
                                                      };
                                                  })
                                                : []
                                        }
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined && value === null ? '' : value}
                                        name={name || ''}
                                        error={errors.State}
                                    />
                                )}
                            />
                        </FormControl>
                        <TextField
                            size="small"
                            style={{ marginBottom: 15 }}
                            fullWidth
                            required
                            error={errors.zip}
                            id="outlined-required"
                            label="Zip"
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.zip === '' ? false : true,
                            }}
                            {...register('zip', { required: true })}
                        /> */}
                    </Box>
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                watchAllFields.active !== undefined ? watchAllFields.active : false
                            }
                            {...register('active', { required: false })}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Active"
                />

                <div className="d-flex model-footer">
                    <Button
                        style={{ marginRight: 'auto' }}
                        variant="outlined"
                        onClick={() => {
                            props.setIsOpenEdit(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button style={{ marginLeft: 2 }} variant="contained" type="submit">
                        {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                    </Button>
                </div>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </div>
    );
};

export default User_edit;

User_edit.propTypes = {
    idValue: PropTypes.number.isRequired,
    setIsLoadList: PropTypes.any.isRequired,
    setIsOpenEdit: PropTypes.any.isRequired,
    roleCode: PropTypes.string.isRequired,
};
