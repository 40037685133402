import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Alert,
    Divider,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ManageRole = (props) => {
    const [savedResponse, setSaveResponse] = React.useState({});
    const { submitData, response } = usePostData(AppUrls.SaveUserRole, 'POST');

    const { rolePermissionList, rolePermissionPrev } = props;
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,

        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();

    React.useEffect(() => {
        if (rolePermissionList) {
            setValue('role', rolePermissionList.RoleName);
            setValue('roleCode', rolePermissionList.RoleCode);
            setValue('web', rolePermissionList.HasWebAccess);
            setValue('Impersonate', rolePermissionList.AllowImpersonate);
        }
    }, [rolePermissionList]);

    React.useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            const timer = setTimeout(() => {
                setSaveResponse({});
                setValue('role', '');
                setValue('roleCode', '');
                navigate('/role');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const onSubmit = async (data) => {
        submitData({
            IsActive: true,
            RoleId: rolePermissionList.RoleId === undefined ? 0 : rolePermissionList.RoleId,
            RoleName: data.role,
            RoleCode: !data.roleCode ? '' : data.roleCode,
            HasWebAccess: !data.web ? false : true,

            AllowImpersonate: !data.Impersonate ? false : true,
            UserRoleMenuPermissions: [
                {
                    IsActive: true,
                    PermissionId: 0,
                    RoleId: 0,
                    MenuId: 0,
                    MenuName: '',
                    RoleCode: '',
                    RoleName: '',
                    IsView: true,
                    IsAdd: true,
                    IsEdit: true,
                    IsDelete: true,
                    IsPrint: true,
                    IsScreen: true,
                    IsMenuPage: true,
                    SeqNo: 0,
                    ParentMenu: 0,
                    MenuLevel: 0,
                },
            ],
            UserRoleMenuSpecialPermissions: [
                {
                    RoleSpecialPerMapId: 0,
                    RoleId: 0,
                    SpecialPermissionId: 0,
                    HasAccess: true,
                    MenuId: 0,
                    MenuName: '',
                    PermissionCode: '',
                    PermissionDesc: '',
                },
            ],

            cTLRole_MasterPrev:
                rolePermissionPrev.RoleId !== undefined
                    ? {
                          RoleName: rolePermissionPrev && rolePermissionPrev.RoleName,
                          HasWebAccess: rolePermissionPrev && rolePermissionPrev.HasWebAccess,
                          AllowImpersonate:
                              rolePermissionPrev && rolePermissionPrev.AllowImpersonate,
                          UserRoleMenuPermissions:
                              rolePermissionPrev && rolePermissionPrev.UserRoleMenuPermissions,
                          UserRoleMenuSpecialPermissions:
                              rolePermissionPrev && rolePermissionPrev.UserRoleMenuPermissions,
                      }
                    : {
                          RoleName: '',
                          HasWebAccess: null,
                          AllowImpersonate: null,
                          UserRoleMenuPermissions: [{}],
                          RoleId: 5,
                          UserRoleMenuSpecialPermissions: [{}],
                      },
        });
    };

    return (
        <>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="manageroll-container">
                    <TextField
                        required
                        error={errors.role}
                        id="outlined-required"
                        label="Role"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.role === '' ? false : true,
                        }}
                        {...register('role', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.roleCode}
                        id="outlined-required"
                        label="Role Code"
                        defaultValue=""
                        disabled={rolePermissionList.RoleId === undefined ? false : true}
                        InputLabelProps={{
                            shrink: watchAllFields.roleCode === '' ? false : true,
                        }}
                        {...register('roleCode', { required: true })}
                    />
                </div>
                <div className="d-flex">
                    {' '}
                    <div style={{ marginLeft: 'auto' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        watchAllFields.Impersonate !== undefined
                                            ? watchAllFields.Impersonate
                                            : false
                                    }
                                    {...register('Impersonate', { required: false })}
                                    //   onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Allow Impersonate  
                        "
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        watchAllFields.web !== undefined
                                            ? watchAllFields.web
                                            : false
                                    }
                                    {...register('web', { required: false })}
                                    //   onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Web Access
                        "
                        />
                        <Button className="saveBtn" variant="contained" type="submit" size="large">
                            {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                        </Button>{' '}
                    </div>
                </div>
            </Box>

            {savedResponse.IsSuccess != undefined ? (
                <Alert severity={savedResponse.IsSuccess ? 'success' : 'error'}>
                    {savedResponse.IsSuccess ? 'Saved successfully' : 'Error'}
                </Alert>
            ) : null}
        </>
    );
};

export default ManageRole;
ManageRole.propTypes = {
    rolePermissionList: PropTypes.array.isRequired,
};
