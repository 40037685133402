import { Button, Dialog, DialogActions, DialogContent, TextField, Box } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const CustomDateRangePickerModal = ({
    isDialogOpen,
    handleDialogCancel,
    onChange,
    setSelectedLabel,
}) => {
    const [dateRange, setDateRange] = useState({ fromDate: moment(), toDate: moment() });

    return (
        <div>
            <Dialog disableEscapeKeyDown open={isDialogOpen} onClose={handleDialogCancel}>
                <DialogContent style={{ padding: 40 }}>
                    <h2 className="mb-2">Date</h2>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                columnGap: '15px',
                            }}
                        >
                            <DesktopDatePicker
                                label="From"
                                inputFormat="MM/dd/yyyy"
                                value={dateRange.fromDate}
                                onChange={(newDate) =>
                                    setDateRange({ ...dateRange, fromDate: moment(newDate)._d })
                                }
                                renderInput={(props) => (
                                    <TextField {...props} size="small" error={false} />
                                )}
                                maxDate={new Date(dateRange.toDate)}
                            />
                            <DesktopDatePicker
                                label="To"
                                inputFormat="MM/dd /yyyy"
                                value={dateRange.toDate}
                                onChange={(newDate) =>
                                    setDateRange({ ...dateRange, toDate: moment(newDate)._d })
                                }
                                renderInput={(props) => (
                                    <TextField {...props} size="small" error={false} />
                                )}
                                minDate={new Date(dateRange.fromDate)}
                                maxDate={new Date(moment()._d)}
                            />
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions style={{ padding: 30 }}>
                    <Button
                        style={{ marginRight: 'auto' }}
                        variant="outlined"
                        onClick={handleDialogCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => {
                            onChange?.(dateRange);
                            setSelectedLabel(
                                `${moment(dateRange.fromDate).format('MM-DD-YY')}  -  ${moment(
                                    dateRange.toDate,
                                ).format('MM-DD-YY')}`,
                            );
                            handleDialogCancel();
                        }}
                    >
                        APPLY
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomDateRangePickerModal;
