import * as React from 'react';
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { columnsForClaims } from '../../components/kendoGrid/columns';
import { process } from '@progress/kendo-data-query';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { CheckBoxColumnMenu } from '../../components/kendoGrid/checkboxColumnMenu';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import mapIcon from '../../images/map-marked-alt.svg';
import editIcon from '../../images/file-signature.svg';
import linkopen from '../../images/link-open.svg';
import fileIcon from '../../images/file-alt.svg';
import desabledFileIcon from '../../images/Icon-awesome-file-alt.svg';
import PropTypes from 'prop-types';
import { Button, LinearProgress, Typography } from '@mui/material';
import { Modal, IconButton, Box, FormControl } from '@mui/material';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Controller, useForm } from 'react-hook-form';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import ClaimBulkStatusAssignment from './claim-bulk-status-assignment';
import { Cached, Clear } from '@mui/icons-material';
import ClaimContact from './claim-contact';
import ClaimMultiAssignment from './claim-Multi-assign-list';
import { encryptWithAES } from '../../utils/global-functions';
import Moment from 'moment';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import moment from 'moment';
import ClaimListMapView from './claim-list-map-view';
const DATA_ITEM_KEY = 'ClaimGuID';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const ClaimList = ({
    filterList,
    OnHandleChange,
    setClaimFilters,
    forUpdate,
    isLoadingData,
    setIsLoad,
    reload,
    getFilterResponse,
    handleReloadFilter,
}) => {
    const history = useNavigate();
    const [display, setDisplay] = React.useState(false);
    const { handleSetPrintPermissions, handleSetSpecialPermissions } = useUserPermissions();
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const createDataState = (dataState) => {
        if (filterList.length > 0) {
            filterList.forEach((p) => {
                if (p.DateReceived) p.DateReceived = new Date(p.DateReceived);

                if (p.ScheduledOn) p.ScheduledOn = new Date(p.ScheduledOn);
                if (p.InspectedOn) p.InspectedOn = new Date(p.InspectedOn);
                if (p.ReservationStart) p.ReservationStart = new Date(p.ReservationStart);
                if (p.AcceptedDate) p.AcceptedDate = new Date(p.AcceptedDate);
                if (p.RejectedDate) p.RejectedDate = new Date(p.RejectedDate);
            });
        }
        return {
            result: process(filterList?.slice(0), dataState),
            dataState: dataState,
        };
    };
    let initialState = {};
    const [stateColumns, setStateColumns] = React.useState(columnsForClaims);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenOne, setIsOpenOne] = React.useState(false);
    const [headerChecked, setHeaderChecked] = React.useState(false);
    const [idValues, setIdValues] = React.useState([]);
    const [customFilterArray, setCustomFilterArray] = React.useState([]);
    const [isContactOpen, setIsContactOpen] = React.useState(false);
    const [selectedClaimdata, setSelectedClaimData] = React.useState([]);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const [newResult, setNewResult] = React.useState({});
    const [currentContactInfo, setCurrentContactInfo] = React.useState({
        mobile: '',
        email: '',
        claimId: '',
        emailToName: '',
    });
    const [mapViewState, setMapViewState] = React.useState({
        isShowMapView: false,
        lat: '',
        long: '',
        claimNumber: '',
        insuredName: '',
        insuredAddress: '',
        HasReservation: false,
        ReservationStart: '',
        ReservationEnd: '',
    });
    const { submitData, response } = usePostData(AppUrls.ClaimGridSave, 'POST');

    const currentUser = useCurrentUser();

    initialState = createDataState({
        take: 100,
        skip: 0,
    });

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        if (forUpdate === 1) {
            setResult([]);
        }
    }, [forUpdate]);

    React.useEffect(() => {
        if (newResult.skip !== undefined) {
            initialState = createDataState(newResult);
            setDataState(initialState.dataState);
            setResult(initialState.result);
        }
    }, [filterList]);

    React.useEffect(() => {
        if (response.result !== undefined) {
            handleReloadFilter();
        }
    }, [response]);

    React.useEffect(() => {
        if (getFilterResponse?.result !== undefined) {
            if (
                getFilterResponse?.result?.Data?.FilterDetails !== undefined &&
                getFilterResponse?.result?.Data?.FilterDetails !== ''
            ) {
                setStateColumns(JSON.parse(getFilterResponse?.result?.Data?.FilterDetails));
            }
            if (
                getFilterResponse?.result.Data?.ColumnFilters !== undefined &&
                getFilterResponse.result.Data?.ColumnFilters !== ''
            ) {
                if (
                    JSON.parse(getFilterResponse?.result.Data?.ColumnFilters)?.filter !== null &&
                    JSON.parse(getFilterResponse?.result.Data?.ColumnFilters)?.filter !== undefined
                ) {
                    if (
                        JSON.parse(getFilterResponse?.result?.Data?.ColumnFilters)?.filter
                            ?.filters !== null
                    ) {
                        const newArray = JSON.parse(getFilterResponse?.result?.Data?.ColumnFilters);

                        newArray.filter.filters?.forEach((d, i) => {
                            d.filters?.forEach((m, i) => {
                                if (
                                    m.field === 'DateReceived' ||
                                    m.field === 'ScheduledOn' ||
                                    m.field === 'InspectedOn' ||
                                    m.field === 'ReservationStart' ||
                                    m.field === 'AcceptedDate' ||
                                    m.field === 'RejectedDate'
                                ) {
                                    m.value = new Date(m.value);
                                }
                            });
                        });

                        setCustomFilterArray(newArray?.filter?.filters);
                        setNewResult(newArray);

                        initialState = createDataState(newArray);
                        setDataState(initialState?.dataState);

                        if (initialState?.dataState?.group) {
                            setGroupOfGrid(initialState?.dataState?.group);
                        }

                        setResult(initialState?.result);
                    } else {
                        setCustomFilterArray([]);
                    }
                } else {
                    setNewResult(JSON.parse(getFilterResponse?.result?.Data?.ColumnFilters));

                    initialState = createDataState(
                        // ),
                        JSON.parse(getFilterResponse?.result?.Data?.ColumnFilters),
                    );
                    setDataState(initialState?.dataState);
                    setResult(initialState?.result);
                    if (initialState?.dataState?.group) {
                        setGroupOfGrid(initialState?.dataState?.group);
                    }
                }
            } else {
                setNewResult({
                    take: 100,
                    skip: 0,
                });
                initialState = createDataState({
                    take: 100,
                    skip: 0,
                });
                setDataState(initialState?.dataState);
                setResult(initialState?.result);
                if (initialState.dataState?.group) {
                    setGroupOfGrid(initialState?.dataState?.group);
                }
            }
        }
    }, [getFilterResponse]);

    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [selectedState, setSelectedState] = React.useState({});

    const dataStateChange = (event) => {
        const dateFields = ['DateReceived'];

        setGroupOfGrid(event?.dataState?.group);

        if (event?.dataState?.filter !== undefined && event?.dataState?.filter !== null) {
            if (event.dataState.filter.filters !== null) {
            } else {
                setCustomFilterArray([]);
            }
        } else {
            setCustomFilterArray([]);
        }

        let updatedState = createDataState(event?.dataState);

        setResult(updatedState?.result);
        setDataState(updatedState?.dataState);
        submitData({
            UserCtrlFilterId: 0,
            PageName: 'CLAIM',
            UserId: currentUser.UserId,
            ControlName: 'ClAIM GRID',
            FilterDetails: JSON.stringify(stateColumns),
            ColumnFilters: JSON.stringify(updatedState?.dataState),
        });
    };

    const expandChange = (event) => {
        const isExpanded =
            event.dataItem?.expanded === undefined
                ? event.dataItem?.aggregates
                : event.dataItem?.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
    };

    const customColumnMenu = (props) => <CheckBoxColumnMenu {...props} data={filterList} />;

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {props.dataItem.AssignedToName !== null ? (
                            <button
                                className="icon-btn"
                                onClick={() => {
                                    setCurrentContactInfo({
                                        email: props.dataItem.InspectorEmail,
                                        mobile: props.dataItem.InspectorPhone,
                                        claimId: props.dataItem.ClaimGuID,
                                        emailToName: props.dataItem.AssignedToName,
                                    });
                                    setIsContactOpen(true);
                                }}
                            >
                                <img style={{ width: 15 }} src={fileIcon} title=" " />
                            </button>
                        ) : (
                            <button
                                disabled={props.dataItem.AssignedToName !== null ? false : true}
                                className="icon-btn"
                            >
                                <img style={{ width: 15 }} src={desabledFileIcon} title=" " />
                            </button>
                        )}
                        <button
                            onClick={() => {
                                let param = {
                                    state: {
                                        page: 'claim',
                                        guId: props.dataItem.ClaimGuID,
                                    },
                                };
                                let encryptedParam = encryptWithAES(JSON.stringify(param));
                                history(window.open(`/${encryptedParam}`, '_blank'));
                            }}
                            style={{ marginLeft: 10 }}
                            className="icon-btn"
                        >
                            <img style={{ width: 19 }} src={linkopen} title=" " />
                        </button>
                        <button
                            style={{ marginLeft: 10 }}
                            className="icon-btn"
                            onClick={() => {
                                setMapViewState({
                                    isShowMapView: true,
                                    lat: props.dataItem.LAT,
                                    long: props.dataItem.LONG,
                                    claimNumber: props.dataItem.ClaimNumber,
                                    insuredAddress: props.dataItem.PropertyAddress,
                                    insuredName: props.dataItem.InsuredName,
                                    HasReservation: props.dataItem.HasReservation,
                                    ReservationStart: props.dataItem.ReservationStart,
                                    ReservationEnd: props.dataItem.ReservationEnd,
                                });
                            }}
                        >
                            <img src={mapIcon} title=" " />
                        </button>
                    </div>
                </td>
            </>
        );
    };

    const handleClose = () => {
        setIsOpen(false);
        setIsOpenOne(false);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);

        submitData({
            UserCtrlFilterId: 0,
            PageName: 'CLAIM',
            UserId: currentUser.UserId,
            ControlName: 'ClAIM GRID',
            FilterDetails: JSON.stringify(columnsState),
            ColumnFilters: JSON.stringify(dataState),
        });
    };

    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);

            if (Object.values(newSelectedState).filter((data) => data === true).length >= 2) {
                setDisplay(true);
            } else {
                setDisplay(false);
            }
            setIdValues(Object.keys(newSelectedState).filter((key) => newSelectedState[key]));
        },
        [selectedState],
    );
    const onHeaderSelectionChange = React.useCallback(
        (event) => {
            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {};
            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
            if (Object.values(newSelectedState).filter((data) => data === true).length > 2) {
                setDisplay(true);
            } else {
                setDisplay(false);
            }
            setIdValues(Object.keys(newSelectedState).filter((data) => data));
        },
        [selectedState],
    );

    const _export = React.useRef(null);

    const excelExport = () => {
        _export.current.save(
            process(result && result.data && result.data, {
                take: filterList.length + 1,
                skip: 0,
            }).data,
        );
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(result && result.data && result.data, {
                take: filterList.length + 1,
                skip: 0,
            }).data,
        );
    };

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
            </Box>
        );
    }

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            overflow: 'hidden',
                        }}
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'claim',
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`${encryptedParam}`, {
                                state: {
                                    page: 'claim',
                                },
                            });
                        }}
                    >
                        <i className={props.dataItem.ClaimSLAColorIndicator} />
                        {props.dataItem.ClaimNumber}
                    </a>
                </td>
            </>
        );
    };

    const statusCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    {props.dataItem.ClaimStatusName}
                    {props.dataItem.ClaimSLAColorIndicator === 'green-circle' && (
                        <LinearProgressWithLabel value={props.dataItem.Progress} color="success" />
                    )}
                    {props.dataItem.ClaimSLAColorIndicator === 'yellow-circle' && (
                        <LinearProgressWithLabel value={props.dataItem.Progress} color="warning" />
                    )}
                    {props.dataItem.ClaimSLAColorIndicator === 'red-circle' && (
                        <LinearProgressWithLabel value={props.dataItem.Progress} color="error" />
                    )}
                </td>
            </>
        );
    };

    const carrierCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            history(`/carriers/view`, {
                                state: {
                                    carrierId: `${props.dataItem.CarrierUniqueId}`,
                                },
                            });
                        }}
                    >
                        {props.dataItem.CarrierName}
                    </a>
                </td>
            </>
        );
    };

    const AssignedNameCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                {props.dataItem.AssignedToId == null ? (
                    <td className="k-command-cell">{props.dataItem.AssignedToName}</td>
                ) : (
                    <td className="k-command-cell">
                        {' '}
                        <Link to={'/user/' + `${props.dataItem.AssignToUniqueId}`}>
                            {props.dataItem.AssignedToName}
                        </Link>
                    </td>
                )}
            </>
        );
    };

    const carrierCell2 = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return <></>;
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 480,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
    };

    const MultiselectBoxstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '98%',
        height: '96%',
        bgcolor: 'background.paper',
        boxShadow: 3,
        p: 3,
    };

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value, 1, 1);
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={result && result.data && result.data}
                style={{
                    maxHeight: 'calc(100vh - 234px)',
                    display: value ? 'none' : '',
                }}
            >
                {stateColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        if (
                            d.field === 'DateReceived' ||
                            d.field === 'ScheduledOn' ||
                            d.field === 'InspectedOn' ||
                            d.field === 'ReservationStart' ||
                            d.field === 'AcceptedDate' ||
                            d.field === 'RejectedDate'
                        ) {
                            return (
                                <Column
                                    key={index}
                                    field={d.field}
                                    title={d.title}
                                    format="{0:MM-dd-yyyy hh:mm a}"
                                />
                            );
                        } else {
                            return <Column key={index} field={d.field} title={d.title} />;
                        }
                    })}
            </Grid>
        );
    };

    const customFiltersClose = (id) => {
        if (customFilterArray.length > 0) {
            if (customFilterArray.length > 1) {
                dataState['filter'] = {
                    filters: customFilterArray.filter((d, i) => {
                        return i !== id;
                    }),
                    logic: 'and',
                };

                let updatedState = createDataState(dataState);
                setResult(updatedState.result);
                setDataState(updatedState.dataState);
                submitData({
                    UserCtrlFilterId: 0,
                    PageName: 'CLAIM',
                    UserId: currentUser.UserId,
                    ControlName: 'ClAIM GRID',
                    FilterDetails: JSON.stringify(stateColumns),
                    ColumnFilters: JSON.stringify(updatedState.dataState),
                });
            } else {
                dataState['filter'] = null;

                let updatedState = createDataState(dataState);
                setResult(updatedState.result);
                setDataState(updatedState.dataState);
                submitData({
                    UserCtrlFilterId: 0,
                    PageName: 'CLAIM',
                    UserId: currentUser.UserId,
                    ControlName: 'ClAIM GRID',
                    FilterDetails: JSON.stringify(stateColumns),
                    ColumnFilters: JSON.stringify(updatedState.dataState),
                });
            }

            setCustomFilterArray(
                customFilterArray.filter((d, i) => {
                    return i !== id;
                }),
            );
        }
    };

    const customAllFiltersClose = () => {
        dataState['filter'] = null;
        dataState['group'] = [];
        dataState['sort'] = [];

        let updatedState = createDataState(dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
        setCustomFilterArray([]);
        setGroupOfGrid([]);
        submitData({
            UserCtrlFilterId: 0,
            PageName: 'CLAIM',
            UserId: currentUser.UserId,
            ControlName: 'ClAIM GRID',
            FilterDetails: JSON.stringify(stateColumns),
            ColumnFilters: JSON.stringify(updatedState.dataState),
        });
    };

    const rowItemCheckBox = (event) => {
        if (event.rowType === 'groupHeader') {
            return null;
        }
        return (
            <td>
                <input
                    type="checkbox"
                    class="k-checkbox k-checkbox-md k-rounded-md"
                    disabled={
                        event?.dataItem?.ClaimStatusCode === 'CANCEL' ||
                        event?.dataItem?.ClaimStatusCode === 'COMPLETE' ||
                        false
                    }
                    onChange={(changeEvent) => {
                        if (!changeEvent.target.checked) {
                            setHeaderChecked(false);
                        } else {
                            if (idValues.length + 1 === dataState?.take && dataState?.take) {
                                setHeaderChecked(true);
                            }
                        }
                        if (
                            event?.dataItem?.ClaimStatusCode !== 'CANCEL' &&
                            event?.dataItem?.ClaimStatusCode !== 'COMPLETE'
                        ) {
                            let selectedStateCopy = { ...selectedState };

                            selectedStateCopy[event?.dataItem[DATA_ITEM_KEY]] =
                                !selectedStateCopy[event?.dataItem[DATA_ITEM_KEY]];
                            setSelectedState(selectedStateCopy);
                            if (
                                Object.values(selectedStateCopy).filter((data) => data === true)
                                    .length >= 2
                            ) {
                                setDisplay(true);
                            } else {
                                setDisplay(false);
                            }
                            setIdValues(
                                Object.keys(selectedStateCopy).filter(
                                    (key) => selectedStateCopy[key],
                                ),
                            );
                        }
                    }}
                    checked={
                        event?.dataItem?.ClaimStatusCode !== 'CANCEL' &&
                        event?.dataItem?.ClaimStatusCode !== 'COMPLETE' &&
                        selectedState[event?.dataItem[DATA_ITEM_KEY]]
                    }
                    defaultChecked={
                        event?.dataItem?.ClaimStatusCode !== 'CANCEL' &&
                        event?.dataItem?.ClaimStatusCode !== 'COMPLETE' &&
                        selectedState[event?.dataItem[DATA_ITEM_KEY]]
                    }
                />
            </td>
        );
    };

    const rowHeaderCheckBox = (event) => {
        if (event.rowType === 'groupHeader') {
            return null;
        }

        let isChecked =
            result !== undefined && result.data !== undefined
                ? result.data
                      .filter(
                          (claim) =>
                              claim?.ClaimStatusCode !== 'CANCEL' &&
                              claim?.ClaimStatusCode !== 'COMPLETE',
                      )
                      .findIndex((item) => !selectedState[idGetter(item)]) === -1
                : 0;

        return (
            <>
                <input
                    id="b4fb11d2-4bee-4f5e-aa99-f7f294ada709"
                    type="checkbox"
                    class="k-checkbox k-checkbox-md k-rounded-md"
                    onChange={(e) => {
                        const newSelectedState = { ...selectedState };
                        setHeaderChecked(e.target.checked);

                        result.data.forEach((item) => {
                            if (groupOfGrid.length > 0) {
                                item.items.forEach((itemWithGroup) => {
                                    if (
                                        itemWithGroup?.ClaimStatusCode !== 'CANCEL' &&
                                        itemWithGroup?.ClaimStatusCode !== 'COMPLETE'
                                    ) {
                                        newSelectedState[idGetter(itemWithGroup)] =
                                            e.target.checked;
                                    }
                                });
                            } else {
                                if (
                                    item?.ClaimStatusCode !== 'CANCEL' &&
                                    item?.ClaimStatusCode !== 'COMPLETE'
                                ) {
                                    newSelectedState[idGetter(item)] = !isChecked;
                                }
                            }
                        });
                        setSelectedState(newSelectedState);
                        if (
                            Object.values(newSelectedState).filter((data) => data === true)
                                .length >= 2
                        ) {
                            setDisplay(true);
                        } else {
                            setDisplay(false);
                        }
                        setIdValues(
                            Object.keys(newSelectedState).filter((key) => newSelectedState[key]),
                        );
                    }}
                    checked={headerChecked}
                    defaultChecked={headerChecked}
                />
                <label class="k-checkbox-label" for="b4fb11d2-4bee-4f5e-aa99-f7f294ada709"></label>
                <span
                    class="k-column-resizer"
                    draggable="false"
                    style={{ touchAction: 'none' }}
                ></span>
            </>
        );
    };

    const onAssign = () => {
        setDisplay(false);
        setSelectedState({});
    };

    const getExcelFilterList = (filterList) => {
        return filterList.map((p) => {
            if (p.DateReceived)
                p.DateReceived = moment(p.DateReceived).format('MM-DD-YYYY hh:mm a');
            if (p.ScheduledOn) p.ScheduledOn = moment(p.ScheduledOn).format('MM-DD-YYYY hh:mm a');
            if (p.InspectedOn) p.InspectedOn = moment(p.InspectedOn).format('MM-DD-YYYY hh:mm a');
            if (p.ReservationStart)
                p.ReservationStart = moment(p.ReservationStart).format('MM-DD-YYYY hh:mm a');
            if (p.AcceptedDate)
                p.AcceptedDate = moment(p.AcceptedDate).format('MM-DD-YYYY hh:mm a');
            if (p.RejectedDate)
                p.RejectedDate = moment(p.RejectedDate).format('MM-DD-YYYY hh:mm a');
            return p;
        });
    };
    const handleCloseMapview = () => {
        setMapViewState({
            isShowMapView: false,
            lat: '',
            long: '',
            claimNumber: '',
            insuredAddress: '',
            insuredName: '',
            HasReservation: false,
            ReservationStart: '',
            ReservationEnd: '',
        });
    };

    const gridData = React.useMemo(
        () =>
            groupOfGrid?.length > 0
                ? result !== undefined
                    ? result.data
                    : []
                : (result?.data || [])?.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                  })),
        [selectedState, result, dataState, groupOfGrid],
    );

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MultiselectBoxstyle}>
                    <ClaimMultiAssignment
                        idValues={idValues}
                        setIsLoads={setIsLoad}
                        setIsOpen={setIsOpen}
                        reload={reload}
                        onAssign={onAssign}
                        claims={result?.data || []}
                    />
                </Box>
            </Modal>
            <ClaimContact
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
                email={currentContactInfo.email}
                phoneNumber={currentContactInfo.mobile}
                claimId={currentContactInfo.claimId}
                EmailToName={currentContactInfo.emailToName}
                inspectorId={null}
                handleRefreshNotes={() => {}}
            />
            <Modal
                open={isOpenOne}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ClaimBulkStatusAssignment
                        idValues={idValues}
                        // setClaimFilters={setClaimFilters}
                        setIsOpenOne={setIsOpenOne}
                        setIsLoad={setIsLoad}
                    />
                </Box>
            </Modal>
            <Modal
                open={mapViewState.isShowMapView}
                onClose={handleCloseMapview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ClaimListMapView mapViewState={mapViewState} />
            </Modal>
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                {isLoadingData ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Grid
                    data={gridData}
                    {...dataState}
                    style={{
                        maxHeight: 'calc(100vh - 193px)',
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    onDataStateChange={dataStateChange}
                    resizable={true}
                    reorderable={true}
                    sortable={true}
                    // onSortChange={sortChange}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500, 1000],
                        previousNext: true,
                    }}
                    groupable={true}
                    total={result.total}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            {handleSetSpecialPermissions('CLM_BULK_ASSIGN') !== undefined ? (
                                handleSetSpecialPermissions('CLM_BULK_ASSIGN') ? (
                                    <div id="bulkAssign" style={{ marginLeft: 'inherit' }}>
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <Box
                                                title="Bulk Assignment"
                                                style={{
                                                    marginLeft: 1,
                                                    marginRight: 20,
                                                    cursor: 'pointer',
                                                    display: display ? '' : 'none',
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                    }}
                                                    variant="contained"
                                                    disabled={
                                                        idValues.length >
                                                        currentUser?.MultiClaimSelectionLimit
                                                    }
                                                >
                                                    Bulk Assign
                                                </Button>
                                                {idValues.length >
                                                currentUser?.MultiClaimSelectionLimit ? (
                                                    <span style={{ color: 'red', marginLeft: 6 }}>
                                                        Please select only upto
                                                        {currentUser &&
                                                            currentUser?.MultiClaimSelectionLimit}{' '}
                                                        claims for bulk assignment.
                                                    </span>
                                                ) : null}
                                                {/* <img
                                                src={bulkAsign}
                                                alt="Bulk Assign"
                                                title="Bulk Assignment"
                                            /> */}
                                            </Box>
                                        </Tooltip>
                                    </div>
                                ) : null
                            ) : null}

                            {customFilterArray?.length > 0
                                ? customFilterArray?.map((d, i) => {
                                      if (d?.filters?.length > 1) {
                                          return (
                                              <div style={{ marginLeft: 5 }}>
                                                  <span>
                                                      {columnsForClaims?.filter((data, index) => {
                                                          return (
                                                              data?.field === d?.filters[0]?.field
                                                          );
                                                      })[0]?.title +
                                                          ' ' +
                                                          d.filters[0]?.operator +
                                                          ' ' +
                                                          (d.filters[0]?.field === 'DateReceived' ||
                                                          d.filters[0]?.field === 'ScheduledOn' ||
                                                          d.filters[0]?.field === 'InspectedOn' ||
                                                          d.filters[0]?.field ===
                                                              'ReservationStart' ||
                                                          d.filters[0]?.field === 'AcceptedDate' ||
                                                          d.filters[0]?.field === 'RejectedDate'
                                                              ? //   ---------
                                                                d.filters[0]?.value?.toLocaleDateString(
                                                                    'en-US',
                                                                ) +
                                                                ' ' +
                                                                d?.logic +
                                                                ' ' +
                                                                d?.filters[1]?.operator +
                                                                ' ' +
                                                                d?.filters[1]?.value?.toLocaleDateString(
                                                                    'en-US',
                                                                )
                                                              : d?.filters[0]?.value +
                                                                ' ' +
                                                                d?.logic +
                                                                ' ' +
                                                                d?.filters[1]?.value)}
                                                  </span>

                                                  <IconButton
                                                      color="primary"
                                                      onClick={() => customFiltersClose(i)}
                                                  >
                                                      <Tooltip
                                                          openDelay={100}
                                                          position="top"
                                                          anchorElement="target"
                                                      >
                                                          {' '}
                                                          <Clear title={'Clear'} />
                                                      </Tooltip>
                                                  </IconButton>
                                              </div>
                                          );
                                      } else {
                                          if (d?.filters[0]?.value !== null) {
                                              return (
                                                  <>
                                                      <span>
                                                          {columnsForClaims?.filter(
                                                              (data, index) => {
                                                                  return (
                                                                      data?.field ===
                                                                      d?.filters[0]?.field
                                                                  );
                                                              },
                                                          )[0]?.title +
                                                              ' ' +
                                                              d?.filters[0]?.operator +
                                                              ' ' +
                                                              (d.filters[0]?.field ===
                                                                  'DateReceived' ||
                                                              d.filters[0]?.field ===
                                                                  'ScheduledOn' ||
                                                              d.filters[0]?.field ===
                                                                  'InspectedOn' ||
                                                              d.filters[0]?.field ===
                                                                  'ReservationStart' ||
                                                              d.filters[0]?.field ===
                                                                  'AcceptedDate' ||
                                                              d.filters[0]?.field === 'RejectedDate'
                                                                  ? typeof d?.filters[0]?.value ===
                                                                    'object'
                                                                      ? d?.filters[0]?.value?.toLocaleDateString(
                                                                            'en-US',
                                                                        )
                                                                      : new Date(
                                                                            d?.filters[0]?.value,
                                                                        )?.toLocaleDateString(
                                                                            'en-US',
                                                                        )
                                                                  : d?.filters[0]?.value) +
                                                              ' '}
                                                      </span>
                                                      <IconButton
                                                          color="primary"
                                                          onClick={() => customFiltersClose(i)}
                                                      >
                                                          <Tooltip
                                                              openDelay={100}
                                                              position="top"
                                                              anchorElement="target"
                                                          >
                                                              <Clear title={'Clear'} />
                                                          </Tooltip>
                                                      </IconButton>
                                                  </>
                                              );
                                          } else {
                                              return (
                                                  <>
                                                      <span>
                                                          {columnsForClaims?.filter(
                                                              (data, index) => {
                                                                  return (
                                                                      data?.field ===
                                                                      d?.filters[0]?.field
                                                                  );
                                                              },
                                                          )[0]?.title +
                                                              ' ' +
                                                              d?.filters[0]?.operator +
                                                              ' '}
                                                      </span>
                                                      <IconButton
                                                          color="primary"
                                                          onClick={() => customFiltersClose(i)}
                                                      >
                                                          <Tooltip
                                                              openDelay={100}
                                                              position="top"
                                                              anchorElement="target"
                                                          >
                                                              <Clear title={'Clear'} />
                                                          </Tooltip>
                                                      </IconButton>
                                                  </>
                                              );
                                          }
                                      }
                                  })
                                : null}

                            <div style={{ paddingLeft: 3 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                            <Link to="/mapview" target="_blank">
                                <IconButton title="View Map" className="icon-btn map-icon">
                                    <img src={mapIcon} title="View Map" />
                                </IconButton>
                            </Link>

                            <IconButton
                                onClick={() => {
                                    reload();
                                    customAllFiltersClose();
                                }}
                                className="clearIcon"
                                title="Clear All filter"
                            >
                                <Cached />
                            </IconButton>
                        </div>
                    </GridToolbar>

                    <GridToolbar>
                        {handleSetPrintPermissions('Claims') ? (
                            <div>
                                <button
                                    title="Export to Excel"
                                    className="icon-btn"
                                    onClick={excelExport}
                                >
                                    <img src={iconexcel} title="Export to Excel" />
                                </button>

                                <button className="icon-btn" onClick={exportPDF}>
                                    <img src={iconpdf} title="Export to PDF" />
                                </button>
                            </div>
                        ) : null}
                    </GridToolbar>
                    <Column
                        field={SELECTED_FIELD}
                        width="40px"
                        headerSelectionValue={
                            result !== undefined && result.data !== undefined
                                ? result.data.findIndex(
                                      (item) => !selectedState[idGetter(item)],
                                  ) === -1
                                : 0
                        }
                        groupable
                        cell={rowItemCheckBox}
                        headerCell={rowHeaderCheckBox}
                    />

                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'ClaimNumber' ? (
                                <Column
                                    key={idx}
                                    width="200px"
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={navigateCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'StateCode' ? (
                                <Column
                                    key={idx}
                                    width="90px"
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    filter={column.filter}
                                    columnMenu={customColumnMenu}
                                />
                            ) : column.field === 'AssignedToName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={AssignedNameCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'CarrierName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={carrierCell}
                                    filter={column.filter}
                                    columnMenu={customColumnMenu}
                                />
                            ) : column.field === 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={editCell}
                                    sortable={false}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'ClaimStatusName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={statusCell}
                                    filter={column.filter}
                                    columnMenu={customColumnMenu}
                                />
                            ) : column.field === 'TypeOfLoss' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={customColumnMenu}
                                />
                            ) : column.field === 'DateReceived' ||
                              column.field === 'ScheduledOn' ||
                              column.field === 'InspectedOn' ||
                              column.field === 'ReservationStart' ||
                              column.field === 'AcceptedDate' ||
                              column.field === 'RejectedDate' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    format="{0:MM-dd-yyyy hh:mm a}"
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>

                <ExcelExport
                    fileName={`ClaimExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    style={{ display: 'none' }}
                    data={
                        filterList
                            ? process(getExcelFilterList(filterList), {
                                  take: getExcelFilterList(filterList).length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    ref={_export}
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`ClaimExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default ClaimList;

ClaimList.propTypes = {
    filterList: PropTypes.array.isRequired,
    OnHandleChange: PropTypes.func.isRequired,
    forUpdate: PropTypes.number,
    setClaimFilters: PropTypes.func.isRequired,
    isLoadingData: PropTypes.bool,
    reload: PropTypes.func,
    getFilterResponse: PropTypes.any,
    handleReloadFilter: PropTypes.func,
};
