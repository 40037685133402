import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import CustomDateRangePickerModal from './CustomDateRangePickerModal';

function getQuarter(date = new Date()) {
    const quarter = Math.floor(date.getMonth() / 3 + 1);

    if (quarter === 1) {
        return moment(moment().startOf('year')._d).quarter(1)._d;
    } else if (quarter === 2) {
        return moment(moment().startOf('year')._d).quarter(2)._d;
    } else if (quarter === 3) {
        return moment(moment().startOf('year')._d).quarter(3)._d;
    } else {
        return moment(moment().startOf('year')._d).quarter(4)._d;
    }
}

function getLastQuarter(date = new Date()) {
    const quarter = Math.floor(date.getMonth() / 3 + 1);
    const dates = { startDate: new Date(), endDate: new Date() };

    if (quarter === 1) {
        dates.startDate = moment(moment().subtract(1, 'year').startOf('year')._d).quarter(4)._d;
        dates.endDate = moment(dates.startDate).add(2, 'month').endOf('month')._d;
        return dates;
    } else if (quarter === 2) {
        dates.startDate = moment(moment().startOf('year')._d).quarter(1)._d;
        dates.endDate = moment(dates.startDate).add(2, 'month').endOf('month')._d;
        return dates;
    } else if (quarter === 3) {
        dates.startDate = moment(moment().startOf('year')._d).quarter(2)._d;
        dates.endDate = moment(dates.startDate).add(2, 'month').endOf('month')._d;
        return dates;
    } else {
        dates.startDate = moment(moment().startOf('year')._d).quarter(3)._d;
        dates.endDate = moment(dates.startDate).add(2, 'month').endOf('month')._d;
        return dates;
    }
}

const SELECT_OPTIONS = [
    {
        fromDate: moment()._d,
        todate: moment()._d,
        label: 'Today',
        subLabel: `${moment().format('MM/DD/YYYY')} `,
    },
    {
        fromDate: moment().subtract(7, 'days')._d,
        todate: moment().subtract(1, 'days')._d,
        label: 'Last 7 Days',
        subLabel: `${moment().subtract(7, 'days').format('MMM DD')} - ${moment()
            .subtract(1, 'days')
            .format('MMM DD')} `,
    },

    {
        fromDate: moment().subtract(30, 'days')._d,
        todate: moment().subtract(1, 'days')._d,
        label: 'Last 30 Days',
        subLabel: `${moment().subtract(30, 'days').format('MMM DD')} - ${moment()
            .subtract(1, 'days')
            .format('MMM  DD')} `,
    },
    {
        fromDate: moment().startOf('month')._d,
        todate: moment()._d,
        label: 'This Month',
        subLabel: `${moment().format('MMM YYYY')} `,
    },

    {
        fromDate: getQuarter(),
        todate: moment()._d,
        label: 'This Quarter',
        subLabel: `${moment(getQuarter()).format('MMM ')} -
        ${moment().format('MMM YYYY')}`,
    },
    {
        fromDate: moment().subtract(1, 'months').startOf('month')._d,
        todate: moment().subtract(1, 'months').endOf('month')._d,
        label: 'Last Month',
        subLabel: `${moment().subtract(1, 'months').format('MMM YYYY')}`,
    },
    {
        fromDate: getLastQuarter().startDate,
        todate: getLastQuarter().endDate,
        label: 'Last Quarter',
        subLabel: `${moment(getLastQuarter().startDate).format('MMM ')} -
               ${moment(getLastQuarter().endDate).format('MMM YYYY')}`,
    },

    {
        fromDate: moment().subtract(3, 'months').startOf('month')._d,
        todate: moment(moment().subtract(1, 'month')).endOf('month')._d,
        label: 'Last 3 Month',
        subLabel: `${moment().subtract(3, 'months').format('MMM ')} -
               ${moment(moment().subtract(1, 'month')).endOf('month').format('MMM YYYY')}`,
    },
    {
        fromDate: moment().subtract(6, 'months').startOf('month')._d,
        todate: moment(moment().subtract(1, 'month')).endOf('month')._d,
        label: 'Last 6 Month',
        subLabel: `${moment().subtract(6, 'months').format('MMM ')} -
        ${moment(moment().subtract(1, 'month')).endOf('month').format('MMM YYYY')}`,
    },
    {
        fromDate: moment().subtract(12, 'months').startOf('month')._d,
        todate: moment(moment().subtract(1, 'month')).endOf('month')._d,
        label: 'Last 12 Month',
        subLabel: `${moment().subtract(12, 'months').format('MMM ')} -
        ${moment(moment().subtract(1, 'month')).endOf('month').format('MMM YYYY')}`,
    },
    {
        fromDate: moment()._d,
        todate: moment()._d,
        label: 'Custom',
        subLabel: '',
    },
];

const CustomSelectDatePicker = ({ onChange }) => {
    const [selectedLabel, setSelectedLabel] = useState('Today');
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleSelectChange = (option) => {
        if (option.label === 'Custom') {
            setIsDialogOpen(true);
        } else {
            onChange?.(option);
            setSelectedLabel(option.label);
        }
    };

    const handleDialogCancel = () => setIsDialogOpen(false);

    return (
        <div>
            <CustomDateRangePickerModal
                handleDialogCancel={handleDialogCancel}
                isDialogOpen={isDialogOpen}
                setSelectedLabel={setSelectedLabel}
                onChange={onChange}
            />
            <div>
                <Autocomplete
                    size="small"
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    freeSolo
                    options={SELECT_OPTIONS}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            style={{ textAlign: 'center', cursor: 'none' }}
                            label="Date"
                            inputProps={{
                                ...props.inputProps,
                                value: selectedLabel,
                                readOnly: false,
                            }}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <div
                                {...props}
                                onClick={(event) => {
                                    handleSelectChange(option);
                                    props.onClick(event);
                                }}
                                style={{ display: 'block' }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{option.label}</span>
                                    <span>{option.subLabel}</span>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default CustomSelectDatePicker;
