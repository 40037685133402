import * as React from 'react';
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
    getSelectedStateFromKeyDown,
} from '@progress/kendo-react-grid';
import { columnsForInspectorAssignment } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import PropTypes from 'prop-types';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import {
    Alert,
    CircularProgress,
    Button,
    Switch,
    FormControlLabel,
    Typography,
    TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Keys } from '../../helpers/keys';
import Moment from 'moment';
import Claim_Inspector_calendar from './claim-profile-Inspector-calender';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';

const initialSortConfig = columnsForInspectorAssignment?.map((x) => {
    return { field: x?.field, dir: x?.dir };
});

const ClaimIndividualAssignment = ({
    cliamProfile,
    setIsLoads,
    setIsOpen,
    reload,
    actionType,
    handleRefreshNotes,
}) => {
    const [stateColumns, setStateColumns] = React.useState(columnsForInspectorAssignment);
    const [savedResponse, setSaveResponse] = React.useState({});
    const [calenderList, setCalenderList] = React.useState([]);
    const [validationMsg, setvalidationMsg] = React.useState('');
    const [sort, setSort] = React.useState([initialSortConfig[6]]);
    const [filter, setFilter] = React.useState(null);

    const [data, setData] = React.useState([]);
    const [selecteddata, setSelectedData] = React.useState([]);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [isLoaderOn, setisLoaderOn] = React.useState(false);

    const [availableInspectoractive, setavailableInspectoractive] = React.useState('active');
    const [allInspectoractive, setallInspectoractive] = React.useState('');
    const [reasonvalue, setReasonValue] = React.useState('');

    const [showAllInspector, setShowAllInspector] = React.useState(true);

    const { submitData: submitDataForSave, response: responseForSave } = usePostData(
        AppUrls.SaveAssignment,
        'POST',
    );
    const { submitData: submitDataForReAssignSave, response: responseForReassignSave } =
        usePostData(AppUrls.SaveReAssignment, 'POST');

    {
        /* const { submitData: submitDataForCalender, response: responseForCalender } = usePostData(
        AppUrls.GetUserTasks,
        'POST',
       );*/
    }

    const { result: responseForCalender, reload: reloadCalender } = useLoadData2();

    const [isLoad, setIsLoad] = React.useState(true);
    const { submitData, response } = usePostData(AppUrls.InspectorForIndividualclaim, 'POST');

    React.useEffect(() => {
        if (isLoad === true) {
            setIsLoad(false);
            submitData({
                ClaimGuId: cliamProfile.ClaimGuID,
                ClaimLatitude: cliamProfile.LAT,
                ClaimLongitude: cliamProfile.LONG,
                HasReservation: cliamProfile.HasReservation,
                ReservationStart:
                    cliamProfile.HasReservation == true ? cliamProfile.ReservationStart : null,
                ReservationEnd:
                    cliamProfile.HasReservation == true ? cliamProfile.ReservationEnd : null,
                AllInspector: showAllInspector,
            });
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (selecteddata.length === 0) {
            setCalenderList([]);
        } else {
            if (selecteddata !== undefined) {
                reloadCalender(
                    AppUrls.GetInspectorSchedule + '?inspectorId=' + selecteddata.UserId,
                    '',
                );
                {
                    /* submitDataForCalender({
                    PageNo: 1,
                    PageSize: 50,
                    SortDir: 'Ascending',
                    SortExp: '',
                    StartDate: null,
                    EndDate: null,
                    ClaimTaskNumber: '',
                    UserUniqueId: selecteddata.UserUniqueId,
                });*/
                }
            }
        }
    }, [selecteddata]);

    React.useEffect(() => {
        if (responseForCalender != undefined) {
            if (responseForCalender.IsSuccess) {
                if (responseForCalender.Data != null) {
                    responseForCalender.Data.forEach((o) => {
                        o.ScheduleStartTime = new Date(o.ScheduleStartTime);
                        o.ScheduleEndTime = new Date(o.ScheduleEndTime);
                    });
                }
                setCalenderList(responseForCalender.Data);
                setisLoaderOn(false);
            }
        }
    }, [responseForCalender]);

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };
    const AssignInspectorbtnClick = (event) => {
        setvalidationMsg('');

        if (selecteddata === undefined) {
            setvalidationMsg('Please select any Inspector');
        } else if (selecteddata.length === 0) {
            setvalidationMsg('Please select any Inspector');
        } else if (reasonvalue === '' && actionType === 'ReAssign') {
            setvalidationMsg('Please enter the reason for reassign');
        } else {
            setIsDisabled(true);
            if (actionType === 'Assign') {
                submitDataForSave([
                    {
                        AssignedToId: selecteddata.UserId,
                        AssignedToName: selecteddata.FirstName,
                        ClaimGuID: cliamProfile.ClaimGuID,
                        XactNetAddress: selecteddata.XactNetAddress,
                        CarrierId: cliamProfile.CarrierId,
                        ReasonForReassign: '',
                        UserUniqueId: selecteddata.UserUniqueId,
                        UserEmail: selecteddata.EmailAddress,
                        UserPhone: selecteddata.Mobile,
                    },
                ]);
            } else if (actionType === 'ReAssign') {
                submitDataForReAssignSave([
                    {
                        AssignedToId: selecteddata.UserId,
                        AssignedToName: selecteddata.FirstName,
                        ClaimGuID: cliamProfile.ClaimGuID,
                        XactNetAddress: selecteddata.XactNetAddress,
                        CarrierId: cliamProfile.CarrierId,
                        ReasonForReassign: reasonvalue,
                        UserUniqueId: selecteddata.UserUniqueId,
                        UserEmail: selecteddata.EmailAddress,
                        UserPhone: selecteddata.Mobile,
                    },
                ]);
            }
        }
    };
    React.useEffect(() => {
        if (response.result != undefined) {
            setData(
                response.result.Data.map((dataItem) =>
                    Object.assign(
                        {
                            selected: false,
                        },
                        dataItem,
                    ),
                ),
            );
        }
    }, [response]);

    React.useEffect(() => {
        if (responseForSave.result != undefined) {
            setSaveResponse(responseForSave.result);
            const timer = setTimeout(() => {
                setSaveResponse({});
                reload();
                handleRefreshNotes();
                setIsOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [responseForSave]);

    React.useEffect(() => {
        if (responseForReassignSave.result != undefined) {
            setSaveResponse(responseForReassignSave.result);
            const timer = setTimeout(() => {
                setSaveResponse({});
                reload();
                handleRefreshNotes();
                setIsOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [responseForReassignSave]);

    const rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ['data-id']: rowProps.dataItem.UserId,
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    };
    const handleRowClick = (props) => {
        const newData = data.map((item) => ({
            ...item,
            selected: item.UserId === props.dataItem.UserId,
        }));
        const selectedrowValue = newData.filter((s) => s.UserId === props.dataItem.UserId);
        setSelectedData(selectedrowValue[0]);
        setData(newData);
        setisLoaderOn(true);
    };

    const handleNavigationAction = (event) => {
        const { focusElement } = event;
        const rowId = focusElement.parentElement.getAttribute('data-id');

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.UserId) === rowId,
            }));
            const selectedrowValue = newData.filter((s) => s.UserId == rowId);
            setSelectedData(selectedrowValue[0]);
            setData(newData);
            setisLoaderOn(true);
        }
    };

    const toggleAllInspector = () => {
        setShowAllInspector(!showAllInspector);
        setIsLoad(true);
        setisLoaderOn(false);
        if (showAllInspector) {
            setavailableInspectoractive('');
            setallInspectoractive('active');
        } else {
            setavailableInspectoractive('active');
            setallInspectoractive('');
        }
    };

    return (
        <>
            <div>
                <Box className="toppopHeader">
                    <Box style={{ borderRight: '1px solid #ddd' }}>
                        <Typography variant="h2" fontWeight={'600'}>
                            {cliamProfile.ClaimNumber}{' '}
                        </Typography>
                        <Typography variant="h3" style={{ whiteSpace: 'nowrap' }}>
                            Reservation Time:&nbsp;
                            {cliamProfile.HasReservation === true
                                ? Moment(cliamProfile.ReservationStart).format(
                                      'MM-DD-YYYY hh:mm:ss A',
                                  ) +
                                  '  to  ' +
                                  Moment(cliamProfile.ReservationEnd).format('hh:mm:ss A')
                                : 'Not Available'}
                        </Typography>
                    </Box>

                    <Box style={{ paddingLeft: '25px' }}>
                        <Typography variant="h4" fontWeight={'600'} fontSize={14}>
                            {cliamProfile.InsuredName}
                        </Typography>
                        <Typography>{cliamProfile.PropertyAddress}</Typography>
                    </Box>
                    {cliamProfile.HasReservation === true && (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'right',
                            }}
                            className="swichCustom"
                        >
                            <Typography marginRight={3} className={allInspectoractive}>
                                All Inspectors
                            </Typography>
                            <FormControlLabel
                                value="All"
                                control={
                                    <Switch
                                        color="primary"
                                        checked={showAllInspector}
                                        onChange={toggleAllInspector}
                                    />
                                }
                            />
                            <Typography className={availableInspectoractive}>
                                Available Inspectors
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box className="gridCol2m">
                    <Box>
                        {response.result === undefined || response.result.Data === undefined ? (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        ) : (
                            <>
                                <Grid
                                    data={filterBy(orderBy(data, sort), filter)}
                                    selectedField="selected"
                                    onRowClick={handleRowClick}
                                    rowRender={rowRender}
                                    navigatable={true}
                                    onNavigationAction={handleNavigationAction}
                                    className="ispectorGrid"
                                    sortable={true}
                                    sort={sort}
                                    filter={filter}
                                    onSortChange={(e) => {
                                        setSort(e.sort);
                                    }}
                                    onFilterChange={(e) => {
                                        setFilter(e?.filter);
                                    }}
                                >
                                    {stateColumns.map(
                                        (column, idx) =>
                                            column.show &&
                                            (cliamProfile.HasReservation === true &&
                                            column.field === 'Availability' ? (
                                                <Column
                                                    key={idx}
                                                    field={column.field}
                                                    title={
                                                        <Tooltip
                                                            openDelay={100}
                                                            position="top"
                                                            anchorElement="target"
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <span title={column.title}>
                                                                    {column.title}s
                                                                </span>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    width={133}
                                                    cell={(props) => (
                                                        <td className="k-command-cell">
                                                            {' '}
                                                            {props.dataItem.Availability === -1
                                                                ? 'N/A'
                                                                : props.dataItem.Availability === 1
                                                                ? 'Available'
                                                                : 'Not Available'}
                                                        </td>
                                                    )}
                                                    filter={column.filter}
                                                    columnMenu={(props) => (
                                                        <ColumnMenu
                                                            {...props}
                                                            columns={stateColumns}
                                                            onColumnsSubmit={onColumnsSubmit}
                                                        />
                                                    )}
                                                />
                                            ) : column.field === 'WorkLoad' ||
                                              column.field === 'Distance' ? (
                                                <Column
                                                    width={140}
                                                    key={idx}
                                                    field={column.field}
                                                    title={
                                                        <Tooltip
                                                            openDelay={110}
                                                            position="top"
                                                            anchorElement="target"
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <span title={column.title}>
                                                                    {column.title}
                                                                </span>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    filter={column.filter}
                                                    columnMenu={(props) => (
                                                        <ColumnMenu
                                                            {...props}
                                                            columns={stateColumns}
                                                            onColumnsSubmit={onColumnsSubmit}
                                                        />
                                                    )}
                                                />
                                            ) : column.field === 'InsuredRating' ? (
                                                <Column
                                                    width={160}
                                                    key={idx}
                                                    field={column.field}
                                                    title={
                                                        <Tooltip
                                                            openDelay={100}
                                                            position="top"
                                                            anchorElement="target"
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <span title={column.title}>
                                                                    {column.title}
                                                                </span>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    filter={column.filter}
                                                    columnMenu={(props) => (
                                                        <ColumnMenu
                                                            {...props}
                                                            columns={stateColumns}
                                                            onColumnsSubmit={onColumnsSubmit}
                                                        />
                                                    )}
                                                />
                                            ) : (
                                                column.field !== 'Availability' && (
                                                    <Column
                                                        key={idx}
                                                        field={column.field}
                                                        title={
                                                            <Tooltip
                                                                openDelay={125}
                                                                position="top"
                                                                anchorElement="target"
                                                            >
                                                                <div
                                                                    style={{
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    <span title={column.title}>
                                                                        {column.title}
                                                                    </span>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                        filter={column.filter}
                                                        columnMenu={(props) => (
                                                            <ColumnMenu
                                                                {...props}
                                                                columns={stateColumns}
                                                                onColumnsSubmit={onColumnsSubmit}
                                                            />
                                                        )}
                                                    />
                                                )
                                            )),
                                    )}
                                </Grid>
                            </>
                        )}
                    </Box>
                    <Box>
                        {isLoaderOn ? (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        ) : (
                            <Claim_Inspector_calendar inspectorDetails={calenderList} />
                        )}
                    </Box>
                </Box>

                <Box className="d-flex" style={{ justifyContent: 'space-between', marginTop: 2 }}>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        variant="outlined"
                        type="button"
                    >
                        Cancel
                    </Button>

                    <Box style={{ display: 'flex' }}>
                        {actionType === 'ReAssign' && (
                            <TextField
                                //required
                                //error={errors.description}
                                style={{ minWidth: '520px', marginRight: 30 }}
                                id="outlined-required"
                                size="small"
                                label="Reason"
                                defaultValue=""
                                value={reasonvalue}
                                onChange={(e) => setReasonValue(e.target.value)}
                            />
                        )}

                        <Button
                            variant="contained"
                            disabled={isDisabled}
                            type="button"
                            id="btnAssignInspector"
                            onClick={AssignInspectorbtnClick}
                        >
                            {responseForSave.isSubmitting ||
                            responseForReassignSave.isSubmitting ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                'Assign'
                            )}
                        </Button>
                    </Box>
                </Box>

                {savedResponse.IsSuccess !== undefined ? (
                    <Alert
                        className="alertNew"
                        severity={savedResponse.IsSuccess ? 'success' : 'error'}
                    >
                        {savedResponse.Message}
                    </Alert>
                ) : null}
                {validationMsg !== undefined && validationMsg !== '' && (
                    <Alert className="alertNew" severity="error">
                        {validationMsg}
                    </Alert>
                )}
            </div>
        </>
    );
};
export default ClaimIndividualAssignment;

ClaimIndividualAssignment.prototype = {
    cliamProfile: PropTypes.array,
    setIsLoads: PropTypes.any,
    setIsOpen: PropTypes.any,
    reload: PropTypes.func,
    actionType: PropTypes.any,
    handleRefreshNotes: PropTypes.func,
};
