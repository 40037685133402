import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { process } from '@progress/kendo-data-query';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
    getSelectedState,
} from '@progress/kendo-react-grid';
import { columnForSLATrack } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TextField, Box, LinearProgress, Typography } from '@mui/material';
import editIcon from '../../images/pencil.svg';
import PropTypes from 'prop-types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import deleteIcon from '../../images/trash.svg';
import { getter } from '@progress/kendo-react-common';
import { encryptWithAES } from '../../utils/global-functions';
const DATA_ITEM_KEY = 'SLATrackingID';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const SlaTrackList = ({ OnHandleChange, filterList, onHandleDeleteClick }) => {
    const history = useNavigate();
    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(columnForSLATrack);

    // let initialState = createDataState({
    //   take: 8,
    //   skip: 0,
    // });

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
            }),
        );
    }, [filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const [selectedState, setSelectedState] = React.useState({});

    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
        },
        [selectedState],
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const handleDeleteClick = (claimTaskId) => {
        onHandleDeleteClick(claimTaskId);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    <button
                        className="icon-btn grid-icon"
                        // onClick={() => {
                        //     history(`/claimTask/add`, {
                        //         state: `${props.dataItem.ClaimTaskId}`,
                        //     });
                        // }}
                    >
                        <img src={editIcon} title="Edit" />
                    </button>

                    <button
                        className="icon-btn grid-icon"
                        // onClick={() => {
                        //   handleDeleteClick(props.dataItem.ClaimTaskId);
                        // }}
                    >
                        <img src={deleteIcon} title="Delete" />
                    </button>
                </td>
            </>
        );
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`, {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                },
                            });
                        }}
                    >
                        {props.dataItem.ClaimTaskNumber}
                    </a>
                </td>
            </>
        );
    };

    const carrierCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            history(`/carriers/view`, {
                                state: {
                                    carrierId: `${props.dataItem.CarrierUniqueId}`,
                                },
                            });
                        }}
                    >
                        {props.dataItem.CarrierName}
                    </a>
                </td>
            </>
        );
    };

    const claimCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'claim',
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`);
                        }}
                    >
                        {props.dataItem.ClaimNumber}
                    </a>
                </td>
            </>
        );
    };

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value);
    };

    const elapsedPerCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    {props.dataItem.ElapsedPercentage}
                    {!props.dataItem.HasBreached &&
                        !props.dataItem.HasRisk &&
                        !props.dataItem.IsStopped && (
                            <LinearProgressWithLabel
                                value={props.dataItem.ElapsedPercentage}
                                color="success"
                            />
                        )}
                    {props.dataItem.HasBreached &&
                        !props.dataItem.HasRisk &&
                        !props.dataItem.IsStopped && (
                            <LinearProgressWithLabel
                                value={props.dataItem.ElapsedPercentage}
                                color="error"
                            />
                        )}
                    {!props.dataItem.HasBreached &&
                        props.dataItem.HasRisk &&
                        !props.dataItem.IsStopped && (
                            <LinearProgressWithLabel
                                value={props.dataItem.ElapsedPercentage}
                                color="warning"
                            />
                        )}
                </td>
            </>
        );
    };
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
            </Box>
        );
    }

    const slaTimeCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td
                    className="k-command-cell hover"
                    dangerouslySetInnerHTML={{
                        __html: props.dataItem.SLATime,
                    }}
                ></td>
            </>
        );
    };

    const slaTimeLeftCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td
                    className="k-command-cell hover"
                    dangerouslySetInnerHTML={{
                        __html: props.dataItem.TimeLeft,
                    }}
                ></td>
            </>
        );
    };

    const slaElapsedTimeCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td
                    className="k-command-cell hover"
                    dangerouslySetInnerHTML={{
                        __html: props.dataItem.ElapsedTime,
                    }}
                ></td>
            </>
        );
    };
    return (
        <>
            <div>
                <Grid
                    data={
                        result !== undefined && result.data !== undefined
                            ? result.data.map((item) => ({
                                  ...item,
                                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                              }))
                            : []
                    }
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    sortable={true}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500],
                        previousNext: true,
                    }}
                    resizable={true}
                    groupable={true}
                    pageSize={10}
                    style={{
                        maxHeight: 'calc(100vh - 182px)',
                    }}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div style={{ paddingLeft: 3 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                            {/* <Link to="/mapview">
                                <button title="Export to Excel" className="icon-btn map-icon">
                                    <img src={mapIcon} title="View Map" />
                                </button>
                            </Link> */}
                        </div>
                        {/* <Link to="/mapview">
                            <button title="Export to Excel" className="icon-btn map-icon">
                                <img src={mapIcon} title="View Map" />
                            </button>
                        </Link> */}
                    </GridToolbar>

                    <Column
                        field={SELECTED_FIELD}
                        width="60px"
                        heigth="60px"
                        headerSelectionValue={
                            result !== undefined && result.data !== undefined
                                ? result.data.findIndex(
                                      (item) => !selectedState[idGetter(item)],
                                  ) === -1
                                : 0
                        }
                    />

                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field == 'optn' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'SLATime' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={slaTimeCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'TimeLeft' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={slaTimeLeftCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'ElapsedTime' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={slaElapsedTimeCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'ElapsedPercentage' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={elapsedPerCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
            </div>
        </>
    );
};

export default SlaTrackList;
