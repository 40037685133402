import React from 'react';
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { getter } from '@progress/kendo-react-common';
import { columnsForUsers } from '../../components/kendoGrid/columns';
import { process } from '@progress/kendo-data-query';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { CheckBoxColumnMenu } from '../../components/kendoGrid/checkboxColumnMenu';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import Icon from '@mui/material/Icon';
import editIcon from '../../images/pencil.svg';

import fileIcon from '../../images/file-alt.svg';
import disabledFileIcon from '../../images/Icon-awesome-file-alt.svg';

import bulkAsign from '../../images/assign-bulk.svg';
import {
    Modal,
    Box,
    Typography,
    Breadcrumbs,
    Paper,
    Button,
    LinearProgress,
    Alert,
} from '@mui/material';
import UserForm from './user-form';
import User_edit from './user-edit';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ClaimContact from '../claim/claim-contact';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DATA_ITEM_KEY = 'UserId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const UserList = ({ filterList, OnHandleChange, setIsLoadList, filterListForRoleColumn }) => {
    let initialState = {};
    const [display, setDisplay] = React.useState(false);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const createDataState = (dataState) => {
        return {
            result: process(filterList?.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(
        columnsForUsers?.filter((data) => {
            return data?.field !== 'AssignedClaimCount';
        }),
    );
    const [isOpen, setIsOpen] = React.useState(false);
    const [idValue, setIdValue] = React.useState(0);
    const [idValues, setIdValues] = React.useState([]);
    const [selectedItemsInGrid, setSelectedItemsInGrid] = React.useState([]);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [roleCode, setRoleCode] = React.useState('');
    const [savedResponse, setSaveResponse] = React.useState({});
    const [isResultModelOpen, setisResultModelOpen] = React.useState(false);
    const [currentContactInfo, setCurrentContactInfo] = React.useState({
        mobile: '',
        email: '',
        claimId: '',
        emailToName: '',
        inspectorId: '',
    });
    const { submitData: submitDataForBulkinspector, response: responseForBulkinspector } =
        usePostData(AppUrls.SendBulkInspector, 'POST');

    const [isContactOpen, setIsContactOpen] = React.useState(false);

    initialState = createDataState({
        take: 100,
        skip: 0,
    });

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        initialState = createDataState({
            take: 100,
            skip: 0,
            group: groupOfGrid,
        });
        setResult(initialState?.result);
    }, [filterList]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);

        setGroupOfGrid(event?.dataState?.group);
        setResult(updatedState?.result);
        setDataState(updatedState?.dataState);
    };
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const expandChange = (event) => {
        const isExpanded =
            event?.dataItem?.expanded === undefined
                ? event?.dataItem?.aggregates
                : event?.dataItem?.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
    };

    const [selectedState, setSelectedState] = React.useState({});
    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
            if (Object.values(newSelectedState)?.filter((data) => data === true)?.length >= 1) {
                setDisplay(true);
            } else {
                setDisplay(false);
            }
            setIdValue(0);
            setIdValues(Object.keys(newSelectedState)?.filter((key) => newSelectedState[key]));

            setSelectedItemsInGrid(
                event.dataItems?.filter((data, i) => {
                    return Object.keys(newSelectedState)
                        ?.filter((key) => newSelectedState[key])
                        ?.includes(data.UserId.toString());
                }),
            );
        },
        [selectedState],
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};

        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });

        setSelectedState(newSelectedState);
        if (Object.values(newSelectedState)?.filter((data) => data === true)?.length > 1) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
        setIdValue(0);
        setIdValues(Object.keys(newSelectedState)?.filter((data) => data));
        setSelectedItemsInGrid(event?.dataItems);
    }, []);
    React.useEffect(() => {
        if (responseForBulkinspector?.result != undefined) {
            setSaveResponse(responseForBulkinspector?.result);
            setisResultModelOpen(true);
            const timer = setTimeout(() => {
                setSaveResponse('');
                setisResultModelOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [responseForBulkinspector]);

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(filterList, {
                take: filterList?.length + 1,
                skip: 0,
                group: dataState?.group,
                filter: dataState?.filter,
            }).data,
        );
    };

    const editCellForIsActive = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {props?.dataItem?.IsActive ? 'Active' : 'Inactive'}
                </td>
            </>
        );
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
    };

    const handleClose = () => {
        setIsOpen(false);
        setIdValue(0);
        setIsOpenEdit(false);
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                {props.dataItem.RoleCode !== 'INSPECTOR' ? (
                    <td className="k-command-cell">{props?.dataItem?.UserName}</td>
                ) : (
                    <td className="k-command-cell">
                        {' '}
                        <Link to={`${props?.dataItem?.UserUniqueId}`}>
                            {props.dataItem.UserName}
                        </Link>
                    </td>
                )}
            </>
        );
    };

    const navigateCount = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                {props.dataItem.RoleCode !== 'INSPECTOR' ? (
                    <td className="k-command-cell">{props.dataItem.AssignedClaimCount}</td>
                ) : (
                    <td className="k-command-cell">
                        {' '}
                        <Link to={`${props.dataItem.UserUniqueId}`}>
                            {props?.dataItem?.AssignedClaimCount}
                        </Link>
                    </td>
                )}
            </>
        );
    };
    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            setIdValue(props?.dataItem?.UserId);
                            setIsOpenEdit(true);
                            setRoleCode(props?.dataItem?.RoleCode);
                        }}
                    >
                        <img src={editIcon} title="Edit" />
                    </button>
                    <button
                        // disabled={props.dataItem.AssigneeName !== null ? false : true}
                        className="icon-btn file-icon"
                        onClick={() => {
                            setCurrentContactInfo({
                                email: props.dataItem.EmailAddress,
                                mobile: props.dataItem.Phone,
                                claimId: '',
                                emailToName: props.dataItem.UserFullName,
                                inspectorId: props.dataItem.UserId,
                            });
                            setIsContactOpen(true);
                        }}
                    >
                        <img src={fileIcon} title=" " />
                    </button>
                </td>
            </>
        );
    };

    const customColumnMenu = (props) => <CheckBoxColumnMenu {...props} data={filterList} />;
    const customColumnMenuForRoles = (props) => <CheckBoxColumnMenu {...props} data={filterList} />;

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value);
    };

    const SendInspectorToDispatchbtnClick = () => {
        let multiInspector = [];
        selectedItemsInGrid.forEach((data) => {
            multiInspector.push({
                PilotID: data.PilotId === null ? '' : data.PilotId,
                Email: data.EmailAddress === null ? '' : data.EmailAddress,
                CellNumber: data.Mobile === null ? '' : data.Mobile,
                XactAddress: data.XactNetAddress === null ? '' : data.XactNetAddress,
                FirstName: data.FirstName === null ? '' : data.FirstName,
                LastName: data.LastName === null ? '' : data.LastName,
                IsActive: data.IsActive === 'Active' ? true : false,
                Address1: data.ContactAddress === null ? '' : data.ContactAddress,
                Address2: '',
                City: data.City === null ? '' : data.City,
                State: data.StateCode === null ? '' : data.StateCode,
                ZIP: data.Zip === null ? '' : data.Zip,
                RoleCode: data.RoleCode === null ? '' : data.RoleCode,
            });
        });

        submitDataForBulkinspector(multiInspector);
    };

    const gridData = React.useMemo(
        () =>
            groupOfGrid?.length > 0
                ? result !== undefined
                    ? result?.data
                    : []
                : (result?.data || [])?.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                  })),
        [selectedState, result, dataState, groupOfGrid],
    );

    const exportGrid = (value) => {
        return (
            <Grid
                data={
                    result !== undefined && result.data !== undefined
                        ? result.data?.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: selectedState[idGetter(item)],
                          }))
                        : []
                }
                {...dataState}
                style={{
                    maxHeight: 'calc(100vh - 234px)',
                    display: value ? 'none' : '',
                }}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: 'multiple',
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={true}
                groupable={true}
                total={result.total}
                reorderable={true}
                expandField="expanded"
                onExpandChange={expandChange}
                // pageSize={8}
            >
                {stateColumns
                    ?.filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    ?.map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
            </Grid>
        );
    };

    return (
        <>
            {responseForBulkinspector.isSubmitting ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : null}
            <Modal
                open={isResultModelOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {savedResponse.IsSuccess != undefined ? (
                    <Alert severity={savedResponse.IsSuccess ? 'success' : 'error'}>
                        {savedResponse.Message}
                    </Alert>
                ) : null}
            </Modal>

            <ClaimContact
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
                email={currentContactInfo.email}
                phoneNumber={currentContactInfo.mobile}
                EmailToName={currentContactInfo.emailToName}
                inspectorId={currentContactInfo.inspectorId}
                handleRefreshNotes={() => {}}
            />
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <UserForm
                        idValues={idValues}
                        setIsLoadList={setIsLoadList}
                        setIsOpen={setIsOpen}
                    />
                </Box>
            </Modal>
            <Modal
                open={isOpenEdit}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <User_edit
                        idValue={idValue}
                        setIsLoadList={setIsLoadList}
                        setIsOpenEdit={setIsOpenEdit}
                        roleCode={roleCode}
                    />
                </Box>
            </Modal>
            <div>
                <section className="userlist-header">
                    <Box className="resGrid">
                        <Box>
                            <Typography className="title m-0">Users</Typography>
                            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                                <Link
                                    to={'/'}
                                    underline="hover"
                                    color="inherit"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Home
                                </Link>
                                <Link
                                    to={'/user'}
                                    underline="hover"
                                    color="text.primary"
                                    aria-current="page"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Users
                                </Link>
                            </Breadcrumbs>
                        </Box>
                    </Box>
                </section>
            </div>
            <div className="claims-list-grid">
                <Grid
                    data={
                        // result
                        gridData
                    }
                    {...dataState}
                    style={{
                        maxHeight: 'calc(100vh - 147px)',
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500, 1000],
                        previousNext: true,
                    }}
                    groupable={true}
                    total={result.total}
                    pageSize={8}
                    resizable={true}
                    onRowDoubleClick={() => {}}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div
                                style={{ marginLeft: 'inherit' }}
                                id="bulkRoleAsign"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <Tooltip openDelay={100} position="top" anchorElement="target">
                                    <Box
                                        title="Role Bulk Assign"
                                        style={{
                                            marginLeft: 1,
                                            marginRight: 20,
                                            cursor: 'pointer',
                                            display: display ? '' : 'none',
                                        }}
                                    >
                                        <img
                                            src={bulkAsign}
                                            alt="Role Bulk Assign"
                                            title="Role Bulk Assign"
                                        />
                                    </Box>
                                </Tooltip>
                            </div>
                            <div id="bulkDispatch" style={{ marginLeft: 'inherit' }}>
                                <Tooltip openDelay={100} position="top" anchorElement="target">
                                    <Box
                                        title="Update Dispatch"
                                        style={{
                                            marginLeft: 1,
                                            marginRight: 20,
                                            cursor: 'pointer',
                                            display: display ? '' : 'none',
                                        }}
                                    >
                                        <Button
                                            onClick={() => SendInspectorToDispatchbtnClick()}
                                            variant="contained"
                                        >
                                            Update Dispatch
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </div>
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                        </div>
                    </GridToolbar>
                    <GridToolbar>
                        <div>
                            <button
                                title="Export to Excel"
                                className="icon-btn"
                                onClick={excelExport}
                            >
                                <img src={iconexcel} title="Export to Excel" />
                            </button>

                            <button className="icon-btn" onClick={exportPDF}>
                                <img src={iconpdf} title="Export to PDF" />
                            </button>
                        </div>
                    </GridToolbar>
                    <Column
                        field={SELECTED_FIELD}
                        width="45px"
                        headerSelectionValue={
                            result !== undefined && result.data !== undefined
                                ? result.data.findIndex(
                                      (item) => !selectedState[idGetter(item)],
                                  ) === -1
                                : 0
                        }
                    />

                    {stateColumns?.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'UserName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={navigateCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                    width={200}
                                />
                            ) : column.field === 'AssignedClaimCount' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={navigateCount}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                    width={150}
                                />
                            ) : column.field === 'StateCode' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    columnMenu={customColumnMenu}
                                />
                            ) : column.field === 'RoleName' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    // filterCell={roleFilterCell}
                                    columnMenu={customColumnMenu}
                                />
                            ) : column.field == 'IsActive' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCellForIsActive}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                    width={200}
                                />
                            )),
                    )}
                </Grid>

                <ExcelExport
                    fileName={`UsersExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    style={{ display: 'none' }}
                    // data={result !== undefined && result.data !== undefined ? result.data : []}
                    // ref={_export}
                    data={
                        filterList
                            ? process(filterList, {
                                  take: filterList?.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    ref={_export}
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`UsersExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default UserList;

UserList.propTypes = {
    filterList: PropTypes.array.isRequired,
    setIsLoadList: PropTypes.any.isRequired,
    filterListForRoleColumn: PropTypes.array.isRequired,
};
