import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Moment from 'moment';
import CustomDatePicker from '../../components/CustomDatePicker';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 780,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const ClaimProfileTaskEdit = ({
    taskInfo,
    setIsEditTask,
    cliamProfileDetails,
    handleRefreshTask,
    setLoadStatus,
    handleRefreshNotes,
    reload,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const watchAllFields = watch();
    const [savedResponse, setSaveResponse] = useState({});
    const {
        isLoading: manualStatusLoading,
        result: manualStatus,
        isError: manualStatusError,
    } = useLoadData(AppUrls.GetAllManualStatus);

    const { submitData: updateTaskStatus, response: updateTaskStatusResponse } = usePostData(
        AppUrls.ManulTaskStatusUpdate,
        'POST',
    );

    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (updateTaskStatusResponse.result != undefined) {
            setSaveResponse(updateTaskStatusResponse.result);
            // handleRefreshTask();
            const timer = setTimeout(() => {
                setSaveResponse({});
                handleRefreshTask();
                reload();
                handleRefreshNotes();
                setIsEditTask(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [updateTaskStatusResponse]);

    let currentdate = new Date();
    // console.log(watchAllFields);
    // console.log(taskInfo);

    const onSubmit = async (data) => {
        updateTaskStatus({
            ClaimId: cliamProfileDetails.ClaimId !== null ? cliamProfileDetails.ClaimId : 0,
            ClaimTaskStatusCode: data.newTaskStatus,
            // TaskAcceptedDate:
            //     data.newTaskStatus === 'ASGN_ACCEPTED'
            //         ? data.acceptedDate !== undefined
            //             ? data.acceptedDate
            //             : currentdate
            //         : currentdate,
            // PromisedInspectionStartTime:
            //     data.newTaskStatus === 'SCHEDULED'
            //         ? data.promisedInspectionStartTime !== undefined
            //             ? data.promisedInspectionStartTime
            //             : currentdate
            //         : currentdate,
            // Duration:
            //     data.newTaskStatus === 'SCHEDULED'
            //         ? data.duration !== undefined
            //             ? data.duration
            //             : 0
            //         : 0,
            // InspectionActualStartTime:
            //     data.newTaskStatus === 'INSPCTN_STARTED'
            //         ? data.inspectionStarted !== undefined
            //             ? data.inspectionStarted
            //             : currentdate
            //         : currentdate,
            // InspectionActualEndTime:
            //     data.newTaskStatus === 'INSPCTN_COMPLETED'
            //         ? data.inspectionActualEndTime !== undefined
            //             ? data.inspectionActualEndTime
            //             : currentdate
            //         : currentdate,
            // XACarrierId:
            //     cliamProfileDetails.XACarrierId !== null ? cliamProfileDetails.XACarrierId : '',
            // ClaimSource:
            //     cliamProfileDetails.ClaimSource !== null ? cliamProfileDetails.ClaimSource : '',
            // XactAddress:
            //     cliamProfileDetails.XactAddress !== null ? cliamProfileDetails.XactAddress : '',
            // RejectedDate:
            //     data.newTaskStatus === 'ASGN_REJECTED'
            //         ? data.rejectedDate !== undefined
            //             ? data.rejectedDate
            //             : currentdate
            //         : currentdate,
            // EstimateUploadTime:
            //     data.newTaskStatus === 'XACT_COMPLETED'
            //         ? data.estimateUploadTime !== undefined
            //             ? data.estimateUploadTime
            //             : currentdate
            //         : currentdate,
            TaskAcceptedDate:
                data.newTaskStatus === 'ASGN_ACCEPTED'
                    ? data.acceptedDate !== undefined
                        ? `${Moment(data.acceptedDate).format('MM-DD-YYYY hh:mm A')}`
                        : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
            PromisedInspectionStartTime:
                data.newTaskStatus === 'SCHEDULED'
                    ? data.promisedInspectionStartTime !== undefined
                        ? `${Moment(data.promisedInspectionStartTime).format('MM-DD-YYYY hh:mm A')}`
                        : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
            Duration:
                data.newTaskStatus === 'SCHEDULED'
                    ? data.duration !== undefined
                        ? data.duration
                        : 0
                    : 0,
            InspectionActualStartTime:
                data.newTaskStatus === 'INSPCTN_STARTED'
                    ? data.inspectionStarted !== undefined
                        ? `${Moment(data.inspectionStarted).format('MM-DD-YYYY hh:mm A')}`
                        : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
            InspectionActualEndTime:
                data.newTaskStatus === 'INSPCTN_COMPLETED'
                    ? data.inspectionActualEndTime !== undefined
                        ? `${Moment(data.inspectionActualEndTime).format('MM-DD-YYYY hh:mm A')}`
                        : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
            XACarrierId:
                cliamProfileDetails.XACarrierId !== null ? cliamProfileDetails.XACarrierId : '',
            ClaimSource:
                cliamProfileDetails.ClaimSource !== null ? cliamProfileDetails.ClaimSource : '',
            XactAddress:
                cliamProfileDetails.XactAddress !== null ? cliamProfileDetails.XactAddress : '',
                AssignedToId:
                cliamProfileDetails.AssignedToId !== null ? cliamProfileDetails.AssignedToId : 0,
            RejectedDate:
                data.newTaskStatus === 'ASGN_REJECTED'
                    ? data.rejectedDate !== undefined
                        ? `${Moment(data.rejectedDate).format('MM-DD-YYYY hh:mm A')}`
                        : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
            EstimateUploadTime:
                data.newTaskStatus === 'XACT_COMPLETED'
                    ? data.estimateUploadTime !== undefined
                        ? `${Moment(data.estimateUploadTime).format('MM-DD-YYYY hh:mm A')}`
                        : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
        });

        // data.newTaskStatus==="ASGN_ACCEPTED"?data.acceptedDate!==undefined?data.acceptedDate:currentdate:null
    };

    const newTaskStatus = [
        { value: 1, label: 'Assignment Accepted' },
        { value: 2, label: 'Assignment Rejected' },
        { value: 3, label: 'Scheduled' },
        { value: 4, label: 'En route' },
        { value: 5, label: 'Inspection Started' },
        { value: 6, label: 'Inspection Completed' },
        { value: 7, label: 'Estimate uploaded' },
    ];

    const duration = Array(12)
        .fill(0)
        .map((e, i) => {
            return { value: i * 2 + 2, label: `${i * 2 + 2}` };
        });

    return (
        <div>
            <Box sx={style}>
                <h2 style={{ marginBottom: 20 }}> Task Edit </h2>
                <Box
                    component="form"
                    fullWidth
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div>
                        <ul className="list-data">
                            <li>Task :</li>
                            <li style={{ fontWeight: 600 }}>
                                {taskInfo !== null && taskInfo !== undefined
                                    ? taskInfo.ClaimTaskNumber
                                    : ''}
                            </li>
                            <li>Claim :</li>
                            <li style={{ fontWeight: 600 }}>
                                {taskInfo !== null && taskInfo !== undefined
                                    ? taskInfo.ClaimNumber
                                    : ''}
                            </li>
                            <li>Current status: </li>
                            <li style={{ fontWeight: 600 }}>
                                {taskInfo !== null && taskInfo !== undefined
                                    ? taskInfo.ClaimTaskStatusName
                                    : ''}
                            </li>
                            <li style={{ gridColumn: 'span 2', marginTop: '15px' }}>
                                <FormControl sx={{ mb: 2 }} fullWidth>
                                    <Controller
                                        fullWidth
                                        control={control}
                                        name="newTaskStatus"
                                        rules={{
                                            required: false,
                                        }}
                                        render={({ field: { onChange, onBlur, value, name } }) => (
                                            <SelectOption
                                                values={
                                                    // newTaskStatus.map((e) => {
                                                    //     return {
                                                    //         value: e['value'],
                                                    //         label: e['label'],
                                                    //     };
                                                    // })
                                                    manualStatus !== undefined
                                                        ? manualStatus?.Data?.map((e) => {
                                                              return {
                                                                  value: e['ClaimTaskStatusCode'],
                                                                  label: e['ClaimTaskStatusName'],
                                                              };
                                                          })
                                                        : []
                                                }
                                                disabled={false}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value === undefined ? '' : value}
                                                // name={name || ''}
                                                name={'New Status ' || ''}
                                                error={errors.newTaskStatus}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </li>{' '}
                            {watchAllFields.newTaskStatus === 'ASGN_ACCEPTED' ? (
                                <>
                                    {/* <li>Accepted Date: </li> */}

                                    {/* Accepted Date: */}
                                    {/* <CustomDatePicker
                                            onChange={(date) => setSelectedDate(date)}
                                            value={selectedDate}
                                            dateFormat="MM-dd-yyyy hh:mm a"
                                            label={'Reservation Date Time'}
                                            showTimeSelect
                                            showCustomDaysPicker={false}
                                            defaultNull
                                            //  selectsRange={2}
                                        /> */}
                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        <FormControl fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="acceptedDate"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DesktopDateTimePicker
                                                            fullWidth
                                                            label="Accepted Date"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value}
                                                            onChange={onChange}
                                                            closeOnSelect={false}
                                                            renderInput={(params) => (
                                                                <TextField {...params} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    // <CustomDatePicker
                                                    //     onChange={(date) => setSelectedDate(date)}
                                                    //     value={selectedDate}
                                                    //     dateFormat="MM-dd-yyyy hh:mm a"
                                                    //     label={'Reservation Date Time'}
                                                    //     showTimeSelect
                                                    //     showCustomDaysPicker={false}
                                                    //     defaultNull
                                                    //     //  selectsRange={2}
                                                    // />
                                                )}
                                            />
                                        </FormControl>
                                    </li>
                                </>
                            ) : null}
                            {watchAllFields.newTaskStatus === 'ASGN_REJECTED' ? (
                                <>
                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        {' '}
                                        <FormControl fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="rejectedDate"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DesktopDateTimePicker
                                                            fullWidth
                                                            label="Rejected Date"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value}
                                                            closeOnSelect={false}
                                                            onChange={onChange}
                                                            renderInput={(params) => (
                                                                <TextField {...params} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </FormControl>
                                    </li>
                                </>
                            ) : null}
                            {watchAllFields.newTaskStatus === 'SCHEDULED' ? (
                                <>
                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        {' '}
                                        <FormControl fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="promisedInspectionStartTime"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DesktopDateTimePicker
                                                            fullWidth
                                                            label="Promised Inspection Start Time"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value}
                                                            closeOnSelect={false}
                                                            onChange={onChange}
                                                            renderInput={(params) => (
                                                                <TextField {...params} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </FormControl>
                                    </li>

                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        {' '}
                                        <FormControl sx={{ mb: 2 }} fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="duration"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <SelectOption
                                                        values={duration}
                                                        disabled={false}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value === undefined ? '' : value}
                                                        // name={name || ''}
                                                        name={'duration ' || ''}
                                                        error={errors.newTaskStatus}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </li>
                                    {/* <li>
                                        {' '}
                                        <TextField
                                            fullWidth
                                            error={errors.duration}
                                            id="outlined-multiline-static"
                                            label="Duration "
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink:
                                                    watchAllFields.duration === '' ? false : true,
                                            }}
                                            {...register('duration ', { required: false })}
                                        />
                                    </li> */}
                                </>
                            ) : null}
                            {watchAllFields.newTaskStatus === 'INSPCTN_STARTED' ? (
                                <>
                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        {' '}
                                        <FormControl fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="inspectionStarted"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DesktopDateTimePicker
                                                            fullWidth
                                                            label="Inspection Start Time"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value}
                                                            closeOnSelect={false}
                                                            onChange={onChange}
                                                            renderInput={(params) => (
                                                                <TextField {...params} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </FormControl>
                                    </li>
                                </>
                            ) : null}
                            {watchAllFields.newTaskStatus === 'INSPCTN_COMPLETED' ? (
                                //
                                <>
                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        {' '}
                                        <FormControl fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="inspectionActualEndTime"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DesktopDateTimePicker
                                                            fullWidth
                                                            label="Inspection Complete Time"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value}
                                                            closeOnSelect={false}
                                                            onChange={onChange}
                                                            renderInput={(params) => (
                                                                <TextField {...params} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </FormControl>
                                    </li>
                                </>
                            ) : null}
                            {watchAllFields.newTaskStatus === 'XACT_COMPLETED' ? (
                                //
                                <>
                                    <li style={{ gridColumn: 'span 2/ auto' }}>
                                        {' '}
                                        <FormControl fullWidth>
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name="estimateUploadTime"
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DesktopDateTimePicker
                                                            fullWidth
                                                            label="Estimate Upload Time"
                                                            inputFormat="dd/MM/yyyy hh:mm a"
                                                            value={value}
                                                            closeOnSelect={false}
                                                            onChange={onChange}
                                                            renderInput={(params) => (
                                                                <TextField {...params} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </FormControl>
                                    </li>
                                </>
                            ) : null}
                        </ul>
                    </div>
                    <div className="d-flex" style={{ marginTop: 20 }}>
                        <Button
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                                setIsEditTask(false);
                            }}
                            variant="outlined"
                            type="button"
                        >
                            Cancel
                        </Button>

                        <Button
                            disabled={watchAllFields.newTaskStatus === undefined ? true : false}
                            style={{ marginLeft: 'auto' }}
                            variant="contained"
                            type="submit"
                        >
                            {/* Save */}
                            {updateTaskStatusResponse.isSubmitting ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </Box>
                {savedResponse.IsSuccess != undefined ? (
                    <Alert
                        style={{ marginTop: 15 }}
                        severity={savedResponse.IsSuccess ? 'success' : 'error'}
                    >
                        {savedResponse.Message}
                    </Alert>
                ) : null}
            </Box>
        </div>
    );
};

export default ClaimProfileTaskEdit;

ClaimProfileTaskEdit.propTypes = {
    taskInfo: PropTypes.any,
    setIsEditTask: PropTypes.func,
    cliamProfileDetails: PropTypes.any,
    handleRefreshTask: PropTypes.func,
    setLoadStatus: PropTypes.func,
    handleRefreshNotes: PropTypes.func,
    reload: PropTypes.func,
};
