import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import React from 'react';
import moment from 'moment';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ColumnMenu } from '../../../components/kendoGrid/customColumnMenu';
import iconexcel from '../../../images/file-excel.svg';
import iconpdf from '../../../images/file-pdf-solid.svg';
import { process } from '@progress/kendo-data-query';

const ReportingDashboardList = ({ filterList, gridColumns, setGridColumns }) => {
    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );

    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
                group: groupOfGrid,
            }),
        );
        setResult(initialState.result);
    }, [filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save();
    };

    const dataStateChange = (event) => {
        setGroupOfGrid(event.dataState.group);
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const expandChange = (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
    };

    const onColumnsSubmit = (columnsState) => {
        setGridColumns(columnsState);
    };

    const footerCells = (props) => {
        const { field } = props;

        if (props.rowType === 'groupHeader') {
            return null;
        }

        let total = result.data.reduce(function (previousValue, currentValue) {
            if (currentValue[field]) {
                return previousValue + currentValue[field];
            } else {
                return previousValue + 0;
            }
        }, 0);

        if (field === 'statename') {
            return (
                <>
                    <td className="k-command-cell">
                        <div style={{ textAlign: 'center' }}>Total</div>
                    </td>
                </>
            );
        } else {
            return (
                <>
                    <td className="k-command-cell">
                        <div style={{ textAlign: 'center' }}> {total}</div>
                    </td>
                </>
            );
        }
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500],
                    previousNext: true,
                }}
                resizable={true}
                reorderable={true}
                groupable={false}
                pageSize={10}
                style={{
                    maxHeight: 'calc(100vh - 182px)',
                    display: value ? 'none' : '',
                }}
            >
                {gridColumns.map((d, index) => {
                    return (
                        <Column
                            key={index}
                            field={d.field}
                            title={d.title}
                            footerCell={footerCells}
                        />
                    );
                })}
            </Grid>
        );
    };

    const CustomFooterForExel = (props) => {
        const { field } = props.column;

        let total = result.data.reduce(function (previousValue, currentValue) {
            if (currentValue[field]) {
                return previousValue + currentValue[field];
            } else {
                return previousValue + 0;
            }
        }, 0);

        if (field === 'statename') {
            return `Total`;
        } else {
            return `  ${total}`;
        }
    };

    const exportGridExel = (value) => {
        return (
            <Grid
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500],
                    previousNext: true,
                }}
                resizable={true}
                reorderable={true}
                groupable={false}
                pageSize={10}
                style={{
                    maxHeight: 'calc(100vh - 182px)',
                    display: value ? 'none' : '',
                }}
            >
                {gridColumns.map((d, index) => {
                    return (
                        <ExcelExportColumn
                            key={index}
                            field={d.field}
                            title={d.title}
                            footer={CustomFooterForExel}
                        />
                    );
                })}
            </Grid>
        );
    };

    const custumCell = (props) => {
        const { dataItem, field } = props;
        if (props.rowType === 'groupHeader') {
            return null;
        }

        if (field === 'statename') {
            return (
                <>
                    <td className="k-command-cell">{dataItem[field]}</td>
                </>
            );
        } else {
            return (
                <>
                    <td className="k-command-cell">
                        {' '}
                        <div style={{ textAlign: 'center' }}>
                            {dataItem[field] === 0 ? '' : dataItem[field]}
                        </div>{' '}
                    </td>
                </>
            );
        }
    };

    return (
        <div>
            {' '}
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                <Grid
                    data={result}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500],
                        previousNext: true,
                    }}
                    groupable={true}
                    resizable={true}
                    reorderable={true}
                    pageSize={10}
                    style={{
                        maxHeight: 'calc(100vh - 182px)',
                    }}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <div className="search-wrap"></div>
                    </GridToolbar>
                    <GridToolbar>
                        <div>
                            <button
                                title="Export to Excel"
                                className="icon-btn"
                                onClick={excelExport}
                            >
                                <img src={iconexcel} title="Export to Excel" />
                            </button>

                            <button className="icon-btn" onClick={exportPDF}>
                                <img src={iconpdf} title="Export to PDF" />
                            </button>
                        </div>
                    </GridToolbar>
                    {gridColumns.map(
                        (column, idx) =>
                            column.show && (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    sortable={false}
                                    filter={column.filter}
                                    cell={custumCell}
                                    footerCell={footerCells}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={gridColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ),
                    )}
                </Grid>
                <ExcelExport
                    fileName={`ReportExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    style={{ display: 'none' }}
                    ref={_export}
                    data={
                        filterList
                            ? process(filterList, {
                                  take: filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGridExel(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`ReportExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </div>
    );
};

export default ReportingDashboardList;
