import {
    Autocomplete,
    Box,
    Breadcrumbs,
    FormControl,
    LinearProgress,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectMulti from '../../components/select-multi';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';
import moment from 'moment';
import { usePostData } from '../../hooks/use-post-data.hook';
import SelectOption from '../../components/select-option';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import CustomSelectDatePicker from './customSelectDatePicker/CustomSelectDatePicker';
import ClaimStatusCounts from './ClaimStatusCounts';
import { Link } from 'react-router-dom';

const USER_API_PAYLOAD = {
    PageNo: 1,
    PageSize: 50,
    SortDir: 'Ascending',
    SortExp: '',
    StartDate: null,
    EndDate: null,
    UserName: '',
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    IsActive: true,
    RoleCode: 'INSPECTOR',
};

const ReportingDashboard = () => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const { response: inspectorList } = usePostData2(AppUrls.UserList, USER_API_PAYLOAD);
    const { submitData, response } = usePostData(AppUrls.GetReportClaimsDashboard, 'POST');
    const { submitData: claimStatusUpdate, response: claimStatusResponse } = usePostData(
        AppUrls.GetReportClaimStatusDashboard,
        'POST',
    );
    const { submitData: claimCountForGrid, response: claimCountForGridResponse } = usePostData(
        AppUrls.GetReportClaimCountDashboardGrid,
        'POST',
    );
    const { result: carrierList } = useLoadData(AppUrls.GetAllCarrier);
    const { result: states } = useLoadData(AppUrls.GetAllStates);
    const [inputValue, setInputValue] = React.useState('');
    const [inputOption, setInputOption] = React.useState(null);
    const [isLoad, setIsLoad] = React.useState(true);
    const [inspectors, setInspectors] = React.useState([]);
    const [claimStatusFilterType, setClaimStatusFilterType] = React.useState('RECEIVED');
    const [claimStatusCode, setClaimStatusCode] = React.useState(0);

    const {
        isLoading: claimStatusLoading,
        result: claimStatusResult,
        isError: claimStatusError,
    } = useLoadData('ClaimStatus');
    const [formState, setFormState] = React.useState({
        carriesIds: [],
        stateIds: [],
        claimStatusIds: [],
        resourceId: 0,
        dateFrom: moment()._d,
        dateTo: moment()._d,
        isLoad: true,
    });

    React.useEffect(() => {
        if (inspectorList.result) {
            setInspectors((inspectorList.result && inspectorList?.result?.Data) || []);
        }
    }, [inspectorList]);

    React.useEffect(() => {
        submitData({
            CarrierIDs: formState.carriesIds.toString(),
            StateIDs: formState.stateIds.toString(),
            ResourceId: !formState.resourceId ? 0 : formState.resourceId,
            ClaimStatus: formState.claimStatusIds.toString(),
            DateFrom: !formState.dateFrom
                ? moment().format()
                : new Date(formState.dateFrom.setHours(12)),
            DateTo: !formState.dateTo ? moment().format() : new Date(formState.dateTo.setHours(12)),
        });
    }, [formState]);

    React.useEffect(() => {
        claimStatusUpdate({
            CarrierIDs: formState.carriesIds.toString(),
            StateIDs: formState.stateIds.toString(),
            ResourceId: !formState.resourceId ? 0 : formState.resourceId,
            ClaimStatus: formState.claimStatusIds.toString(),
            DateFrom: !formState.dateFrom
                ? moment().format()
                : new Date(formState.dateFrom.setHours(12)),
            DateTo: !formState.dateTo ? moment().format() : new Date(formState.dateTo.setHours(12)),
            FilterType: claimStatusFilterType,
        });
        claimCountForGrid({
            CarrierIDs: formState.carriesIds.toString(),
            StateIDs: formState.stateIds.toString(),
            ResourceId: !formState.resourceId ? 0 : formState.resourceId,
            ClaimStatus: formState.claimStatusIds.toString(),
            DateFrom: !formState.dateFrom
                ? moment().format()
                : new Date(formState.dateFrom.setHours(12)),
            DateTo: !formState.dateTo ? moment().format() : new Date(formState.dateTo.setHours(12)),
            FilterType: claimStatusFilterType,
            StatusId: claimStatusCode,
        });
    }, [claimStatusFilterType, formState]);

    React.useEffect(() => {
        claimCountForGrid({
            CarrierIDs: formState.carriesIds.toString(),
            StateIDs: formState.stateIds.toString(),
            ResourceId: !formState.resourceId ? 0 : formState.resourceId,
            ClaimStatus: formState.claimStatusIds.toString(),
            DateFrom: !formState.dateFrom
                ? moment().format()
                : new Date(formState.dateFrom.setHours(12)),
            DateTo: !formState.dateTo ? moment().format() : new Date(formState.dateTo.setHours(12)),
            FilterType: claimStatusFilterType,
            StatusId: claimStatusCode,
        });
    }, [claimStatusCode]);

    const handleRefreshButtonClick = () => {
        setClaimStatusCode(0);
        setClaimStatusFilterType('RECEIVED');
    };

    const handleCustomDatePickerChange = (option) => {
        setFormState({
            ...formState,
            dateFrom: option.fromDate,
            dateTo: option.todate,
        });
    };

    const onSubmit = async (data) => {};

    const handleInputValueChange = (newInputValue) => {
        setInputValue(newInputValue);
        setInspectors([]);
    };

    const showAutocomplete = () => {
        return (
            <FormControl fullWidth>
                <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={
                        inspectors?.map((e) => {
                            return {
                                value: e['UserId'],
                                label: e['FirstName'] + ' ' + e['LastName'],
                            };
                        }) || []
                    }
                    inputValue={inputValue}
                    value={inputOption}
                    onChange={(_, option) => {
                        setInputOption(option);

                        setFormState({
                            ...formState,
                            resourceId: !option ? 0 : option.value,
                        });
                    }}
                    onInputChange={(event, newInputValue) => {
                        handleInputValueChange(newInputValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Resource" />}
                />
            </FormControl>
        );
    };

    return (
        <>
            <Box>
                <Typography className="title" variant="h1">
                    Reporting Dashboard
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        to={'/'}
                        underline="hover"
                        color="inherit"
                        style={{ textDecoration: 'none' }}
                    >
                        Home
                    </Link>
                    <Link
                        to={'/reporting_dashboard'}
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                        style={{ textDecoration: 'none' }}
                    >
                        Reporting Dashboard
                    </Link>
                </Breadcrumbs>
            </Box>

            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box className="carrier-edit setshadow card">
                    <FormControl>
                        <Controller
                            control={control}
                            name="carrier"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => {
                                return (
                                    <SelectMulti
                                        values={
                                            (carrierList &&
                                                carrierList?.Data?.map((e) => {
                                                    return {
                                                        value: e['CarrierId'],
                                                        label: e['CarrierName'],
                                                    };
                                                })) ||
                                            []
                                        }
                                        disabled={false}
                                        onBlur={onBlur}
                                        onChange={(carrierId) =>
                                            setFormState({ ...formState, carriesIds: carrierId })
                                        }
                                        value={formState.carriesIds && formState.carriesIds}
                                        nameForLablel="Carrier *"
                                        name={'Carrier *' || ''}
                                        error={errors.CarrierId}
                                        multi={true}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Controller
                            control={control}
                            name="state"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => {
                                return (
                                    <SelectMulti
                                        values={
                                            (states &&
                                                states?.Data?.map((e) => {
                                                    return {
                                                        value: e['StateId'],
                                                        label: e['StateName'],
                                                    };
                                                })) ||
                                            []
                                        }
                                        disabled={false}
                                        onChange={(stateId) =>
                                            setFormState({ ...formState, stateIds: stateId })
                                        }
                                        value={formState.stateIds && formState.stateIds}
                                        onBlur={onBlur}
                                        nameForLablel="States *"
                                        name={'States *' || ''}
                                        error={errors.CarrierId}
                                        multi={true}
                                    />
                                );
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <CustomSelectDatePicker onChange={handleCustomDatePickerChange} />
                    </FormControl>
                    <FormControl>
                        <Controller
                            control={control}
                            name="Status"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => {
                                return (
                                    <SelectMulti
                                        values={
                                            (claimStatusResult &&
                                                claimStatusResult?.Data?.filter(
                                                    (status) => status.ClaimStatusCode !== 'CANCEL',
                                                ).map((e) => {
                                                    return {
                                                        value: e['ClaimStatusCode'],
                                                        label: e['ClaimStatusName'],
                                                    };
                                                })) ||
                                            []
                                        }
                                        disabled={false}
                                        onChange={(statusId) =>
                                            setFormState({
                                                ...formState,
                                                claimStatusIds: statusId,
                                            })
                                        }
                                        value={formState.claimStatusIds && formState.claimStatusIds}
                                        onBlur={onBlur}
                                        nameForLablel="Status *"
                                        name={'Status *' || ''}
                                        error={errors.CarrierId}
                                        multi={true}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    {showAutocomplete()}
                </Box>
            </Box>
            {response.isSubmitting ? <LinearProgress /> : null}
            <ClaimStatusCounts
                statusCounts={response.result && response.result}
                claimStatus={claimStatusResponse}
                setClaimStatusFilterType={setClaimStatusFilterType}
                claimStatusFilterType={claimStatusFilterType}
                claimCountForGridResponse={claimCountForGridResponse}
                claimStatusCode={claimStatusCode}
                setClaimStatusCode={setClaimStatusCode}
                handleRefreshButtonClick={handleRefreshButtonClick}
            />
        </>
    );
};

export default ReportingDashboard;
