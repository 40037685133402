import { Cached, Clear } from '@mui/icons-material';
import { Box, Container, Typography, Grid, LinearProgress, IconButton } from '@mui/material';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { Fragment } from 'react';
import { decodeHtml } from '../../utils/global-functions';
import ReportingDashboardGrid from './grid';

const ClaimStatusCounts = ({
    statusCounts,
    claimStatus,
    setClaimStatusFilterType,
    claimStatusFilterType,
    claimCountForGridResponse,
    claimStatusCode,
    setClaimStatusCode,
    handleRefreshButtonClick,
}) => {
    return (
        <Container style={{ padding: 0 }} maxWidth="false">
            <div className="card carrier-profile" style={{ paddingBottom: 0 }}>
                <Typography variant="h3" color={'#337dff'} fontWeight={'bold'} marginBottom={2}>
                    TOTAL CLAIM BY STATUS
                    <IconButton
                        onClick={() => {
                            handleRefreshButtonClick();
                        }}
                        className="clearIcon1"
                        title="Refresh"
                        style={{ marginLeft: 5, fontSize: 'large', color: 'coral' }}
                    >
                        <Cached />
                    </IconButton>
                </Typography>

                <Box className="grid-carrier-profile-stack1">
                    <Box
                        className={
                            claimStatusFilterType === 'RECEIVED'
                                ? 'border-blue active'
                                : 'border-blue'
                        }
                        style={{ cursor: 'pointer' }}
                    >
                        <Box
                            onClick={() => {
                                setClaimStatusFilterType('RECEIVED');
                                setClaimStatusCode(0);
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" className="label-class">
                                Total Received
                            </Typography>
                            <Typography variant="h1" sx={{ marginLeft: 'auto' }}>
                                {statusCounts && statusCounts?.Data?.TotalReceived}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        className={
                            claimStatusFilterType === 'COMPLETED'
                                ? 'border-green active'
                                : 'border-green'
                        }
                        style={{ cursor: 'pointer' }}
                    >
                        <Box
                            onClick={() => {
                                setClaimStatusFilterType('COMPLETED');
                                setClaimStatusCode(0);
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" className="label-class">
                                Total completed
                            </Typography>
                            <Typography sx={{ marginLeft: 'auto' }} variant="h1">
                                {statusCounts && statusCounts?.Data?.TotalCompleted}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className={
                            claimStatusFilterType === 'OPEN'
                                ? 'border-green active'
                                : 'border-green'
                        }
                        style={{ cursor: 'pointer' }}
                    >
                        <Box
                            onClick={() => {
                                setClaimStatusFilterType('OPEN');
                                setClaimStatusCode(0);
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" className="label-class">
                                Total Open
                            </Typography>
                            <Typography sx={{ marginLeft: 'auto' }} variant="h1">
                                {statusCounts && statusCounts?.Data?.TotalOpen}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="box-total-boxex">
                    {claimStatus.isSubmitting ? (
                        <LinearProgress style={{ gridColumn: 'span 4' }} />
                    ) : (
                        claimStatus?.result &&
                        claimStatus?.result?.Data &&
                        claimStatus?.result?.Data?.map((status) => {
                            return (
                                <Box className="box-total-boxex-body">
                                    <Box
                                        key={status.ClaimStatusName}
                                        className="blocks grid-carrier borderBottom1x"
                                        style={{
                                            backgroundColor:
                                                status.ClaimStatusId === claimStatusCode
                                                    ? 'lavender'
                                                    : '',
                                        }}
                                    >
                                        <Box
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setClaimStatusCode(
                                                    status.ClaimStatusId === claimStatusCode
                                                        ? 0
                                                        : status.ClaimStatusId,
                                                );
                                            }}
                                        >
                                            <Typography style={{ cursor: 'pointer' }} variant="h4">
                                                {' '}
                                                {status.StatusCount}
                                            </Typography>
                                            <Typography style={{ cursor: 'pointer' }} variant="h6">
                                                {status.ClaimStatusName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })
                    )}
                </Box>
            </div>
            {claimCountForGridResponse.isSubmitting ? (
                <LinearProgress style={{ gridColumn: 'span 4' }} />
            ) : null}
            <ReportingDashboardGrid
                claimCountForGridResponse={
                    !claimCountForGridResponse?.result ? {} : claimCountForGridResponse?.result
                }
            />

            <div className="card" style={{ marginTop: 5, paddingBottom: 20, marginBottom: 10 }}>
                <Typography variant="h3" color={'#337dff'} fontWeight={'bold'} marginBottom={2}>
                    TOTAL CYCLE TIME
                </Typography>
                <Box className="cycleBox-grid">
                    {statusCounts &&
                        statusCounts?.Data?.ClaimCycleTimelist?.map((cycleTime) => {
                            return (
                                <Box
                                    className="grid-carrier cyclebox"
                                    style={{
                                        borderBottomColor:
                                            cycleTime.SLAPer === 'All claims within SLA' ||
                                            cycleTime.SLAPer === ''
                                                ? 'green'
                                                : 'red',
                                    }}
                                >
                                    <Box className="cycleboxBody" onClick={() => {}}>
                                        <Typography style={{ cursor: 'default' }} variant="h6">
                                            {cycleTime.CycleTimeName}
                                        </Typography>
                                        <Typography
                                            component="div"
                                            dangerouslySetInnerHTML={{
                                                __html: cycleTime.CycleTimeValue,
                                            }}
                                            style={{ cursor: 'default' }}
                                        ></Typography>
                                        <Typography style={{ cursor: 'default' }} component="small">
                                            {cycleTime.SLAPer}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            </div>
        </Container>
    );
};

export default ClaimStatusCounts;
