import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import SelectOption from '../../components/select-option';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};
const ClaimContact = ({
    isContactOpen,
    setIsContactOpen,
    email,
    phoneNumber,
    claimId,
    EmailToName,
    inspectorId,
    handleRefreshNotes,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const user = useCurrentUser();
    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});
    const [selectedValue, setSelectedValue] = React.useState('2');
    const [checked, setChecked] = React.useState([true, false]);
    const { submitData, response } = usePostData(AppUrls.InsuredContact, 'POST');
    const { result: notificationMedia } = useLoadData(AppUrls.NotificationMedia);
    let array = [];

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            handleRefreshNotes();
            const timer = setTimeout(() => {
                setSaveResponse({});
                setValue('contactType', '');
                setValue('subject', '');
                setValue('body', '');
                setIsContactOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const onSubmit = async (data) => {
        let dataArray = [];
        // dataArray.splice(dataArray.length);
        // if (checked[0]) {
        //     dataArray.push({
        //         IsActive: true,
        //         MsgId: 0,
        //         MsgTypeId:
        //             notificationMedia !== undefined
        //                 ? notificationMedia.Data.filter((data, index) => {
        //                       return data.NotificationMediaName === 'SMS';
        //                   })[0].NotificationMediaID
        //                 : 0,
        //         MsgFrom: user.Phone,
        //         MsgTo: phoneNumber,
        //         MsgCC: '',
        //         MsgSubject: data.subject,
        //         MsgBody: data.body,
        //         MsgTypeName: 'SMS',
        //     });
        // }
        // if (checked[1]) {
        //     dataArray.push({
        //         IsActive: true,
        //         MsgId: 0,
        //         MsgTypeId:
        //             notificationMedia !== undefined
        //                 ? notificationMedia.Data.filter((data, index) => {
        //                       return data.NotificationMediaName === 'Email';
        //                   })[0].NotificationMediaID
        //                 : 0,
        //         MsgFrom: user.Email,
        //         MsgTo: email,
        //         MsgCC: '',
        //         MsgSubject: data.subject,
        //         MsgBody: data.body,
        //         MsgTypeName: 'Email',
        //     });
        // }

        // submitData(dataArray);
        if (selectedValue === '2') {
            submitData([
                {
                    IsActive: true,
                    MsgId: 0,
                    MsgTypeId:
                        notificationMedia !== undefined
                            ? notificationMedia.Data.filter((data, index) => {
                                  return data.NotificationMediaName === 'SMS';
                              })[0].NotificationMediaID
                            : 0,
                    MsgFrom: user.Phone !== null ? user.Phone : '',
                    MsgTo: phoneNumber,
                    MsgCC: '',
                    MsgSubject: data.subject,
                    MsgBody: data.body,
                    MsgTypeName: 'SMS',
                    ClaimGuID: claimId !== undefined ? claimId : '',
                    EmailToName: '',
                    InspectorId: inspectorId === null ? 0 : inspectorId,
                    IsFromClaim: inspectorId === null ? true : false,
                },
            ]);
        } else {
            submitData([
                {
                    IsActive: true,
                    MsgId: 0,
                    MsgTypeId:
                        notificationMedia !== undefined
                            ? notificationMedia.Data.filter((data, index) => {
                                  return data.NotificationMediaName === 'Email';
                              })[0].NotificationMediaID
                            : 0,
                    MsgFrom: user.EmailAddress !== null ? user.EmailAddress : '',
                    MsgTo: email,
                    MsgCC: '',
                    MsgSubject: data.subject,
                    MsgBody: data.body,
                    MsgTypeName: 'Email',
                    ClaimGuID: claimId !== undefined ? claimId : '',
                    EmailToName: EmailToName,
                    InspectorId: inspectorId === null ? 0 : inspectorId,
                    IsFromClaim: inspectorId === null ? true : false,
                },
            ]);
        }
    };

    // if (carrierError) {
    //     return <div>Error Loading Data</div>;
    // }
    const handleClose = () => {
        setIsContactOpen(false);
        setValue('contactType', '');
        setValue('subject', '');
        setValue('body', '');
        // setSelectedValue([]);
        array = [];
    };

    const handleChange = (event) => {
        // if (event.target.checked) {
        //     array.push(parseInt(event.target.value));
        // } else {
        //     array.filter((data, i) => {
        //         return data !== event.target.value;
        //     });
        // }
        setSelectedValue(event.target.value);

        // setSelectedValue(array);
    };

    const handleChange2 = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };

    const handleChange3 = (event) => {
        setChecked([checked[0], event.target.checked]);
    };

    return (
        <div>
            <Modal
                open={isContactOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 style={{ marginBottom: 20 }}>Contact</h2>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '53ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            {/* <FormControl sx={{ m: 1, width: '53ch' }}> */}
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label={`Phone:  ${phoneNumber !== null ? phoneNumber : ''} `}
                                    checked={selectedValue === '2'}
                                    onChange={handleChange}
                                    disabled={phoneNumber === null ? true : false}
                                />
                                <FormControlLabel
                                    value="3"
                                    onChange={handleChange}
                                    control={<Radio />}
                                    label={`Email:  ${email !== null ? email : ''} `}
                                    checked={selectedValue === '3'}
                                    disabled={email === null && email !== '' ? true : false}
                                />
                            </RadioGroup>
                            {/* <FormControlLabel
                                style={{ marginLeft: 'auto' }}
                                control={
                                    <Checkbox checked={checked[0]} onChange={handleChange2} />
                                    
                                }
                                label={phoneNumber}
                            /> */}
                            {/* <FormControlLabel
                                style={{ marginLeft: 'auto' }}
                                control={
                                    <Checkbox checked={checked[1]} onChange={handleChange3} />
                                  
                                }
                                label={email}
                            /> */}
                            {/* </FormControl> */}

                            {selectedValue === '3' ? (
                                <TextField
                                    required
                                    error={errors.inspectionType}
                                    id="outlined-required"
                                    label="Subject"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: watchAllFields.subject === '' ? false : true,
                                    }}
                                    {...register('subject', { required: false })}
                                />
                            ) : null}

                            <TextField
                                required
                                error={errors.body}
                                id="outlined-required"
                                label="Text"
                                multiline
                                defaultValue=""
                                rows={4}
                                InputLabelProps={{
                                    shrink: watchAllFields.body === '' ? false : true,
                                }}
                                {...register('body', { required: true })}
                            />
                        </div>
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <Button
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                    setIsContactOpen(false);
                                    setValue('contactType', '');
                                    setValue('subject', '');
                                    setValue('body', '');
                                }}
                                variant="outlined"
                                type="button"
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                                type="submit"
                                disabled={
                                    selectedValue === '2'
                                        ? phoneNumber !== null && phoneNumber !== ''
                                            ? false
                                            : true
                                        : false
                                }
                            >
                                {response.isSubmitting ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    'Send'
                                )}
                            </Button>
                        </div>
                    </Box>
                    {savedResponse.IsSuccess != undefined ? (
                        <Alert
                            style={{ marginTop: 15 }}
                            severity={savedResponse.IsSuccess ? 'success' : 'error'}
                        >
                            {selectedValue === '2'
                                ? 'SMS sent successfully'
                                : 'Email sent successfully'}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
        </div>
    );
};
export default React.memo(ClaimContact);
ClaimContact.propTypes = {
    isContactOpen: PropTypes.bool,
    setIsContactOpen: PropTypes.func,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    claimId: PropTypes.string,
    EmailToName: PropTypes.string,
    inspectorId: PropTypes.string,
    handleRefreshNotes: PropTypes.func,
};
