// import { Select } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';

function SelectMulti(props) {
    const valueRef = useRef(props.value);

    if (props.multi) {
        const x = props.values?.filter((e) =>
            Array.isArray(props.value) ? props.value?.includes(e.value) : false,
        );

        const MultiSelectOption = (selectOptionProps) => {
            return (
                <div style={{ textOverflow: 'ellipsis' }}>
                    <components.Option {...selectOptionProps}>
                        <input
                            type="checkbox"
                            checked={selectOptionProps.isSelected}
                            onChange={() => null}
                        />{' '}
                        <label>{selectOptionProps?.label}</label>
                    </components.Option>
                </div>
            );
        };

        valueRef.current = Array.isArray(props.value) ? props.value : [];

        const selectAllOption = {
            value: '<SELECT_ALL>',
            label: 'Select All',
        };

        const isSelectAllSelected = () => valueRef.current?.length === props.values?.length;

        const isOptionSelected = (option) =>
            valueRef?.current?.some(({ value }) => value === option.value) || isSelectAllSelected();

        const getOptions = () => [selectAllOption, ...props.values];

        const getValue = () => (isSelectAllSelected() ? [selectAllOption] : x);

        const onChange = (newValue, actionMeta) => {
            const { action, option, removedValue } = actionMeta;
            if (action === 'select-option' && option.value === selectAllOption.value) {
                props.onChange(
                    props.values.map((e) => {
                        if (e.value != '0') {
                            return e.value;
                        }
                    }),
                    actionMeta,
                );
            } else if (
                (action === 'deselect-option' && option.value === selectAllOption.value) ||
                (action === 'remove-value' && removedValue.value === selectAllOption.value)
            ) {
                props.onChange([], actionMeta);
            } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
                props.onChange(
                    props.values
                        ?.filter(({ value }) => value !== option.value)
                        .map((e) => {
                            if (e.value != '0') {
                                return e.value;
                            }
                        }),
                    actionMeta,
                );
            } else {
                props.onChange(
                    newValue
                        ? newValue.map((e) => {
                              if (e.value != '0') {
                                  return e.value;
                              }
                          })
                        : [],
                    actionMeta,
                );
            }
        };

        return (
            <span
                style={{ cursor: props.disabled ? '' : 'default' }}
                className={props.disabled ? '_cursor-no-drop' : ''}
            >
                <Select
                    isOptionSelected={isOptionSelected}
                    className={`${
                        props.error ? '_react-select-validation' : '_react-select-nonValidation '
                    }multi-select`}
                    // menuPortalTarget={document.body}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    classNamePrefix="select"
                    // value={x}
                    options={getOptions()}
                    value={getValue()}
                    isDisabled={props.disabled}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    isMulti={props.multi}
                    name={props.name}
                    // options={props.values}
                    onChange={onChange}
                    // onChange={(e) => {
                    //     props.onChange(
                    //         e
                    //             ? e.map((e) => {
                    //                   if (e.value != '0') {
                    //                       return e.value;
                    //                   }
                    //               })
                    //             : [],
                    //     );
                    // }}
                    placeholder={props.nameForLablel === '' ? 'Select' : props.nameForLablel}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Option: MultiSelectOption,
                    }}
                    styles={{
                        control: (base) => ({
                            ...base,
                            borderColor: props.error ? '#d32f2f' : base?.borderColor,
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                        }),
                        multiValue: (provided) => {
                            return {
                                ...provided,
                                backgroundColor: '#fff',
                                borderRadius: 15,
                                border: 'solid',
                                borderWidth: 1,
                                borderColor: 'hsl(0, 0%, 80%)',
                            };
                        },
                        multiValueRemove: (provided) => {
                            return {
                                ...provided,
                                borderRadius: 15,
                            };
                        },
                    }}
                />
            </span>
        );
    } else {
        const x = props.values.filter((e) => props.value === e.value);

        return (
            <span
                style={{ cursor: props.disabled ? '' : 'default' }}
                className={props.disabled ? '_cursor-no-drop' : ''}
            >
                <Select
                    className={
                        props.nameForLablel === ''
                            ? '_react-select-language'
                            : '_react-select-language10'
                    }
                    classNamePrefix="select"
                    name={props.name}
                    options={props.values}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    value={x}
                    isRtl={false}
                    error={true}
                    isSearchable={true}
                    onChange={(e) => {
                        props.onChange(e ? e.value : '');
                    }}
                    placeholder={props.nameForLablel === '' ? 'Select' : props.nameForLablel}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                />
            </span>
        );
    }
}

export default SelectMulti;
