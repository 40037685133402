import {
    Button,
    createTheme,
    ThemeProvider,
    Container,
    CssBaseline,
    Avatar,
    Typography,
    LinearProgress,
} from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import { usePostData } from '../../hooks/use-post-data.hook';
import { useStorage } from '../../hooks/use-storage';
import { AppUrls } from '../../core/route.url';
import { useEffect } from 'react';
// import { useMsal } from '@azure/msal-react';
// import { loginRequest } from '../../authConfig';
// import { useIsAuthenticated } from '@azure/msal-react';
// import { LogIn } from '../../hooks/use-storage';
// import { isNil } from 'lodash';
// import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePostDataForOneLogin } from '../../hooks/use-post-data-2.hook';

export const LoginPage = () => {
    const [authToken, setToken] = useStorage('AUTH_STATE', null);
    // const logoutstate = JSON.parse(localStorage.getItem('LogOut_STATE'));
    // const { instance, accounts } = useMsal();
    // const { submitData, response } = usePostData(AppUrls.LoginAzure);
    const { submitData: submitDatatemp, response: responsetemp } = usePostDataForOneLogin(
        AppUrls.Login,
    );
    // const { result: loginButtonDisplay, reload: loginButtonDisplaySubmit } = useLoadData2();

    // const isAuthenticated = useIsAuthenticated();
    const history = useNavigate();
    const searchParams = useLocation();

    const location = useLocation();

    // useEffect(() => {
    //     if (1) {
    //         loginButtonDisplaySubmit('User/VerifySignIn');
    //     }
    // }, []);

    //    Onelogin api call
    useEffect(() => {
        if (searchParams.hash !== '') {
            if (jwt_decode(searchParams.hash).email !== undefined) {
                submitDatatemp({}, JSON.stringify(searchParams.hash));
                history('/');
            }
        }
    }, [searchParams.hash]);

    // onelogin response
    useEffect(() => {
        if (responsetemp.result != undefined) {
            history('/');
            setToken(responsetemp.result.Token);
        }
    }, [responsetemp]);

    // azure login api call  for logged use

    // useEffect(() => {
    //     if (isAuthenticated === true && isNil(logoutstate)) {
    //         instance
    //             .acquireTokenSilent({
    //                 ...loginRequest,
    //                 account: accounts[0],
    //             })
    //             .then((response) => {
    //                 const bearer = `bearer ${response.accessToken}`;
    //                 submitData(bearer);
    //             });
    //     }
    // }, [isAuthenticated]);

    // useEffect(() => {
    //     if (response.result != undefined) {
    //         setToken(response.result.Token);
    //     }
    // }, [response]);

    const LoginOneLogin = () => {
        history('/onelogin');
    };

    // azure login api call on button click

    // const LoginClick = async () => {
    //     LogIn('LogOut_STATE');
    //     if (isAuthenticated === true) {
    //         instance
    //             .acquireTokenSilent({
    //                 ...loginRequest,
    //                 account: accounts[0],
    //             })
    //             .then((response) => {
    //                 const bearer = `bearer ${response.accessToken}`;
    //                 submitData(bearer);
    //             });
    //     } else {
    //         instance.loginRedirect(loginRequest).catch((e) => {
    //             console.log(e);
    //         });
    //     }
    // };

    const theme = createTheme();

    return (
        <>
            {responsetemp.isSubmitting ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <>
                    {' '}
                    <ThemeProvider theme={theme}>
                        <Container component="main" maxWidth="xs" className="login-page">
                            <CssBaseline />
                            <div className="login-container">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        Sign in
                                    </Typography>
                                    {/* <CircularProgress /> */}{' '}
                                    {/* {loginButtonDisplay.Show_Azure_Login === '1' ? (
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            disableElevation
                                            size="large"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={LoginClick}
                                        >
                                            Sign In
                                        </Button>
                                    ) : null} */}
                                    {/* {loginButtonDisplay.Show_One_Login === '1' ? ( */}
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        disableElevation
                                        size="large"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={LoginOneLogin}
                                    >
                                        OneLogin
                                    </Button>
                                    {/* ) : null} */}
                                    {location.state !== null ? (
                                        <h3 style={{ color: 'red' }}>This Account Doesn't Exist</h3>
                                    ) : null}
                                </Box>
                            </div>
                        </Container>
                    </ThemeProvider>
                </>
            )}
        </>
    );
};