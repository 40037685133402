import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import ClaimTaskList from './claimTaskList';
import {
    CircularProgress,
    Backdrop,
    LinearProgress,
    Box,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    Breadcrumbs,
    Typography,
} from '@mui/material';
import Moment from 'moment'; //new installed library for date format
import TaskSummary from './task-summary';
import SelectOption from '../../components/select-option';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';

const ClaimTask = () => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const { submitData, response } = usePostData(AppUrls.CliamTask, 'POST');
    const { submitData: submitDataForDelete, response: responseForDelete } = usePostData(
        AppUrls.UserForDelete,
        'Delete',
    );
    const [filterList, setFilterList] = React.useState([]);
    const [taskType, setTaskType] = React.useState('CLAIM_TASK');
    const [taskStatus, setTaskStatus] = React.useState('ACTIVE');
    const [dashboardStyle, setDashboardStyle] = React.useState('dashboard-status-block taskStat');
    const [initialResult, setInitialResult] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(false);
    // const [value, setValue] = React.useState('female');

    const { submitData: submitDataForSummary, response: summaryResponse } = usePostData(
        AppUrls.TaskStatusCountList,
        'POST',
    );

    //const { reload, result } = usePostData();

    //console.log(summaryResponse);

    React.useEffect(() => {
        setIsLoadList(true);
    }, []);
    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);

            submitDataForSummary({
                ClaimTaskTypeCode: 'CLAIM_TASK',
                TaskStatus: taskStatus,
            });

            //reload(AppUrls.TaskStatusCountList, '');
            submitData({
                PageNo: 1,
                PageSize: 500,
                SortDir: 'Ascending',
                SortExp: '',
                StartDate: null,
                EndDate: null,
                ClaimTaskNumber: '',
                ClaimGuID: '',
                ClaimTaskTypeCode: taskType,
                TaskStatus: taskStatus,
            });
        }
    }, [isLoadList, responseForDelete.result]);

    // React.useEffect(() => {
    //     if (response.result != undefined) {
    //         if (response.result.IsSuccess) {
    //             // to format the date
    //             response.result.Data.forEach((o) => {
    //                 o.DueDate = Moment(o.DueDate).format('DD-MM-YYYY');
    //             });
    //             setFilterList(response.result.Data);
    //         }
    //     }
    // }, [response]);

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            if (response.result.Data.length > 0) {
                response.result.Data.forEach((o) => {
                    o.DueDate =
                        o.DueDate === null ? '' : Moment(o.DueDate).format('MM-DD-YYYY hh:mm A');
                });
                setFilterList(response.result.Data);
                setInitialResult(response.result.Data);
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        } else {
            setFilterList([]);
            setInitialResult([]);
        }
    }, [response]);

    React.useEffect(() => {
        if (responseForDelete.result != undefined) {
            if (responseForDelete.result.IsSuccess) {
                setIsLoadList(true);
            }
        }
    }, [responseForDelete]);

    const onHandleDeleteClick = (claimTaskId) => {
        submitDataForDelete({
            ClaimTaskId: claimTaskId,
        });
    };
    //
    const OnHandleChange = (searchterm, count, reset) => {
        if (reset) {
            if (count) {
                setFilterList(
                    initialResult.filter((data) => {
                        return (
                            (data.ClaimNumber &&
                                data.ClaimNumber.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.CarrierName &&
                                data.CarrierName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ClaimTaskNumber &&
                                data.ClaimTaskNumber.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ClaimTaskTypeName &&
                                data.ClaimTaskTypeName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ClaimTaskStatusName &&
                                data.ClaimTaskStatusName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.AssigneeName &&
                                data.AssigneeName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.DueDate &&
                                data.DueDate.toLowerCase().includes(searchterm.toLocaleLowerCase()))
                        );
                    }),
                );
            } else {
                setFilterList([]);
            }
        } else {
            setFilterList(initialResult);
        }
    };

    const OnHandleRiskBreachChange = (mainsearchterm, subsearchterm, count) => {
        if (count) {
            setFilterList(
                initialResult.filter((data) => {
                    return (
                        data.ClaimTaskStatusName &&
                        data.ClaimTaskStatusName.toLowerCase().includes(
                            mainsearchterm.toLocaleLowerCase(),
                        ) &&
                        data.TaskSLAStatusTemp &&
                        data.TaskSLAStatusTemp.toLowerCase().includes(
                            subsearchterm.toLocaleLowerCase(),
                        )
                    );
                }),
            );
        } else {
            setFilterList([]);
        }
    };
    const handleOnChange = async (e) => {
        setTaskType(e.target.value);
        setFilterList([]);
        if (e.target.value === 'REVIEW_TASK') {
            setDashboardStyle('dashboard-status-block rightNone');
        } else {
            setDashboardStyle('dashboard-status-block taskStat');
        }

        //reload(AppUrls.TaskStatusCountList, '');
        setTaskStatus('ACTIVE');
        submitDataForSummary({
            ClaimTaskTypeCode: e.target.value,
            TaskStatus: 'ACTIVE',
        });

        submitData({
            PageNo: 1,
            PageSize: 500,
            SortDir: 'Ascending',
            SortExp: '',
            StartDate: null,
            EndDate: null,
            ClaimTaskNumber: '',
            ClaimGuID: '',
            ClaimTaskTypeCode: e.target.value,
            TaskStatus: 'ACTIVE',
        });
    };

    const handleOnChangeStatus = async (e) => {
        setTaskStatus(e.target.value);
        // setFilterList([]);
        // if (e.target.value === 'REVIEW_TASK') {
        //     setDashboardStyle('dashboard-status-block rightNone');
        // } else {
        //     setDashboardStyle('dashboard-status-block taskStat');
        // }

        //reload(AppUrls.TaskStatusCountList, '');
        submitDataForSummary({
            ClaimTaskTypeCode: taskType,
            TaskStatus: e.target.value,
        });
        submitData({
            PageNo: 1,
            PageSize: 500,
            SortDir: 'Ascending',
            SortExp: '',
            StartDate: null,
            EndDate: null,
            ClaimTaskNumber: '',
            ClaimGuID: '',
            ClaimTaskTypeCode: taskType,
            TaskStatus: e.target.value,
        });
    };

    const displayList = () => {
        if (filterList.length > 0) {
            return (
                <ClaimTaskList
                    filterList={filterList}
                    onHandleDeleteClick={onHandleDeleteClick}
                    OnHandleChange={OnHandleChange}
                    taskType={taskType}
                    onReload={onReload}
                    onHandleOnChangeStatus={handleOnChangeStatus}
                    isSubmitting={response.isSubmitting}
                    taskStatus={taskStatus}
                />
            );
        } else {
            return (
                <ClaimTaskList
                    filterList={[]}
                    onHandleDeleteClick={onHandleDeleteClick}
                    OnHandleChange={OnHandleChange}
                    taskType={taskType}
                    onReload={onReload}
                    onHandleOnChangeStatus={handleOnChangeStatus}
                    isSubmitting={response.isSubmitting}
                    taskStatus={taskStatus}
                />
            );
        }
    };

    const onReload = () => {
        setIsLoadList(true);
    };

    return (
        <div className="main-content">
            <Box className="claimtaskGrid">
                <Box>
                    <Typography variant="h1" className="title m-0">
                        Tasks
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>
                        <Link
                            to={'/claimTask'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Tasks
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={taskType}
                            onChange={handleOnChange}
                        >
                            <FormControlLabel
                                value="CLAIM_TASK"
                                control={<Radio />}
                                label="CLAIM TASKS"
                                disabled={response.isSubmitting}
                            />
                            <FormControlLabel
                                value="REVIEW_TASK"
                                control={<Radio />}
                                label="REVIEW TASKS"
                                disabled={response.isSubmitting}
                            />
                            <FormControlLabel
                                value="GENERAL_TASK"
                                control={<Radio />}
                                label="GENERAL TASKS"
                                disabled={response.isSubmitting}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                {/* <Box>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={taskStatus}
                            onChange={handleOnChangeStatus}
                        >
                            <FormControlLabel
                                value="ACTIVE"
                                control={<Radio />}
                                label="ACTIVE TASK"
                            />
                            <FormControlLabel
                                value="COMPLETED"
                                control={<Radio />}
                                label="COMPLETED TASK"
                            />
                            <FormControlLabel value="ALL" control={<Radio />} label="ALL TASK" />
                        </RadioGroup>
                    </FormControl>
                </Box> */}
            </Box>
            {initialResult.length > 0 ? (
                <>
                    <TaskSummary
                        filterList={
                            summaryResponse.result != undefined ? summaryResponse.result.Data : []
                        }
                        OnHandleChange={OnHandleChange}
                        OnHandleRiskBreachChange={OnHandleRiskBreachChange}
                        taskType={taskType}
                        dashboardStyle={dashboardStyle}
                        isLoadingData={response.isSubmitting}
                    />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={responseForDelete.isSubmitting}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            ) : null}
            {response.isSubmitting ? <LinearProgress /> : null}
            {displayList()}
        </div>
    );
};

export default ClaimTask;
