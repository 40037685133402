import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GuardedPage } from './guarded-page';
import { UnauthorizedPage } from '../features/login/unauthorized.page';
import { DashboardPage } from '../features/dashboard/dashboard.page';
import InspectionTypeForm from '../features/inspectionType/inspection-type-form';
import Inspection from '../features/inspectionType/inspection-type';
import Claim from '../features/claim/claim';
import Claim_profile from '../features/claim/claim-profile';
import User from '../features/user/user';
import AuditTrail from '../features/auditTrail/auditTrail';

import AssignmentTypes from '../features/assignmentTypes/assignmentTypes';
import CarrierDashboard from '../features/carrier/carrier-list';
import ClaimTask from '../features/claimTask/claimTask';
import AssignmentTypesForm from '../features/assignmentTypes/assignmentTypeForm';
import { Inspector_profile } from '../features/user/inspector-profile';
import CarrierView from '../features/carrier/carrier-form';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import Carrier_manage from '../features/carrier/carrier-manage';
import { MapViewPage } from '../features/map/mapview.page';
import Carrier_manage_form from '../features/carrier/carrier-manage-form';
import ClaimProfile from '../features/claim/claim-profile';
import RoleList from '../features/roles/role-list';
import RolePermissionTab from '../features/roles/role-permission-tab';
import Notification from '../features/notification/notification';
import Questionnaire from '../features/claim/questionnaire';
import ProfileSearch from '../features/claim/profile-search';
import SideNavbarComponent from './sideNavbarComponent';
import SlaComponent from '../features/sla/sla-component';
import { CarrierListPage } from '../features/carrier-onboarding/carrier-list.page';
import { CarrierCreatePage } from '../features/carrier-onboarding/carrier-create.page';
import AlertList from '../features/alert/alert_list';
import CustomQueryBuilder from '../features/customQuery/custom-query-builder';
import CustomSaveQuery from '../features/customQuery/custom-save-query';
import CustomExecuteQuery from '../features/customQuery/custom-execute-query';
import Inspector from '../features/user/inspector';
import Auth from '../features/login/auth';
import OneLogin from '../features/login/one.login';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { AuthorizeComponent } from './authorize.component';
import ReportingDashboard from '../features/reportingDashboard';

export const RouterView = () => {
    const user = useCurrentUser();
    const location = useLocation();
    const [userPermission, setUserPermission] = React.useState([]);

    React.useEffect(() => {
        if (user.UserId !== undefined) {
            setUserPermission(user.UserMenuPermissions);
        }
    }, [user]);

    const checkPermission = (menuName) => {
        if (userPermission && userPermission.length > 0) {
            const permissionView = userPermission.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsView;
            return permissionView !== undefined ? permissionView : true;
        }
    };

    if (checkPermission('Claim') === undefined && location.pathname !== '/onelogin') {
        return (
            <Box sx={{ width: '100%' }}>
                <AuthorizeComponent>
                    <LinearProgress />
                </AuthorizeComponent>
            </Box>
        );
    }

    return (
        <Routes>
            <Route
                path="/home"
                element={
                    <GuardedPage
                        requiredPrermission={true}
                        children={<DashboardPage />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Claims')}
                        children={<Claim />}
                        url={'/'}
                    ></GuardedPage>
                }
            />
            <Route
                path="/inspection_type"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Inspection Types')}
                        children={<Inspection />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/mapview"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Map')}
                        children={<MapViewPage />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/inspection/add"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Inspection Types')}
                        children={<InspectionTypeForm />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/role"
                element={
                    <GuardedPage
                        // requiredPrermission={checkPermission('Roles')}
                        requiredPrermission={true}
                        children={<RoleList />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/role/permissions"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Roles')}
                        children={<RolePermissionTab />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/assignmentTypes"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Assignment Types')}
                        children={<AssignmentTypes />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/assignmentTypes/add"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Assignment Types')}
                        children={<AssignmentTypesForm />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/carriers"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Carriers')}
                        children={<CarrierDashboard />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/carriers/view"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Carriers')}
                        children={<CarrierView />}
                    ></GuardedPage>
                }
            />

            <Route
                path="claimtask/:id"
                element={
                    <GuardedPage
                        requiredPrermission={
                            checkPermission('Tasks')
                            // userPermission.length > 0
                            //   ? userPermission.filter((data) => {
                            //       return data.MenuId === 16;
                            //     })[0].IsView
                            //   : true
                        }
                        children={<ClaimProfile />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/:id"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Claims')}
                        children={<Claim_profile />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/user"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Users')}
                        children={<User />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/auditTrail"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Audit Logs')}
                        children={<AuditTrail />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/notification"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Notifications')}
                        children={<Notification />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/claimTask"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Tasks')}
                        children={<ClaimTask />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/User/:id"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Users')}
                        children={<Inspector_profile />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/resumeBuilder/questionnaire"
                element={
                    <GuardedPage
                        requiredPrermission={'Profiles'}
                        isPublic={true}
                        children={<Questionnaire />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/profiles"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Profiles')}
                        children={<ProfileSearch />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/carrierManage"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Manage Carriers')}
                        children={<Carrier_manage />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/Carrier/add"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Carriers')}
                        children={<Carrier_manage_form />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/sla"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Global SLAs')}
                        children={<SlaComponent />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/:id"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Claims')}
                        children={<Claim_profile />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/custom_query"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Query Builder')}
                        children={<CustomQueryBuilder />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/saved_query"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Saved Queries')}
                        children={<CustomSaveQuery />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/execute_query"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Query Builder')}
                        children={<CustomExecuteQuery />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/alerts"
                element={
                    <GuardedPage requiredPrermission={false} children={<AlertList />}></GuardedPage>
                }
            />
            <Route
                path="/reporting_dashboard"
                element={
                    <GuardedPage
                        requiredPrermission={true}
                        children={<ReportingDashboard />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/carrier-onboarding"
                element={
                    <GuardedPage
                        requiredPrermission={false}
                        children={<CarrierListPage />}
                    ></GuardedPage>
                }
            />

            <Route
                path="/carrier-create"
                element={
                    <GuardedPage
                        requiredPrermission={false}
                        children={<CarrierCreatePage />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/inspector"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Resources')}
                        children={<Inspector />}
                    ></GuardedPage>
                }
            />
            <Route
                path="/inspector/:id"
                element={
                    <GuardedPage
                        requiredPrermission={checkPermission('Resources')}
                        children={<Inspector_profile />}
                    ></GuardedPage>
                }
            />
            {/* <Route
        path="/"
        element={
          <GuardedPage
            requiredPrermission={true}
            children={<DashboardPage />}
          ></GuardedPage>
        }
      /> */}
            <Route
                path="/auth"
                element={
                    <GuardedPage
                        requiredPrermission={true}
                        isPublic
                        children={<Auth />}
                    ></GuardedPage>
                }
            />
            <Route path="/onelogin" element={<OneLogin />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
        </Routes>
    );
};
