import React from 'react';
import Divider from '@mui/material/Divider';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import Manage_role from './manage-role';
import RolesPermissionList from './roles-permission-list';
import SpecialPermissionList from './special-permission-list';
import ManageRole from './manage-role';
import { useNavigate } from 'react-router-dom';
const RolePermissionTab = () => {
    const location = useLocation();
    const navigate = useNavigate();
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={valueForTab !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {valueForTab === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component={'span'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [valueForTab, setValueForTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValueForTab(newValue);
    };

    const [rolePermissionList, setRolePermissionList] = React.useState([]);
    const [rolePermissionPrev, setRolePermissionPrev] = React.useState([]);
    const [isLoad, setIsLoad] = React.useState(false);

    const { submitData, response } = usePostData(AppUrls.UserRoleListForPermission, 'POST');

    React.useEffect(() => {
        setIsLoad(true);
    }, []);

    React.useEffect(() => {
        if (isLoad && location.state.test !== '') {
            setIsLoad(false);

            submitData({
                RoleId: parseInt(location.state.test),
                RoleName: '',
                RoleCode: '',
            });
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (response.result != undefined) {
            setRolePermissionList(response.result.Data);
            setRolePermissionPrev(structuredClone(response.result?.Data));
        }
    }, [response]);

    return (
        <div className="main-content">
            <section class="rolepermision-header">
                <h1 class="title m-0">Role Settings </h1>
                <ol class="breadcr-pilot">
                    <li>
                        <a>Home /</a>
                    </li>
                    <li>Roles /</li>
                    <li>Role Settings</li>
                </ol>
            </section>{' '}
            <div className="card">
                <Box sx={{ width: '100%' }}>
                    <Button
                        style={{}}
                        type="submit"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            navigate('/role');
                        }}
                    >
                        Back
                    </Button>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={valueForTab}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Manage Role" {...a11yProps(0)} />
                            <Tab label="Permission" {...a11yProps(1)} />
                            <Tab label="Special Permission" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={valueForTab} index={0}>
                        <h3 className="mb-2">Manage Role</h3>

                        <ManageRole
                            rolePermissionList={rolePermissionList}
                            rolePermissionPrev={rolePermissionPrev}
                        />
                    </TabPanel>
                    <TabPanel value={valueForTab} index={1}>
                        <h3 className="mb-2">Permission</h3>
                        <RolesPermissionList
                            rolePermissionList={rolePermissionList}
                            rolePermissionPrev={rolePermissionPrev}
                            setIsLoadPermission={setIsLoad}
                        />
                    </TabPanel>
                    <TabPanel value={valueForTab} index={2}>
                        <h3 className="mb-2">Special Permission</h3>
                        <SpecialPermissionList
                            rolePermissionList={rolePermissionList}
                            rolePermissionPrev={rolePermissionPrev}
                            setIsLoadPermission={setIsLoad}
                        />
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
};

export default RolePermissionTab;
RolePermissionTab.propTypes = {};
