import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 780,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const ClaimCustomerAttemptedForm = ({
    taskInfo,
    setCustomerContact,
    cliamProfileDetails,
    handleRefreshTask,
    setLoadStatus,
    handleRefreshNotes,
    reload,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});
    const { submitData: updateAttemptedContact, response: updateAttemptedContactResponse } =
        usePostData(AppUrls.UpdateAttemptedContact, 'POST');
    let currentdate = new Date();

    useEffect(() => {
        if (updateAttemptedContactResponse.result != undefined) {
            setSaveResponse(updateAttemptedContactResponse.result);
            // handleRefreshTask();
            const timer = setTimeout(() => {
                setSaveResponse({});
                handleRefreshTask();
                reload();
                handleRefreshNotes();
                setCustomerContact(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [updateAttemptedContactResponse]);

    const onSubmit = async (data) => {
        updateAttemptedContact({
            ClaimId: cliamProfileDetails.ClaimId !== null ? cliamProfileDetails.ClaimId : 0,
            IsContacted: data.active,
            ContactAttempNotes: data.noteBody,
            // ContactedDate:
            //     data.contactedDate !== undefined ? watchAllFields.contactedDate : currentdate,
            ContactedDate:
                data.contactedDate !== undefined
                    ? `${Moment(data.contactedDate).format('MM-DD-YYYY hh:mm A')}`
                    : `${Moment(currentdate).format('MM-DD-YYYY hh:mm A')}`,
            XACarrierId:
                cliamProfileDetails.XACarrierId !== null ? cliamProfileDetails.XACarrierId : '',
            ClaimSource:
                cliamProfileDetails.ClaimSource !== null ? cliamProfileDetails.ClaimSource : '',
            XactAddress:
                cliamProfileDetails.XactAddress !== null ? cliamProfileDetails.XactAddress : '',
            AssignedToId:
                cliamProfileDetails.AssignedToId !== null ? cliamProfileDetails.AssignedToId : 0,
        });
    };

    return (
        <div>
            <Box sx={style}>
                <h2 style={{ marginBottom: 20 }}>Customer Contact attempts </h2>
                <Box
                    component="form"
                    fullWidth
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div>
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="contactedDate"
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDateTimePicker
                                            label="Contacted Date"
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            value={value}
                                            onChange={onChange}
                                            closeOnSelect={false}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </FormControl>
                        <TextField
                            fullWidth
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                            error={errors.noteBody}
                            id="outlined-multiline-static"
                            label="Note"
                            multiline
                            rows={6}
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.noteBody === '' ? false : true,
                            }}
                            {...register('noteBody', { required: true })}
                        />
                        Is Contacted :{' '}
                        <FormControlLabel
                            style={{ marginLeft: 'auto' }}
                            control={
                                <Checkbox
                                    // checked={
                                    //     watchAllFields.active !== undefined
                                    //         ? watchAllFields.active
                                    //         : false
                                    // }
                                    {...register('active', { required: false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            // label="Is Contacted"
                        />
                    </div>
                    <div className="d-flex" style={{ marginTop: 20 }}>
                        <Button
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                                setCustomerContact(false);
                                // setValue('noteBody', '');
                            }}
                            variant="outlined"
                            type="button"
                        >
                            Cancel
                        </Button>

                        <Button style={{ marginLeft: 'auto' }} variant="contained" type="submit">
                            {/* Save */}
                            {updateAttemptedContactResponse.isSubmitting ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </Box>
                {savedResponse.IsSuccess != undefined ? (
                    <Alert
                        style={{ marginTop: 15 }}
                        severity={savedResponse.IsSuccess ? 'success' : 'error'}
                    >
                        {savedResponse.Message}
                    </Alert>
                ) : null}
            </Box>
        </div>
    );
};

export default ClaimCustomerAttemptedForm;

ClaimCustomerAttemptedForm.propTypes = {
    taskInfo: PropTypes.any,
    setCustomerContact: PropTypes.func,
    cliamProfileDetails: PropTypes.any,
    handleRefreshTask: PropTypes.func,
    setLoadStatus: PropTypes.func,
    handleRefreshNotes: PropTypes.func,
    reload: PropTypes.func,
};
