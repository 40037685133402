import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { columnsForClaimTask } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { CheckBoxColumnMenu } from '../../components/kendoGrid/checkboxColumnMenu';
import { Link, useNavigate } from 'react-router-dom';
import linkopen from '../../images/link-open.svg';
import editIcon from '../../images/file-signature.svg';
import deleteIcon from '../../images/file-alt.svg';
import fileIcon from '../../images/file-alt.svg';
import desabledFileIcon from '../../images/Icon-awesome-file-alt.svg';
import {
    Button,
    TextField,
    Box,
    LinearProgress,
    Typography,
    IconButton,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';

import PropTypes from 'prop-types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import ClaimContact from '../claim/claim-contact';
import { encryptWithAES } from '../../utils/global-functions';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { Cached } from '@mui/icons-material';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import moment from 'moment';

const ClaimTaskList = ({
    OnHandleChange,
    filterList,
    onHandleDeleteClick,
    taskType,
    onReload,
    isSubmitting,
    onHandleOnChangeStatus,
    taskStatus,
}) => {
    const history = useNavigate();
    const { handleSetPrintPermissions } = useUserPermissions();
    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(columnsForClaimTask);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const [currentContactInfo, setCurrentContactInfo] = React.useState({
        mobile: '',
        email: '',
        claimId: '',
        emailToName: '',
    });
    const [isContactOpen, setIsContactOpen] = React.useState(false);
    // let initialState = createDataState({
    //   take: 8,
    //   skip: 0,
    // });

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        // if (filterList.length > 0) {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
                group: groupOfGrid,
            }),
        );
        setResult(initialState.result);
        // }
    }, [filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    React.useEffect(() => {
        if (taskType === 'GENERAL_TASK') {
            setStateColumns(columnsForClaimTask);
        } else {
            setStateColumns(
                columnsForClaimTask.filter((data, i) => {
                    return data.field !== 'DueDate';
                }),
            );
        }
    }, [taskType]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        setGroupOfGrid(event.dataState.group);
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const expandChange = (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const handleDeleteClick = (claimTaskId) => {
        onHandleDeleteClick(claimTaskId);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell">
                    {/* <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`);
                        }}
                    >
                        <img src={editIcon} title=" " />
                    </button> */}
                    <button
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(window.open(`/claimtask/${encryptedParam}`, '_blank'));
                        }}
                        style={{ marginLeft: 10 }}
                        className="icon-btn"
                    >
                        <img style={{ width: 19 }} src={linkopen} title=" " />
                    </button>

                    {props.dataItem.AssigneeName !== null ? (
                        <button
                            disabled={props.dataItem.AssigneeName !== null ? false : true}
                            className="icon-btn file-icon"
                            onClick={() => {
                                setCurrentContactInfo({
                                    email: props.dataItem.InspectorEmail,
                                    mobile: props.dataItem.InspectorPhone,
                                    claimId: props.dataItem.ClaimGuID,
                                    emailToName: props.dataItem.AssigneeName,
                                });
                                setIsContactOpen(true);
                            }}
                        >
                            <img src={fileIcon} title=" " />
                        </button>
                    ) : (
                        <button
                            disabled={props.dataItem.AssignedToName !== null ? false : true}
                            className="icon-btn file-icon"
                        >
                            <img src={desabledFileIcon} title=" " />
                        </button>
                    )}
                </td>
            </>
        );
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`${encryptedParam}`);
                        }}
                    >
                        {taskType === 'CLAIM_TASK' && (
                            <i className={props.dataItem.TaskSLAColorIndicator} />
                        )}
                        {props.dataItem.ClaimTaskNumber}
                    </a>
                </td>
            </>
        );
    };

    const dueDateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover"> {props.dataItem.DueDate}</td>
            </>
        );
    };

    const carrierCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            history(`/carriers/view`, {
                                state: {
                                    carrierId: `${props.dataItem.CarrierUniqueId}`,
                                },
                            });
                        }}
                    >
                        {props.dataItem.CarrierName}
                    </a>
                </td>
            </>
        );
    };

    const claimCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'claim',
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`, {
                                state: {
                                    page: 'claim',
                                },
                            });
                        }}
                    >
                        {props.dataItem.ClaimNumber}
                    </a>
                </td>
            </>
        );
    };

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value, 1, 1);
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500],
                    previousNext: true,
                }}
                resizable={true}
                reorderable={true}
                groupable={false}
                pageSize={10}
                style={{
                    maxHeight: 'calc(100vh - 182px)',
                    display: value ? 'none' : '',
                }}
            >
                {/* <GridToolbar>
                    <div className="search-wrap">
                        <div style={{ paddingLeft: 3 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                size="small"
                                onChange={(e) => handleChangeValue(e)}
                            />
                        </div>
                        
                    </div>
                    
                </GridToolbar>
                <GridToolbar>
                    <div>
                        <button title="Export to Excel" className="icon-btn" onClick={excelExport}>
                            <img src={iconexcel} title="Export to Excel" />
                        </button>

                        <button className="icon-btn" onClick={exportPDF}>
                            <img src={iconpdf} title="Export to PDF" />
                        </button>
                    </div>
                </GridToolbar> */}
                {stateColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
            </Grid>
        );
    };

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save();
    };

    const customColumnMenu = (props) => <CheckBoxColumnMenu {...props} data={filterList} />;

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                {/*  <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
        </Box>*/}
            </Box>
        );
    }
    const statusCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    {props.dataItem.ClaimTaskStatusName}
                    {props.dataItem.TaskSLAColorIndicator === 'green-circle' &&
                        taskType === 'CLAIM_TASK' && (
                            <LinearProgressWithLabel
                                value={props.dataItem.Progress}
                                color="success"
                            />
                        )}
                    {props.dataItem.TaskSLAColorIndicator === 'yellow-circle' &&
                        taskType === 'CLAIM_TASK' && (
                            <LinearProgressWithLabel
                                value={props.dataItem.Progress}
                                color="warning"
                            />
                        )}
                    {props.dataItem.TaskSLAColorIndicator === 'red-circle' &&
                        taskType === 'CLAIM_TASK' && (
                            <LinearProgressWithLabel
                                value={props.dataItem.Progress}
                                color="error"
                            />
                        )}
                </td>
            </>
        );
    };

    // const [collapsedState, setCollapsedState] = React.useState([]);

    // const onExpandChange = React.useCallback(
    //     (event) => {
    //         const item = event.dataItem;

    //         if (item.groupId) {
    //             const newCollapsedIds = !event.value
    //                 ? [...collapsedState, item.groupId]
    //                 : collapsedState.filter((groupId) => groupId !== item.groupId);
    //             setCollapsedState(newCollapsedIds);
    //         }
    //     },
    //     [collapsedState],
    // );
    // const newData = setExpandedState({
    //     data: result,
    //     collapsedIds: collapsedState,
    // });

    const handleOnChangeStatus = (e) => {
        onHandleOnChangeStatus(e);
    };

    return (
        <>
            <ClaimContact
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
                email={currentContactInfo.email}
                phoneNumber={currentContactInfo.mobile}
                claimId={currentContactInfo.claimId}
                EmailToName={currentContactInfo.emailToName}
                inspectorId={null}
                handleRefreshNotes={() => {}}
            />
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                <Grid
                    data={result}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500],
                        previousNext: true,
                    }}
                    groupable={true}
                    resizable={true}
                    reorderable={true}
                    pageSize={10}
                    style={{
                        maxHeight: 'calc(100vh - 182px)',
                    }}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div style={{ paddingLeft: 3 }}>
                                {/* <Box> */}
                                {taskType === 'GENERAL_TASK' ? null : (
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={taskStatus}
                                            onChange={handleOnChangeStatus}
                                        >
                                            <FormControlLabel
                                                value="ACTIVE"
                                                control={<Radio />}
                                                label="ACTIVE "
                                                disabled={isSubmitting}
                                            />
                                            <FormControlLabel
                                                value="COMPLETED"
                                                control={<Radio />}
                                                label="COMPLETED "
                                                disabled={isSubmitting}
                                            />
                                            <FormControlLabel
                                                value="ALL"
                                                control={<Radio />}
                                                label="ALL "
                                                disabled={isSubmitting}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                                {/* </Box> */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                            {/* <Link to="/mapview">
                                <button title="Export to Excel" className="icon-btn map-icon">
                                    <img src={mapIcon} title="View Map" />
                                </button>
                            </Link> */}
                            <IconButton
                                onClick={() => {
                                    onReload();
                                }}
                                className="clearIcon"
                                title="Clear All filter"
                            >
                                <Cached />
                            </IconButton>
                        </div>
                        {/* <Link to="/mapview">
                            <button title="Export to Excel" className="icon-btn map-icon">
                                <img src={mapIcon} title="View Map" />
                            </button>
                        </Link> */}
                    </GridToolbar>
                    <GridToolbar>
                        {handleSetPrintPermissions('Tasks') ? (
                            <div>
                                <button
                                    title="Export to Excel"
                                    className="icon-btn"
                                    onClick={excelExport}
                                >
                                    <img src={iconexcel} title="Export to Excel" />
                                </button>

                                <button className="icon-btn" onClick={exportPDF}>
                                    <img src={iconpdf} title="Export to PDF" />
                                </button>
                            </div>
                        ) : null}
                    </GridToolbar>
                    {stateColumns
                        .filter((data, i) => {
                            return taskType === 'GENERAL_TASK'
                                ? data.field !== 'AssigneeName'
                                : data;
                        })
                        .map(
                            (column, idx) =>
                                column.show &&
                                (column.field == 'optn' ? (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={column.title}
                                        sortable={false}
                                        cell={editCell}
                                        filter={column.filter}
                                        columnMenu={(props) => (
                                            <ColumnMenu
                                                {...props}
                                                columns={stateColumns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                        )}
                                    />
                                ) : column.field === 'DueDate' ? (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span title={column.title}>{column.title}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        cell={dueDateCell}
                                        filter={column.filter}
                                        // show={false}
                                        columnMenu={(props) => (
                                            <ColumnMenu
                                                {...props}
                                                columns={stateColumns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                        )}
                                    />
                                ) : column.field === 'ClaimTaskNumber' ? (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span title={column.title}>{column.title}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        cell={navigateCell}
                                        filter={column.filter}
                                        columnMenu={(props) => (
                                            <ColumnMenu
                                                {...props}
                                                columns={stateColumns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                        )}
                                    />
                                ) : column.field === 'CarrierName' ? (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span title={column.title}>{column.title}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        cell={carrierCell}
                                        filter={column.filter}
                                        columnMenu={customColumnMenu}
                                    />
                                ) : column.field === 'ClaimNumber' ? (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span title={column.title}>{column.title}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        cell={claimCell}
                                        filter={column.filter}
                                        columnMenu={(props) => (
                                            <ColumnMenu
                                                {...props}
                                                columns={stateColumns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                        )}
                                    />
                                ) : column.field === 'ClaimTaskStatusName' ? (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span title={column.title}>{column.title}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        cell={statusCell}
                                        filter={column.filter}
                                        columnMenu={customColumnMenu}
                                    />
                                ) : (
                                    <Column
                                        key={idx}
                                        field={column.field}
                                        title={
                                            <Tooltip
                                                openDelay={100}
                                                position="top"
                                                anchorElement="target"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span title={column.title}>{column.title}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        filter={column.filter}
                                        columnMenu={(props) => (
                                            <ColumnMenu
                                                {...props}
                                                columns={stateColumns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                        )}
                                    />
                                )),
                        )}
                </Grid>
                <ExcelExport
                    fileName={`TaskExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    style={{ display: 'none' }}
                    ref={_export}
                    data={
                        filterList
                            ? process(filterList, {
                                  take: filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`TaskExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default ClaimTaskList;

ClaimTaskList.propType = {
    filterList: PropTypes.array.isRequired,
    OnHandleChange: PropTypes.func.isRequired,
    onHandleDeleteClick: PropTypes.func.isRequired,
    taskType: PropTypes.any.isRequired,
    onReload: PropTypes.func.isRequired,
    onHandleOnChangeStatus: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    taskStatus: PropTypes.any.isRequired,
};
