import React from 'react';
import moment from 'moment';

const FooterComponent = () => {
    return (
        <>
            {' '}
            {/* footer start */}
            <footer className="footer">
                {/* <small>Version 2.01</small> */}
                <small></small>{' '}
                <small>{`© ${moment().format(
                    ' YYYY',
                )} Pilot Catastrophe. All Rights Reserved.`}</small>{' '}
                <small>
                    Powered By<span>WBPRO</span>
                </small>{' '}
            </footer>
            {/* footer End */}
        </>
    );
};

export default FooterComponent;
