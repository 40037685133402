import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Alert,
    CircularProgress,
    Typography,
    Box,
    Breadcrumbs,
    Link,
    Stack,
} from '@mui/material';
import SelectOption from '../../components/select-option';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Carrier_manage_form = () => {
    const history = useNavigate();
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();
    const [isLoad, setIsLoad] = useState(false);
    const [savedResponse, setSaveResponse] = useState({});
    const location = useLocation();
    const [idValue, setIdvalue] = useState(0);

    const claimSources = [
        { value: 'Carrier Portal', label: 'Carrier Portal' },
        { value: 'XA', label: 'XA' },
    ];

    const reviewTaskTypes = [
        { value: 'XactAnalysis', label: 'XactAnalysis' },
        { value: 'Manual', label: 'Manual' },
    ];

    const { submitData, response } = usePostData(AppUrls.CarrierForSave, 'POST');

    const { submitData: submitDataForGet, response: getResponse } = usePostData(
        AppUrls.CarrierForGet,
        'POST',
    );

    React.useEffect(() => {
        if (location.state.carrierId !== 0) {
            setIsLoad(true);
        }
    }, [location.state.carrierId]);

    React.useEffect(() => {
        if (isLoad) {
            setIdvalue(location.state.carrierId);
            setIsLoad(false);
            submitDataForGet({
                CarrierId: location.state.carrierId,
            });
        }
    }, [isLoad]);

    const onSubmit = async (data) => {
        console.log(21, getResponse);
        submitData({
            IsActive: data.active,
            CarrierName: data.CarrierName,
            CarrierId: location.state.carrierId,
            ContactName: data.ContactName,
            ContactEmail: data.ContactEmail,
            ContactPhone: data.ContactPhone,
            ContactAddress: data.ContactAddress,
            DocumentPath: data.DocumentPath,
            ClaimSource: data.ClaimSource,
            XACarrierId: data.XACarrierId,
            ReviewTaskType: data.ReviewTaskType,
            IsRequiredManualStatus: data.manualStatus,
            CarrierPrev:
                getResponse.result !== undefined
                    ? {
                          CarrierName: getResponse.result.Data.CarrierName,
                          XACarrierId: getResponse.result.Data.XACarrierId,
                          ContactName: getResponse.result.Data.ContactName,
                          ContactEmail: getResponse.result.Data.ContactEmail,
                          ContactPhone: getResponse.result.Data.ContactPhone,
                          ContactAddress: getResponse.result.Data.ContactAddress,
                          ClaimSource: getResponse.result.Data.ClaimSource,
                          ReviewTaskType: getResponse.result.Data.ReviewTaskType,
                          IsRequiredManualStatus: getResponse.result.Data.IsRequiredManualStatus,
                          IsActive: getResponse.result.Data.IsActive,
                      }
                    : {},
        });
    };

    React.useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            const timer = setTimeout(() => {
                location.state.carrierId !== 0 ? setIsLoad(true) : setIsLoad(false);
                setSaveResponse({});
            }, 1000);
            return () => clearTimeout(timer);
            // setValue('ContactName', '');
            // setValue('CarrierName', '');
            // setValue('ContactEmail', '');
            // setValue('active', true);
            // setValue('ContactPhone', '');
            // setValue('ContactAddress', '');
            // setValue('DocumentPath', '');
            // setValue('ClaimSource', '');
            // setValue('XACarrierId', '');
            // setValue('ReviewTaskType', '');
            // setValue('manualStatus', false);
        }
    }, [response]);

    // React.useEffect(() => {
    //     if (location.state.carrierId !== 0) {
    //         submitDataForGet({ CarrierId: location.state.carrierId });
    //     }
    // }, [location.state.carrierId]);

    React.useEffect(() => {
        if (getResponse.result != undefined) {
            setValue('ContactName', getResponse.result.Data.ContactName);
            setValue('CarrierName', getResponse.result.Data.CarrierName);
            setValue('ContactEmail', getResponse.result.Data.ContactEmail);
            setValue('ContactPhone', getResponse.result.Data.ContactPhone);
            setValue('ContactAddress', getResponse.result.Data.ContactAddress);
            setValue('DocumentPath', getResponse.result.Data.DocumentPath);
            setValue('ClaimSource', getResponse.result.Data.ClaimSource);
            setValue('active', getResponse.result.Data.IsActive);
            setValue('manualStatus', getResponse.result.Data.IsRequiredManualStatus);
            setValue('XACarrierId', getResponse.result.Data.XACarrierId);
            setValue('ReviewTaskType', getResponse.result.Data.ReviewTaskType);
        }
    }, [getResponse]);

    return (
        <div className="main-content">
            <Typography variant="h1" className="title">
                Manage Carrier
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit">
                    Home
                </Link>
                <Link underline="hover" color="text.primary" aria-current="page">
                    Carriers
                </Link>
            </Breadcrumbs>
            <Box
                component="form"
                sx={{ mt: 3 }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box className="carrier-edit">
                    <TextField
                        required
                        error={errors.CarrierName}
                        id="outlined-required"
                        label="Carrier Name"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.CarrierName === '' ? false : true,
                        }}
                        {...register('CarrierName', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.XACarrierId}
                        id="outlined-required"
                        label="XA Carrier Id"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.XACarrierId === '' ? false : true,
                        }}
                        {...register('XACarrierId', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.ContactName}
                        id="outlined-required"
                        label="Contact Name"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.ContactName === '' ? false : true,
                        }}
                        {...register('ContactName', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.ContactEmail}
                        id="outlined-required"
                        label="Email"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.ContactEmail === '' ? false : true,
                        }}
                        {...register('ContactEmail', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.ContactPhone}
                        id="outlined-required"
                        label="Phone"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.ContactPhone === '' ? false : true,
                        }}
                        {...register('ContactPhone', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.ContactAddress}
                        id="outlined-required"
                        label="Address"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.ContactAddress === '' ? false : true,
                        }}
                        {...register('ContactAddress', { required: true })}
                    />
                    <TextField
                        required
                        error={errors.DocumentPath}
                        id="outlined-required"
                        label="Document Path"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.DocumentPath === '' ? false : true,
                        }}
                        {...register('DocumentPath', {
                            required: false,
                            disabled: idValue === 0 ? false : true,
                        })}
                    />
                    <FormControl>
                        <Controller
                            control={control}
                            name="ClaimSource"
                            rules={{
                                required: 'Claim Source is required',
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <SelectOption
                                    values={claimSources}
                                    disabled={false}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value === undefined ? '' : value}
                                    name={'Claim Source' || ''}
                                    error={errors.ClaimSource}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl>
                        <Controller
                            control={control}
                            name="ReviewTaskType"
                            // rules={{
                            //     required: 'Claim Source is required',
                            // }}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <SelectOption
                                    values={reviewTaskTypes}
                                    disabled={false}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value === undefined ? '' : value}
                                    name={'Review Task' || ''}
                                    error={errors.ReviewTaskTypes}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControlLabel
                        style={{}}
                        control={
                            <Checkbox
                                checked={
                                    watchAllFields.manualStatus !== undefined
                                        ? watchAllFields.manualStatus
                                        : false
                                }
                                {...register('manualStatus', { required: false })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Manual Status"
                    />
                </Box>
                <Box className="d-flex" style={{ marginTop: 20 }}>
                    <Button
                        variant="outlined"
                        type="button"
                        onClick={() => {
                            history('/carrierManage');
                        }}
                    >
                        Back
                    </Button>{' '}
                    {/*<FormControlLabel
                        style={{ marginLeft: 'auto' }}
                        control={
                            <Checkbox
                                defaultChecked
                                {...register('active', { required: false })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Active"
                    />*/}
                    <FormControlLabel
                        style={{ marginLeft: 'auto' }}
                        control={
                            <Checkbox
                                checked={
                                    watchAllFields.active !== undefined
                                        ? watchAllFields.active
                                        : false
                                }
                                {...register('active', { required: false })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Active"
                    />
                    <Button style={{ marginLeft: 2 }} variant="contained" type="submit">
                        {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                    </Button>
                </Box>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </div>
    );
};

export default Carrier_manage_form;
Carrier_manage_form.propTypes = {};
